import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editPasswordMyAccountSchema } from "../../schemas/editPasswordSchema";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Paper,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../customComponent";
import { changeSelfPassword } from "../../store/actions/userAction";
import { colorStyling } from "../../helpers/color";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import {
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_PASSWORD_ERROR_HANDLER,
} from "../../store/actionTypes/userActionType";
import parser from "html-react-parser";

const EditPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const { loadingUser, passwordErrorHandler: errorHandler } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { reset: true },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, setForm] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowOldPassword = () => {
    setForm({
      ...form,
      showOldPassword: !form.showOldPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setForm({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let resolver;
  resolver = editPasswordMyAccountSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const submitEditPasswordForm = async (data) => {
    const { oldPassword } = data;

    let errorTrue = false;
    if (oldPassword.length === 0) {
      errorTrue = true;
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { oldPassword: true },
      });
    } else {
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { oldPassword: false },
      });
    }

    if (data["New Password"].length < 8) {
      errorTrue = true;
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { newPassword: true },
      });
    } else {
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { newPassword: false },
      });
    }

    if (data["Confirm Password"] !== data["New Password"]) {
      errorTrue = true;
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { confirmPassword: true },
      });
    } else {
      dispatch({
        type: SET_PASSWORD_ERROR_HANDLER,
        payload: { confirmPassword: false },
      });
    }

    if (errorTrue) {
      return;
    }

    const payload = {
      currentPassword: oldPassword,
      password: data["Confirm Password"],
    };

    dispatch(
      changeSelfPassword(payload, (data, error) => {
        if (error) {
          reset({
            "New Password": "",
            "Confirm Password": "",
          });
          return;
        }

        reset({
          oldPassword: "",
          "New Password": "",
          "Confirm Password": "",
        });
      })
    );
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <DispatchErrorPromptAlert
          isError={isError}
          errorMessage={errorMessage}
          closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
        />
        <Alert severity="info">{parser(t("common.passwordReqHints"))}</Alert>

        <Box component="form">
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              {t("account.currentPasswordLabel")}
            </InputLabel>
            <OutlinedInput
              {...register("oldPassword")}
              label={t("account.currentPasswordLabel")}
              type={form.showOldPassword ? "text" : "password"}
              error={errorHandler.oldPassword ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errorHandler.oldPassword && (
              <FormHelperText sx={{ color: "red" }}>
                {t("account.currentPasswordErrRequired")}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              {t("account.newPasswordLabel")}
            </InputLabel>
            <OutlinedInput
              {...register("New Password")}
              label={t("account.newPasswordLabel")}
              type={form.showNewPassword ? "text" : "password"}
              error={errors["New Password"] ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors["New Password"] && (
              <FormHelperText sx={{ color: "red" }}>
                {errors["New Password"]?.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">
              {t("account.confirmPasswordLabel")}
            </InputLabel>
            <OutlinedInput
              {...register("Confirm Password")}
              label={t("account.confirmPasswordLabel")}
              type={form.showConfirmPassword ? "text" : "password"}
              error={errors["Confirm Password"] ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors["Confirm Password"] && (
              <FormHelperText sx={{ color: "red" }}>
                {t("form.confirmPasswordMustMatch")}
              </FormHelperText>
            )}
          </FormControl>

          {loadingUser ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <SecondaryButton onClick={() => navigate("/")}>
                {tCommon("cancelBtn")}
              </SecondaryButton>

              <PrimaryButton sx={{ ml: 1 }} size="large" disabled>
                <CircularProgress
                  size={20}
                  sx={{ color: colorStyling.white.full, mr: 1 }}
                />{" "}
                {t("account.savePasswordBtn")}
              </PrimaryButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={() => navigate("/")}>
                {tCommon("cancelBtn")}
              </Button>

              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={handleSubmit(submitEditPasswordForm)}
              >
                {t("account.savePasswordBtn")}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default EditPassword;
