import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { TypographyLarge, TypographyXLarge } from "../customComponent";
import Sidebar from "../sidebar/Sidebar";
import UserSuccessPrompt from "./UserSuccessPrompt";
import UserErrorPrompt from "./UserErrorPrompt";
import UserList from "./UserList";
import UserFilter from "./UserFilter";
import CreateUser from "./CreateUser";
import Role from "../../helpers/roles";

const User = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("user");

  const { user, features } = useSelector((state) => state.auth);
  const userRole = user?.type;
  const permission = user?.permission;
  const userPermission = permission?.user;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["USER"] === false) {
      navigate("/");
      return;
    }

    if (!permission) {
      return;
    }

    if (userPermission?.read === false && userRole !== Role.Eins) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {(userRole !== Role.User || userPermission?.create) && (
              <CreateUser />
            )}
          </Box>

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <UserFilter />
          </Paper>

          <UserList />
        </Container>
      </Box>
    </div>
  );
};

export default User;
