import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Sort as PriorityIcon,
  Timelapse as DurationIcon,
} from "@mui/icons-material";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import LaunchIcon from "@mui/icons-material/Launch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  TerminalIcon,
  TableIcon,
  ScheduleGreyIcon,
  ContentScheduleIcon,
  CommandScheduleIcon,
} from "../../../icons";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../helpers/switchColor";
import { formattedDate } from "../../../helpers/dateFormat";
import { colorStyling } from "../../../helpers/color";
import { calculateDuration } from "../../../helpers/scheduleHelper";
import ContentDetails from "../../cms/contentTemplate/detail/ContentDetails";
import { cronToFormData } from "../../../helpers/cronHelper";

const ScheduleInfo = ({ schedule, loading }) => {
  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [hoveredContent, setHoveredContent] = useState(false);

  const handleOpenContentDetail = () => {
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = () => {
    setOpenContentDetail(false);
  };

  const scheduleCron = useMemo(() => {
    return cronToFormData(schedule?.frequency, schedule?.cron);
  }, [schedule]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {schedule?.type?.includes("CONTENT") ? (
            <ContentScheduleIcon />
          ) : (
            <CommandScheduleIcon />
          )}
          <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
            <Box sx={{ width: "fit-content" }}>
              <TypographySmall
                sx={{
                  borderRadius: "15px",
                  color: switchColorOnScheduleStatus(
                    schedule?.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  backgroundColor: switchBackgroundColorBasedOnStatus(
                    schedule?.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  fontWeight: 600,
                  px: 1,
                  py: 0.2,
                }}
              >
                {schedule?.isApproved
                  ? t("item.approvedLabel")
                  : t("item.notApprovedLabel")}
              </TypographySmall>
            </Box>

            <Tooltip
              title={schedule?.name?.length > 30 ? schedule?.name : ""}
              placement="top-start"
            >
              <TypographyXLarge sx={{ fontWeight: 700 }}>
                {schedule?.name?.length > 30
                  ? schedule?.name?.substring(0, 27).trim() + "..."
                  : schedule?.name}
              </TypographyXLarge>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographySmall
            sx={{
              borderRadius: "15px",
              p: 1,
              color: switchColorOnScheduleStatus(
                schedule?.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule?.isActive ? "ACTIVE" : "PENDING"
              ),
              fontWeight: 600,
              mr: 4,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule?.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule?.isActive
              ? t("item.activeLabel")
              : t("item.inactiveLabel")}
          </TypographySmall>
        </Box>
      </Box>

      <Box sx={{ pl: 2, pr: 1 }}>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TableIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("item.frequencyLabel")}
                </TypographyNormal>
                {schedule?.type === "INSTANT" ? (
                  <TypographyLarge
                    sx={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    {t("frequency.INSTANT")}
                  </TypographyLarge>
                ) : schedule?.frequency === "OFF" ? (
                  <TypographyLarge
                    display="flex"
                    alignItems="center"
                    sx={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    {schedule?.type?.includes("CONTENT") ? (
                      <>
                        {t("frequency.LOOP")}
                        <Tooltip
                          arrow
                          disableInteractive
                          placement="top"
                          title={t("item.explainLOOP")}
                        >
                          <InfoOutlinedIcon
                            fontSize="1rem"
                            sx={{ marginLeft: "3px" }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      t("frequency.ONCE")
                    )}
                  </TypographyLarge>
                ) : (
                  <TypographyLarge
                    sx={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    {t(`frequency.${schedule?.frequency}`, {
                      defaultValue: null,
                    }) ?? schedule?.frequency}
                  </TypographyLarge>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TerminalIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("item.actionLabel")}
                </TypographyNormal>
                <TypographyLarge
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {schedule?.command?.replace(/_/g, " ")?.toLowerCase()}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          {schedule?.command === "PLAY_CONTENT" && (
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PriorityIcon sx={{ color: colorStyling.gray.normal }} />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.priorityLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {schedule?.priority?.name}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>
          )}

          {schedule?.type !== "INSTANT" && (
            <>
              <Grid item xs={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ScheduleGreyIcon />

                  <Box sx={{ ml: 2 }}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {t("item.startTimeLabel")}
                    </TypographyNormal>
                    <TypographyLarge sx={{ fontWeight: 600 }}>
                      {formattedDate(schedule?.startAt)}
                    </TypographyLarge>
                  </Box>
                </Box>
              </Grid>

              {schedule?.type?.includes("COMMAND") &&
              schedule?.frequency === "OFF" ? null : (
                <Grid item xs={4}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ScheduleGreyIcon />

                    <Box sx={{ ml: 2 }}>
                      <TypographyNormal sx={{ fontWeight: 300 }}>
                        {t("item.endTimeLabel")}
                      </TypographyNormal>
                      <TypographyLarge sx={{ fontWeight: 600 }}>
                        {schedule?.endAt
                          ? formattedDate(schedule?.endAt)
                          : t("item.endTimeIndefinitely")}
                      </TypographyLarge>
                    </Box>
                  </Box>
                </Grid>
              )}

              {scheduleCron?.minuteInterval > 0 && (
                <Grid item xs={4}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EventRepeatIcon sx={{ color: colorStyling.gray.normal }} />

                    <Box sx={{ ml: 2 }}>
                      <TypographyNormal sx={{ fontWeight: 300 }}>
                        {t("item.repeatActionLabel")}
                      </TypographyNormal>
                      <TypographyLarge sx={{ fontWeight: 600 }}>
                        {scheduleCron.minuteInterval}
                        {` ${t("item.repeatActionSuffix")}`}
                      </TypographyLarge>
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          )}

          {schedule?.command === "PLAY_CONTENT" && schedule?.duration && (
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DurationIcon sx={{ color: colorStyling.gray.normal }} />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.durationLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {calculateDuration(schedule?.duration)}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>
          )}

          {schedule?.command === "PLAY_CONTENT" && (
            <Grid item xs={8}>
              <Tooltip
                arrow
                disableInteractive
                placement="top-start"
                title={t("item.viewContentDetailsTip")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenContentDetail}
                  onMouseEnter={() => setHoveredContent(true)}
                  onMouseLeave={() => setHoveredContent(false)}
                >
                  <BackupTableTwoToneIcon
                    sx={{ color: colorStyling.gray.normal }}
                  />
                  <Box sx={{ ml: 2 }}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {t("item.contentNameLabel")}
                    </TypographyNormal>
                    <TypographyLarge
                      sx={{
                        ...(hoveredContent && {
                          color: colorStyling.primary,
                          textDecoration: "underline",
                        }),
                        fontWeight: 600,
                      }}
                    >
                      {schedule?.content?.name}
                      <LaunchIcon
                        fontSize="xs"
                        sx={{
                          color: hoveredContent
                            ? colorStyling.primary
                            : colorStyling.gray.label,
                        }}
                      />
                    </TypographyLarge>
                  </Box>
                </Box>
              </Tooltip>
              <ContentDetails
                open={openContentDetail}
                handleClose={handleCloseContentDetail}
                contentID={schedule?.contentId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ScheduleInfo;
