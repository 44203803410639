import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import {
  Alert,
  CircularProgress,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CstTypo14W300,
  CstTypo14W600,
  CstTypo20W600,
  CustomTextField,
  PrimaryButton,
} from "../components/customComponent";
import { resetPasswordSchema } from "../schemas/resetPasswordSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { initiateAXIOS } from "../config/axios";
import { CssBaseline } from "@material-ui/core";
import useCountDown from "react-countdown-hook";
import {
  SET_GLOBAL_SUCCESS_MESSAGE,
  SET_GLOBAL_SUCCESS,
} from "../store/actionTypes/globalActionType";
import { GlobalErrorPrompt, GlobalSuccessPrompt } from "../components";
import { colorStyling } from "../helpers/color";
import { initiateAuthAPI } from "../api";
import { handleError } from "../store/handleError";
import DispatchErrorPromptAlert from "./DispatchErrorPromptAlert";
import {
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_PROMPT_USER_DIALOG,
} from "../store/actionTypes/userActionType";
import { logger } from "../helpers/logger";
import { handleTimeoutPrompt } from "../store/handlePrompt";
import { parseVariables } from "../helpers/localize";
import parser from "html-react-parser";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { userLogin } = useSelector((state) => state.auth);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  useEffect(() => {
    if (!userLogin?.target || userLogin?.target?.length === 0) {
      sessionStorage.clear();
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);

  const [form, setForm] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setForm({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetPasswordToken = sessionStorage.getItem("resetPasswordToken");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(resetPasswordSchema) });

  const submitResetPassword = async (data) => {
    const verificationCode = data["Verification Code"];

    const password = data["New Password"];

    let payload = {
      usernameOrEmail: userLogin?.target,
      otp: verificationCode,
      password,
      ...(resetPasswordToken !== "TOTP" && { otpToken: resetPasswordToken }),
    };

    setIsLoading(true);

    try {
      await initiateAXIOS.post(initiateAuthAPI + "reset-password", payload, {
        headers: { authorization: `Bearer ${resetPasswordToken}` },
      });

      const successMessage = t("resetPass.successMsg");
      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({ type: SET_GLOBAL_SUCCESS_MESSAGE, payload: successMessage });
      handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
      setSuccessFlag(true);

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 4000);

      sessionStorage.removeItem("resetPasswordToken");
    } catch (error) {
      logger.error(error);
      const errorMessage = handleError(error, "resetPass", true);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG, 13000);

      const errorKeyword = error.response?.data?.detail?.keyword;
      if (errorKeyword === "resetPasswordAttemptsError") {
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } finally {
      reset({
        "Verification Code": "",
        "New Password": "",
        "Confirm Password": "",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <GlobalSuccessPrompt />
      <GlobalErrorPrompt />

      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid lightgrey",
            width: "37.5rem",
            borderRadius: "6px",
            py: 2,
            px: 6,
          }}
        >
          <CstTypo20W600 sx={{ textAlign: "center", mt: 1, mb: 2 }}>
            {t("resetPass.title")}
          </CstTypo20W600>

          {successFlag ? (
            <CstTypo14W600 textAlign={"center"} sx={{ py: 2 }}>
              {t("resetPass.redirectedHint")}
            </CstTypo14W600>
          ) : (
            <>
              <DispatchErrorPromptAlert
                isError={isError}
                errorMessage={errorMessage}
                closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
              />

              <Box
                component="form"
                onSubmit={handleSubmit(submitResetPassword)}
              >
                {resetPasswordToken !== "TOTP" ? (
                  <>
                    {!isLoading && (
                      <Alert icon={false} severity="success" sx={{ mb: 2 }}>
                        {parser(
                          parseVariables(t("resetPass.warningHint"), {
                            email: userLogin?.target,
                          })
                        )}
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    <Alert severity="info">{t("mfa.2faTotpHint")}</Alert>
                  </>
                )}

                <CustomTextField
                  {...register("Verification Code")}
                  margin="normal"
                  label={t("resetPass.verifCodePlaceholder")}
                  fullWidth
                  required
                  autoComplete="off"
                  error={errors["Verification Code"]}
                  helperText={errors["Verification Code"]?.message}
                  inputProps={{ maxLength: 6 }}
                />

                <CustomTextField
                  margin="normal"
                  label={t("resetPass.newPasswordPlaceholder")}
                  fullWidth
                  type={form.showNewPassword ? "text" : "password"}
                  required
                  {...register("New Password")}
                  error={errors["New Password"]}
                  helperText={errors["New Password"]?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <CustomTextField
                  margin="normal"
                  label={t("resetPass.confirmPasswordPlaceholder")}
                  fullWidth
                  type={form.showConfirmPassword ? "text" : "password"}
                  required
                  {...register("Confirm Password")}
                  error={errors["Confirm Password"]}
                  helperText={
                    errors["Confirm Password"] &&
                    t("resetPass.confirmPasswordMustMatch")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {resetPasswordToken !== "TOTP" && (
                  <CountdownTimer
                    isLoaded={isLoading}
                    setIsLoaded={setIsLoading}
                  />
                )}

                {isLoading ? (
                  <PrimaryButton
                    size="large"
                    sx={{ mt: 1 }}
                    disabled
                    fullWidth
                    variant="outlined"
                  >
                    <CircularProgress size={22} style={{ color: "grey" }} />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    size="large"
                    sx={{ mt: 1, mb: 2 }}
                    fullWidth
                    type="submit"
                    variant="outlined"
                  >
                    {tCommon("submitBtn")}
                  </PrimaryButton>
                )}

                <CstTypo14W300 textAlign={"center"} sx={{ py: 1 }}>
                  <span>
                    <Link
                      href="/login"
                      sx={{
                        cursor: "pointer",
                        color: colorStyling.primary,
                        fontWeight: 500,
                      }}
                      underline="hover"
                      onClick={() => sessionStorage.clear()}
                    >
                      {t("resetPass.backToLoginBtn")}
                    </Link>
                  </span>
                </CstTypo14W300>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const CountdownTimer = ({ isLoaded, setIsLoaded }) => {
  const dispatch = useDispatch();
  const { userLogin } = useSelector((state) => state.auth);

  const { t } = useTranslation("user");

  const SIXTY_SECONDS = 60 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(SIXTY_SECONDS, interval);

  useEffect(() => {
    start();
  }, [start]);

  const handleResendVerificationCode = async () => {
    setIsLoaded(true);
    try {
      const email = userLogin?.target;
      const { data } = await initiateAXIOS.post(
        initiateAuthAPI + "reset-password",
        {
          usernameOrEmail: email,
        }
      );

      const resetPasswordOTPKey = data.otpToken;
      sessionStorage.setItem("resetPasswordToken", resetPasswordOTPKey);
      start();
    } catch (error) {
      logger.error(error);
      const errorMessage = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <Box component="div" sx={{ my: 1 }}>
      {isLoaded ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} sx={{ color: colorStyling.primary }} />
        </Box>
      ) : (
        <CstTypo14W300 textAlign={"center"}>
          {timeLeft / 1000 === 0 ? (
            <span>
              <Link
                onClick={handleResendVerificationCode}
                sx={{
                  cursor: "pointer",
                  color: colorStyling.primary,
                  fontWeight: 500,
                }}
                underline="hover"
              >
                {t("resetPass.resendCodeBtn")}
              </Link>{" "}
              {t("resetPass.resendCodeHint")}
            </span>
          ) : (
            <span>
              {parseVariables(t("resetPass.codeExpireHint"), {
                time: timeLeft / 1000,
              })}
            </span>
          )}
        </CstTypo14W300>
      )}
    </Box>
  );
};

export default ResetPassword;
