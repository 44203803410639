import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import Sidebar from "../sidebar/Sidebar";
import AnnouncementSuccessPrompt from "./AnnouncementSuccessPrompt";
import AnnouncementErrorPrompt from "./AnnouncementErrorPrompt";
import CreateAnnouncement from "./CreateAnnouncement";
import AnnouncementList from "./AnnouncementList";
import AnnouncementFilter from "./AnnouncementFilter";

const Announcement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("announcement");

  const { user, features } = useSelector((state) => state.auth);
  const permission = user?.permission;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["ANNOUNCEMENT"] === false) {
      navigate("/");
      return;
    }

    if (permission && !permission?.schedule?.read) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <AnnouncementSuccessPrompt />
          <AnnouncementErrorPrompt />

          {permission?.schedule?.create && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CreateAnnouncement />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{
              borderRadius: "6px",
              p: 2,
              my: 2,
            }}
          >
            <AnnouncementFilter />
          </Paper>

          <AnnouncementList />
        </Container>
      </Box>
    </div>
  );
};

export default Announcement;
