import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { TypographyLarge } from "../../../customComponent";
import LayerAssetItem from "./LayerAssetItem";
import { colorStyling } from "../../../../helpers/color";
import parser from "html-react-parser";

const ContentPreview = ({
  open,
  loading,
  content,
  layers,
  canvasRes,
  ratiodRes,
  refreshed,
  disableControl,
}) => {
  const { t } = useTranslation("cms");

  const { user } = useSelector((state) => state.auth);
  const permission = user?.permission;

  if (!open) {
    return <></>;
  }

  return (
    <>
      <Box
        id={"preview-box"}
        sx={{
          pl: 1,
          pb: 2,
          pt: 2,
          pr: 4,
          width: "fit-content",
        }}
      >
        {!loading && (
          <Box
            boxShadow={1}
            sx={{
              width: canvasRes.width + 2,
              height: canvasRes.height + 2,
              position: "relative",
              backgroundColor: colorStyling.black,
              border: "1px solid rgba(28, 78, 216, 0.5)",
            }}
          >
            {permission?.asset?.read ? (
              layers.map((layer, i) => {
                return (
                  <LayerAssetItem
                    key={i}
                    index={i}
                    layer={layer}
                    canvasRes={canvasRes}
                    contentRes={{
                      width: content.width,
                      height: content.height,
                    }}
                    ratiodRes={ratiodRes}
                    open={open}
                    refreshed={refreshed}
                    disableControl={disableControl}
                  />
                );
              })
            ) : (
              <Box sx={{ height: "100%", alignContent: "center" }}>
                <TypographyLarge
                  sx={{
                    fontWeight: 400,
                    color: colorStyling.primary,
                    textAlign: "center",
                  }}
                >
                  {parser(t("detail.noPermissionHint"))}
                </TypographyLarge>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ContentPreview;
