import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
} from "@mui/material";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import CloseIcon from "@mui/icons-material/Close";
import {
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_PROMPT_USER_DIALOG,
} from "../../../store/actionTypes/userActionType";
import { CustomTextField, TypographySmall } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import { initiateAuthAPI } from "../../../api";
import { logger } from "../../../helpers/logger";
import ResendOTPCountdown from "./ResendOTPCountdown";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import { colorStyling } from "../../../helpers/color";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";
import { isEmpty } from "lodash";

const DisableMFADialogFormOTP = ({
  open,
  onClose,
  handleSuccess,
  dialogTitle,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");

  const handleCloseDialog = () => {
    onClose();
    setOtp("");
    setToken("");
    setLoading(false);
    setLoadingSendCode(false);
  };

  const sendEmailOtp = async (callback) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingSendCode(true);
    try {
      const { data } = await initiateAXIOS.post(
        initiateAuthAPI + "otp/email",
        { intent: "disable_mfa" },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setToken(data.otpToken);
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
    } finally {
      if (callback) callback();
      setLoadingSendCode(false);
    }
  };

  const verifyEmailOtp = async () => {
    setEmptyMessage("");

    const otpTrim = otp.trim();
    if (otpTrim.length === 0 || otp.length !== 6) {
      setEmptyMessage(t("common.otpInputEmpty"));
      setOtp(otpTrim);
      return;
    }

    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const payload = {
        otp: otp,
        ...(!isEmpty(token) && { otpToken: token }),
        mfaType: "DISABLED",
      };
      const data = await initiateAXIOS.post(initiateAuthAPI + "mfa", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      if (data.status === 200) {
        handleSuccess();
        handleCloseDialog();
      }
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (user.mfaType === "EMAIL") sendEmailOtp();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {dialogTitle}
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DispatchErrorPromptAlert
          isError={isError}
          errorMessage={errorMessage}
          closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
        />

        {!loadingSendCode && (
          <Alert icon={false} severity="success" sx={{ mb: 2 }}>
            {user.mfaType === "EMAIL"
              ? parser(
                  parseVariables(t("account.otpSentHint"), {
                    email: user?.email,
                  })
                )
              : parser(t("account.otpGetTotpHint"))}
          </Alert>
        )}

        <InputLabel>{t("account.enterOtpLabel")}</InputLabel>
        <CustomTextField
          sx={{ mt: 1 }}
          autoFocus
          placeholder={t("account.enterOtpPlaceholder")}
          fullWidth
          margin="normal"
          disabled={loading}
          value={otp}
          required
          autoComplete="off"
          onChange={(e) => {
            setOtp(e.target.value);
            setEmptyMessage("");
          }}
          inputProps={{ maxLength: 6 }}
        />

        {emptyMessage.length > 0 && (
          <Box
            sx={{
              pl: 1,
              pb: 1,
              display: "flex",
              justifyContent: "start",
            }}
          >
            <TypographySmall sx={{ color: colorStyling.red }}>
              {emptyMessage}
            </TypographySmall>
          </Box>
        )}

        {user.mfaType === "EMAIL" && (
          <ResendOTPCountdown sendOtp={sendEmailOtp} />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          variant="contained"
          fullWidth
          type="submit"
          onClick={verifyEmailOtp}
        >
          {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableMFADialogFormOTP;
