import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Toolbar, Paper } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import Sidebar from "../sidebar/Sidebar";
import ViewerSuccessPrompt from "./ViewerSuccessPrompt";
import ViewerErrorPrompt from "./ViewerErrorPrompt";
import FilterViewer from "./FilterViewer";
import ViewerList from "./ViewerList";
import CreateViewer from "./CreateViewer";
import Role from "../../helpers/roles";
import ExportViewer from "./ExportViewer";

const Viewer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("viewer");

  const { user, features } = useSelector((state) => state.auth);
  const userRole = user?.type;
  const permission = user?.permission;
  const viewerPermission = permission?.screen;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["SCREEN"] === false) {
      navigate("/");
      return;
    }

    if (permission && !viewerPermission?.read) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <ViewerSuccessPrompt />
          <ViewerErrorPrompt />

          <Box display={"flex"} justifyContent={"flex-end"}>
            <ExportViewer />
            {(userRole === Role.Eins || viewerPermission?.create) && (
              <CreateViewer />
            )}
          </Box>

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <FilterViewer />
          </Paper>

          <ViewerList />
        </Container>
      </Box>
    </div>
  );
};

export default Viewer;
