import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  setContentName,
  validateContent,
} from "../../../../store/actions/cmsAction";
import { colorStyling } from "../../../../helpers/color";
import { parseVariables } from "../../../../helpers/localize";
import { sanitizeInput } from "../../../../helpers/inputProcessing";
import SubmitDialog from "./SubmitDialog";
import BackToContent from "./BackToContent";

const TopBar = ({ setNameValidation }) => {
  const dispatch = useDispatch();
  const {
    layerMedias,
    contentLayers,
    contentRes,
    contentName,
    loadingDetail,
    loadingSubmit,
    contentTags,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (openSubmitDialog) {
      const existingTags = [];
      Object.keys(contentTags).forEach((tag) => existingTags.push(tag));
      setTags(existingTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSubmitDialog]);

  const handleOpenBackDialog = () => {
    setOpenBackDialog(true);
  };

  const handleCloseBackDialog = () => {
    setOpenBackDialog(false);
  };

  const handleOpenSubmitDialog = () => {
    // validate attributes in detail
    if (contentName.trim() === "") {
      dispatch(setContentName(""));
    }
    if (contentName.length > 40) {
      const errorText = parseVariables(tCommon("validation.maxLengthChars"), {
        num: 40,
      });
      setNameValidation(errorText);
      return;
    }

    const validationPayload = {
      name: sanitizeInput(contentName.trim()),
      width: contentRes.width,
      height: contentRes.height,
      contentLayers,
      layerMedias,
    };

    dispatch(
      validateContent(validationPayload, (validate) => {
        if (!validate) {
          return;
        }

        setOpenSubmitDialog(true);
      })
    );
  };

  const handleCloseSubmitDialog = () => {
    setOpenSubmitDialog(false);
  };

  return (
    <Box
      sx={{
        px: 1,
        pt: 2,
        pb: 1,
        borderTop: `2px solid ${colorStyling.primary}`,
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ px: 2 }}
      >
        <Button onClick={handleOpenBackDialog} startIcon={<ChevronLeftIcon />}>
          {t("designer.backBtn")}
        </Button>

        <Button
          variant="contained"
          sx={{ boxShadow: 3 }}
          disabled={loadingDetail || loadingSubmit}
          onClick={() => handleOpenSubmitDialog()}
        >
          {t("designer.submitBtn")}
        </Button>
      </Box>

      {/* Back Confirmation Dialog */}
      <BackToContent
        open={openBackDialog}
        handleClose={handleCloseBackDialog}
      />

      {/* Submit Confirmation Dialog */}
      <SubmitDialog
        open={openSubmitDialog}
        handleClose={handleCloseSubmitDialog}
        tags={tags}
        setTags={setTags}
      />
    </Box>
  );
};

export default TopBar;
