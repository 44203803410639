import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, TableCell, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { red } from "@mui/material/colors";

import { StyledTableRow, TypographyNormal } from "../../customComponent";
import EditUserGroup from "./EditUserGroup";
import DeleteUserGroup from "./DeleteUserGroup";
import Role from "../../../helpers/roles";
import { colorStyling } from "../../../helpers/color";

const UserGroupItem = ({ group }) => {
  const { user } = useSelector((state) => state.auth);
  const userRole = user?.type;
  const userGroupPermission = user?.permission?.userGroup;

  const { t } = useTranslation("usergroup");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => setOpenEdit(true);

  const handleClickCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = () => setOpenDelete(true);

  const handleClickCloseDelete = () => setOpenDelete(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <EditUserGroup
        open={openEdit}
        onClose={handleClickCloseEdit}
        group={group}
        editPermission={userGroupPermission?.update}
      />

      <DeleteUserGroup
        open={openDelete}
        onClose={handleClickCloseDelete}
        group={group}
      />

      <StyledTableRow>
        <TableCell sx={{ px: 4 }}>
          <Tooltip
            title={group?.name?.length > 30 ? group.name : ""}
            placement="top-start"
          >
            <TypographyNormal>
              {group?.name?.length > 30
                ? group.name.substring(0, 27).trim() + "..."
                : group.name}
            </TypographyNormal>
          </Tooltip>
        </TableCell>
        {userRole === Role.Eins && (
          <TableCell sx={{ px: 4 }}>
            <Tooltip
              title={
                group?.organizationName?.length > 30
                  ? group.organizationName
                  : ""
              }
              placement="top-start"
            >
              <TypographyNormal>
                {group?.organizationName?.length > 30
                  ? group?.organizationName.substring(0, 27).trim() + "..."
                  : group?.organizationName}
              </TypographyNormal>
            </Tooltip>
          </TableCell>
        )}
        <TableCell sx={{ px: 4 }} align="right">
          {(userGroupPermission?.update ||
            userGroupPermission?.delete ||
            userRole !== Role.User) && (
            <>
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: colorStyling.black }}
              >
                <MoreHorizIcon />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {(userGroupPermission?.read || userRole !== Role.User) && (
                  <MenuItem onClick={handleClickOpenEdit} dense>
                    <InfoOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
                    {t("item.viewDetailsMenuBtn")}
                  </MenuItem>
                )}

                {(userGroupPermission?.delete || userRole !== Role.User) && (
                  <MenuItem
                    sx={{ color: red[600] }}
                    onClick={handleClickOpenDelete}
                    dense
                  >
                    <DeleteOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
                    {t("item.deleteMenuBtn")}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default UserGroupItem;
