import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  submitContent,
  setLoadingSubmit,
  validateContent,
  setContentTags,
} from "../../../../store/actions/cmsAction";
import { styled } from "@mui/material/styles";
import { colorStyling } from "../../../../helpers/color";
import { SET_CMS_FILTER } from "../../../../store/actionTypes/cmsActionType";
import { sanitizeInput } from "../../../../helpers/inputProcessing";
import {
  TypographyLarge,
  TypographySmall,
  TypographyXSmall,
} from "../../../customComponent";
import parser from "html-react-parser";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const SubmitDialog = ({ open, handleClose, tags, setTags }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    layerMedias,
    contentLayers,
    contentRes,
    contentName,
    isEditing,
    contentDetail,
    loadingSubmit,
    widthRatio,
    heightRatio,
    cmsDetails,
    contentTags,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [tagInput, setTagInput] = useState("");
  const [tagValidation, setTagValidation] = useState("");

  const handleCloseSubmitDialog = () => {
    handleClose();
  };

  const submitNewContent = async () => {
    dispatch(setLoadingSubmit(true));
    const tagsPayload = [];
    Object.keys(contentTags).forEach((tag) => tagsPayload.push(tag));

    const submitPayload = {
      name: sanitizeInput(contentName.trim()),
      tags: tagsPayload,
      width: contentRes.width,
      height: contentRes.height,
      contentLayers,
      layerMedias,
      widthRatio,
      heightRatio,
      cacheDetails: cmsDetails,
    };

    if (isEditing) {
      if (contentName === contentDetail.name) {
        submitPayload["sameName"] = true;
      }
      submitPayload["editID"] = contentDetail.id;
    }

    dispatch(
      validateContent(submitPayload, (validate) => {
        if (!validate) {
          return;
        }

        dispatch(
          submitContent(submitPayload, () => {
            dispatch({
              type: SET_CMS_FILTER,
              payload: {
                nameLike: "",
                sortBy: "idDesc",
                searchByUser: "",
                searchByUserIDs: [],
                searchByTags: [],
              },
            });
            navigate("/cms/content-template");
          })
        );
      })
    );
  };

  const handleTagInput = (event) => {
    setTagValidation("");
    if (event.key === "Enter") {
      if (tagInput.trim() === "") {
        setTagInput("");
        return;
      }
      if (tags.includes(tagInput)) {
        setTagValidation(t("submitDialog.tagErrorAlreadyExist"));
        return;
      }
      if (/[^a-zA-Z0-9]/.test(tagInput)) {
        setTagValidation(t("submitDialog.tagErrorRegexTrue"));
        return;
      }

      setTags([...tags, tagInput]);
      const updatedTags = { ...contentTags, [tagInput]: true };
      dispatch(setContentTags(updatedTags));
      setTagInput("");
    }
  };

  const handleTagRemove = (tag) => {
    const updatedTags = { ...contentTags };
    delete updatedTags[tag];
    dispatch(setContentTags(updatedTags));

    const newTags = [];
    Object.keys(updatedTags).forEach((tag) => newTags.push(tag));
    setTags(newTags);
  };

  const contentOrientation = useMemo(() => {
    switch (contentRes.orientation) {
      default:
      case "P":
        return t("designer.orientationPortrait");
      case "L":
        return t("designer.orientationLandscape");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRes]);

  return (
    <>
      <Dialog maxWidth="md" fullWidth open={open}>
        <DialogTitle
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {t("submitDialog.title")}
        </DialogTitle>

        <DialogContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <DialogContentText
                sx={{
                  mb: 1,
                  textAlign: "center",
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
              >
                {t("submitDialog.contentInfoLabel")}
              </DialogContentText>

              <Box sx={{ p: 2, justifyItems: "center" }}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    mx: 4,
                    width: "fit-content",
                  }}
                >
                  <StyledBox display={"flex"} alignItems={"baseline"}>
                    <InputLabel>
                      {t("submitDialog.contentNameLabel")}
                    </InputLabel>
                    <TypographyLarge sx={{ ml: 2 }}>
                      {contentName.trim()}
                    </TypographyLarge>
                  </StyledBox>

                  <StyledBox display={"flex"} alignItems={"baseline"}>
                    <InputLabel>{t("submitDialog.resolutionLabel")}</InputLabel>
                    <TypographyLarge sx={{ ml: 2 }}>
                      {`${contentRes.width} x ${contentRes.height} (${contentOrientation})`}
                    </TypographyLarge>
                  </StyledBox>

                  <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        mt: 1,
                        height: "max-content",
                        placeSelf: "start",
                      }}
                    >
                      <InputLabel>
                        {t("submitDialog.contentTagsLabel")}
                      </InputLabel>
                      <TypographyXSmall>
                        {parser(t("submitDialog.contentTagsHint"))}
                      </TypographyXSmall>
                    </Box>

                    <Box
                      width={"275px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        margin="dense"
                        size="small"
                        variant="outlined"
                        placeholder={t("submitDialog.tagPlaceholder")}
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onKeyDown={handleTagInput}
                        sx={{
                          ml: 2,
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: colorStyling.black,
                            backgroundColor: colorStyling.white.inactive,
                          },
                        }}
                      />

                      <TypographySmall
                        sx={{
                          ml: 3,
                          color: colorStyling.red,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {tagValidation}
                      </TypographySmall>

                      <Box
                        display={"flex"}
                        gap={1}
                        sx={{ mt: 1, ml: 2, flexWrap: "wrap" }}
                      >
                        {tags.map((item, index) => (
                          <Chip
                            key={index}
                            label={item}
                            size="small"
                            color="success"
                            onDelete={() => handleTagRemove(item)}
                            sx={{
                              color: "white",
                              backgroundColor: "mediumseagreen",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleCloseSubmitDialog}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={submitNewContent}
          >
            {tCommon("submitBtn")}
          </Button>
        </DialogActions>

        <Backdrop
          sx={{
            color: colorStyling.white.inactive,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loadingSubmit}
          onClick={null}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  );
};

export default SubmitDialog;
