import { coreFeatureFlag } from "../../helpers/featureFlag";
import {
  SET_AUTH_USER,
  SET_AUTH_USER_LOGIN,
  SET_AUTH_ENCRYPTED_DATA,
  SET_AUTH_FEATURES,
} from "../actionTypes/userActionType";

const initialState = {
  user: {},
  otpToken: "",
  loggedUser: "",
  features: coreFeatureFlag,
  userLogin: {},
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH_USER:
      return { ...state, user: payload };

    case SET_AUTH_USER_LOGIN:
      return { ...state, userLogin: payload };

    case SET_AUTH_ENCRYPTED_DATA:
      return { ...state, loggedUser: payload };

    case SET_AUTH_FEATURES:
      return { ...state, features: payload };

    default:
      return state;
  }
};
