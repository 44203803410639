import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import Role from "../helpers/roles";
import { externalRoutes } from "../externalFeatures/router";
import { authRoutes, normalRoutes, superRoutes } from "./routerConfig";
import { useRemoteConfig } from "../components/hooks";
import { AuthorizationLandingRoute } from "../auth/AuthorizationRoute";
import { coreFeatureFlag, getGlobalKey } from "../helpers/featureFlag";
import * as Sentry from "@sentry/react";
import { getUserAuth } from "../store/actions/authAction";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../store/actionTypes/globalActionType";
import { GlobalErrorPrompt } from "../components";
import { SET_AUTH_FEATURES } from "../store/actionTypes/userActionType";

Sentry.init({
  environment: process.env.REACT_APP_ENV ?? "dev",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RouterProvider = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t: tCommon } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  const [orgFeaturesFlag, setOrgFeaturesFlag] = useState(coreFeatureFlag);

  const globalFF = useRemoteConfig(getGlobalKey(), JSON.parse, coreFeatureFlag);

  const routes = useMemo(() => {
    const allRoutes = [
      ...authRoutes,
      ...(user.type === Role.Eins
        ? superRoutes
        : [...normalRoutes, ...externalRoutes]),
    ];

    if (Object.keys(user).length === 0) {
      return allRoutes;
    } else {
      return allRoutes.filter(
        (route) =>
          /**
           * Show when :
           * - orgFeatureFlag[route.flag] = true
           * - route doesnt have flag
           */
          orgFeaturesFlag[route.flag] || route.flag === undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setupRoutes = (data) => {
    const orgDisabledFeatures = data?.organization?.featuresBlacklist ?? [];

    let featureFlags;
    if (globalFF.enabled) {
      featureFlags = { ...globalFF };
      orgDisabledFeatures.forEach((feature) => {
        featureFlags[feature] = false;
      });
    } else {
      return;
    }

    setOrgFeaturesFlag(featureFlags);
    dispatch({ type: SET_AUTH_FEATURES, payload: featureFlags });
  };

  useEffect(() => {
    if (!globalFF?.enabled) {
      return;
    }

    if (location.pathname.includes("login")) {
      return;
    }

    try {
      if (user.id) {
        setupRoutes(user);
      } else {
        dispatch(
          getUserAuth((data) => {
            setupRoutes(data);
          })
        );
      }
    } catch (error) {
      navigate("/");
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({
        type: SET_GLOBAL_ERROR_MESSAGE,
        payload: tCommon("session.expiredAlert"),
      });
      throw new Error(JSON.stringify(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, globalFF]);

  const landingRoute = (
    <Route
      path="/"
      key="landing"
      element={<AuthorizationLandingRoute user={user} />}
    />
  );

  return (
    <>
      <GlobalErrorPrompt customHorizontal={"left"} />

      <SentryRoutes>
        {landingRoute}

        {routes.map((route, index) => {
          const { path, element, wrapper: Wrapper } = route;
          const routeElement = Wrapper ? (
            <Wrapper user={user}>{element}</Wrapper>
          ) : (
            element
          );

          return <Route key={index} path={path} element={routeElement} />;
        })}
      </SentryRoutes>
    </>
  );
};

export default RouterProvider;
