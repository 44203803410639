import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, IconButton, Grid, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import AdvertisementScheduleInfo from "../../../externalFeatures/components/stellar/advertisementSchedule/AdvertisementScheduleInfo";
import ScheduleInfo from "../../schedule/id/ScheduleInfo";
import { useTranslation } from "react-i18next";
import { colorStyling } from "../../../helpers/color";
import { GroupBlueIcon, ViewerBlueIcon } from "../../../icons";

const RenderViewDetailSchedule = ({ schedule, onClose }) => {
  const { t: tCommon } = useTranslation("common");

  const getLink = (schedule) => {
    let linkString = "";
    switch (schedule?.type) {
      case "ANNOUNCEMENT":
        linkString = "/announcement/detail/" + schedule?.id;
        break;
      case "ADVERTISEMENT":
        linkString = "/rotating-schedule/detail/" + schedule?.id;
        break;

      default:
        linkString = "/schedule/detail/" + schedule?.id;
        break;
    }

    return linkString;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TypographyXLarge sx={{ fontWeight: 600 }}>
          {tCommon("viewDetail.scheduleDetailDialogTitle")}
        </TypographyXLarge>

        <IconButton onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              border: `1px solid ${colorStyling.white.border}`,
              borderRadius: "6px",
              p: 2,
            }}
          >
            {schedule?.type === "ADVERTISEMENT" ? (
              <AdvertisementScheduleInfo schedule={schedule} loading={false} />
            ) : (
              <ScheduleInfo schedule={schedule} loading={false} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <GroupInfo schedule={schedule} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <ViewerInfo schedule={schedule} />
        </Grid>

        <Grid item xs={12}>
          <Link to={getLink(schedule)}>
            <Button sx={{ mt: 2 }} fullWidth variant="contained">
              {tCommon("viewDetail.moreDetails")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default RenderViewDetailSchedule;

const GroupInfo = ({ schedule }) => {
  const { t: tCommon } = useTranslation("common");
  const navigate = useNavigate();
  return (
    <Box>
      <TypographyXLarge sx={{ fontWeight: 500, mb: 1 }}>
        {tCommon("viewDetail.groupLabel")}
      </TypographyXLarge>

      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          p: 2,
        }}
      >
        {Array.isArray(schedule?.screenGroups) &&
        schedule?.screenGroups?.length ? (
          <Grid container spacing={2}>
            {schedule?.screenGroups?.map((group) => (
              <Grid
                key={group.id}
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" alignItems="center">
                  <GroupBlueIcon />
                  <Tooltip
                    title={group?.name?.length > 30 ? group?.name : ""}
                    placement="top-start"
                  >
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        ml: 2,
                        cursor: "pointer",
                        "&:hover": {
                          color: colorStyling.primary,
                        },
                      }}
                      onClick={() => {
                        navigate("/group/detail/" + group.id);
                      }}
                    >
                      {group?.name?.length > 30
                        ? group?.name?.substring(0, 27).trim() + "..."
                        : group?.name}
                    </TypographyLarge>
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link to={"/group/detail/" + group.id}>
                    <IconButton sx={{ color: colorStyling.black, ml: 4 }}>
                      <Tooltip title={tCommon("viewDetail.seeDetails")}>
                        <NavigateNextIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TypographyLarge sx={{ textAlign: "center", fontWeight: 300 }}>
            {tCommon("viewDetail.noGroupData")}
          </TypographyLarge>
        )}
      </Box>
    </Box>
  );
};

const ViewerInfo = ({ schedule }) => {
  const { t: tCommon } = useTranslation("common");
  const navigate = useNavigate();
  return (
    <Box>
      <TypographyXLarge sx={{ fontWeight: 500, mb: 1 }}>
        {tCommon("viewDetail.viewerLabel")}
      </TypographyXLarge>

      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          p: 2,
        }}
      >
        {Array.isArray(schedule?.screens) && schedule?.screens?.length ? (
          <Grid container spacing={2}>
            {schedule?.screens?.map((screen) => (
              <Grid
                key={screen.id}
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" alignItems="center">
                  <ViewerBlueIcon />
                  <Tooltip
                    title={screen?.name?.length > 30 ? screen?.name : ""}
                    placement="top-start"
                  >
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        ml: 2,
                        cursor: "pointer",
                        "&:hover": {
                          color: colorStyling.primary,
                        },
                      }}
                      onClick={() => {
                        navigate("/viewer/detail/" + screen.id);
                      }}
                    >
                      {screen?.name?.length > 30
                        ? screen?.name?.substring(0, 27).trim() + "..."
                        : screen?.name}
                    </TypographyLarge>
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link to={"/viewer/detail/" + screen.id}>
                    <IconButton sx={{ color: colorStyling.black, ml: 4 }}>
                      <Tooltip title={tCommon("viewDetail.seeDetails")}>
                        <NavigateNextIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TypographyLarge sx={{ textAlign: "center", fontWeight: 300 }}>
            {tCommon("viewDetail.noViewerData")}
          </TypographyLarge>
        )}
      </Box>
    </Box>
  );
};
