import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import PreviewBox from "./PreviewBox";
import DirectUpload from "./DirectUpload";
import ContentTemplateSuccessPrompt from "../ContentTemplateSuccessPrompt";
import ContentTemplateErrorPrompt from "../ContentTemplateErrorPrompt";
import Sidebar from "../../../sidebar/Sidebar";
import {
  setContentLayers,
  setLayerMedias,
  setContentName,
  setContentResolution,
  setCanvasRes,
  setLoadingSubmit,
  setTextLayers,
  setLayerArray,
  setContentTags,
} from "../../../../store/actions/cmsAction";
import { logger } from "../../../../helpers/logger";
import { SET_CONTENT_IS_EDITING } from "../../../../store/actionTypes/cmsActionType";
import ContainersList from "./ContainersList";
import Form from "./Form";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../../../store/actionTypes/globalActionType";
import GlobalErrorPrompt from "../../../global/GlobalErrorPrompt";
import { parseVariables } from "../../../../helpers/localize";
import TopBar from "./TopBar";

const assetPerLayerLimit = process.env.REACT_APP_ASSET_PER_LAYER_LIMIT || 50;

const CreateContentTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disableDirect } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const { user } = useSelector((state) => state.auth);
  const permission = user?.permission;
  const contentPermission = permission?.content;
  const assetPermission = permission?.asset;

  const [resType, setResType] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [uploadBoxActive, setUploadBoxActive] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      disabled: disableDirect || resType.length === 0,
      noClick: true,
      accept: {
        "image/*": [],
        "video/*": [],
      },
    });

  useEffect(() => {
    if (!permission) {
      return;
    }

    if (!contentPermission?.create || !assetPermission?.read) {
      navigate("/");
      return;
    }

    dispatch({ type: SET_CONTENT_IS_EDITING, payload: false });
    dispatch(setLoadingSubmit(false));
    dispatch(setContentName(""));
    dispatch(setContentLayers({}));
    dispatch(setContentTags({}));
    dispatch(setTextLayers({}));
    dispatch(setLayerArray([]));
    dispatch(setLayerMedias({ reset: true }));
    dispatch(
      setContentResolution({
        width: 0,
        height: 0,
        custom: false,
        orientation: "P",
        label: "",
      })
    );
    dispatch(
      setCanvasRes({
        width: 360,
        height: 640,
        orientation: "P",
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      logger.log(`acceptedFiles:`, acceptedFiles);
      if (acceptedFiles.length > assetPerLayerLimit) {
        logger.log(`LIMITED!`, assetPerLayerLimit);
        dispatch({ type: SET_GLOBAL_ERROR, payload: true });
        dispatch({
          type: SET_GLOBAL_ERROR_MESSAGE,
          payload: parseVariables(t("error.directUploadLimitReached"), {
            maxCount: assetPerLayerLimit,
            limit: assetPerLayerLimit,
          }),
        });
        return true;
      }

      setLoadingFile(true);
      setUploadBoxActive(true);
      setLoadingFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <GlobalErrorPrompt />

      <Box component="main" sx={{ flexGrow: 1, p: 1, overflowX: "auto" }}>
        <Toolbar />
        <CssBaseline />

        <TopBar setNameValidation={setNameValidation} />

        <Box height={"85vh"} {...getRootProps()}>
          <input {...getInputProps()} />

          <DirectUpload
            resType={resType}
            acceptedFiles={acceptedFiles}
            isDragActive={isDragActive}
            uploadBoxActive={uploadBoxActive}
            setUploadBoxActive={setUploadBoxActive}
            loadingFile={loadingFile}
            setLoadingFile={setLoadingFile}
          />

          {(!isDragActive || resType.length === 0) && (
            <>
              <ContentTemplateSuccessPrompt />
              <ContentTemplateErrorPrompt />

              <Box display={"flex"} gap={3} sx={{ px: 2 }}>
                <Box
                  sx={{
                    pr: 2,
                    width: "60%",
                    overflowY: "auto",
                    maxHeight: "80vh",
                  }}
                >
                  <Form
                    resType={resType}
                    setResType={setResType}
                    nameValidation={nameValidation}
                    setNameValidation={setNameValidation}
                  />
                  <ContainersList resType={resType} />
                </Box>

                <PreviewBox />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default CreateContentTemplate;
