import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  Toolbar,
} from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import ScheduleSuccessPrompt from "./ScheduleSuccessPrompt";
import ScheduleErrorPrompt from "./ScheduleErrorPrompt";
import ScheduleList from "./ScheduleList";
import CreateSchedule from "./CreateSchedule";
import ScheduleFilter from "./ScheduleFilter";
import Sidebar from "../sidebar/Sidebar";
import TodayIcon from "@mui/icons-material/Today";

const Schedule = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("schedule");

  const { user, features } = useSelector((state) => state.auth);
  const permission = user?.permission;
  const schedulePermission = permission?.schedule;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["SCHEDULE"] === false) {
      navigate("/");
      return;
    }

    if (permission && !schedulePermission?.read) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ pb: 2 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <ScheduleSuccessPrompt />
          <ScheduleErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {features["SCHEDULE.CALENDAR"] ? (
              <Button
                variant="contained"
                startIcon={<TodayIcon />}
                onClick={() => navigate("/schedule/calendar")}
              >
                {t("calendarBtn")}
              </Button>
            ) : (
              <Box></Box>
            )}
            <Box>{schedulePermission?.create && <CreateSchedule />}</Box>
          </Box>

          <Paper
            elevation={2}
            component="section"
            sx={{ borderRadius: "6px", my: 2, p: 2 }}
          >
            <ScheduleFilter />
          </Paper>

          <ScheduleList />
        </Container>
      </Box>
    </div>
  );
};

export default Schedule;
