import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ApproveScheduleConfirmation from "./ApproveScheduleConfirmation";

const ApproveSchedule = ({ schedule }) => {
  const { t } = useTranslation("schedule");

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={schedule.isApproved ? "warning" : "success"}
        variant="contained"
        startIcon={
          schedule.isApproved ? (
            <UnpublishedIcon fontSize="small" />
          ) : (
            <CheckCircleIcon fontSize="small" />
          )
        }
        sx={{ mr: 2, boxShadow: 3 }}
      >
        {schedule.isApproved ? t("item.suspendBtn") : t("item.approveBtn")}
      </Button>

      <ApproveScheduleConfirmation
        open={open}
        schedule={schedule}
        handleClickClose={handleClickClose}
      />
    </>
  );
};

export default ApproveSchedule;
