import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  CssBaseline,
  CircularProgress,
  Alert,
  Button,
  Paper,
  Link,
} from "@mui/material";
import TimerCountdown from "./TimerCountdown";
import {
  CustomTextField,
  Typography2XLarge,
  TypographyNormal,
  TypographySmall,
} from "../customComponent";
import { initiateAXIOS } from "../../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { SET_AUTH_USER } from "../../store/actionTypes/userActionType";
import dayjs from "dayjs";
import { colorStyling } from "../../helpers/color";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import { logger } from "../../helpers/logger";
import Role from "../../helpers/roles";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";
import { setAuthCookies } from "../../store/actions/authAction";

const Error = ({ handleOnCloseErrorPrompt, errorMessage }) => {
  const handleClose = () => {
    handleOnCloseErrorPrompt(false);
  };

  return (
    <Alert onClose={handleClose} severity="error" sx={{ my: 1 }}>
      {errorMessage}
    </Alert>
  );
};

const TwoFactorAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLogin } = useSelector((state) => state.auth);

  const { t } = useTranslation("user");

  const [otp, setOtp] = useState("");
  const [counterError, setCounterError] = useState(1);

  const [loading, setLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("");
  const [target, setTarget] = useState("");

  const removeOTPToken = () => {
    sessionStorage.removeItem("otpKey");
    sessionStorage.removeItem("requestToken");
    sessionStorage.removeItem("userLogin");
  };

  useEffect(() => {
    if (!userLogin?.username || !userLogin?.password) {
      removeOTPToken();
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userLogin?.target?.length > 0) {
      setTarget(userLogin?.target);
    }
  }, [userLogin]);

  const handleOnCloseErrorPrompt = ({ close }) => {
    setIsError(close);
  };

  const navigateHome = (userRole) => {
    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("login.successAlertMsg"),
    });
    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);

    if (userRole === Role.Eins) {
      navigate("/users");
    } else {
      navigate("/dashboard");
    }

    removeOTPToken();
  };

  const navigateExpiredPassword = () => {
    dispatch({ type: SET_GLOBAL_ERROR, payload: true });
    dispatch({
      type: SET_GLOBAL_ERROR_MESSAGE,
      payload: t("login.passExpiredAlertMsg"),
    });
    handleTimeoutPrompt(dispatch, SET_GLOBAL_ERROR);
    navigate("/expired-password");
    removeOTPToken();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmptyMessage("");

    const otpTrim = otp.trim();
    if (otpTrim.length === 0 || otp.length !== 6) {
      setEmptyMessage(t("common.otpInputEmpty"));
      setOtp(otpTrim);
      return;
    }

    setLoading(true);

    const otpKey = sessionStorage.getItem("requestToken");
    try {
      const { data } = await initiateAXIOS.post("/api/auth/login/", {
        ...userLogin,
        otp: otp,
        ...(target !== "TOTP" && { otpToken: otpKey }),
      });

      const access_token = data.token;
      const refresh_token = data?.refreshToken;

      const timeStamp = dayjs();
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("refresh_token", refresh_token);
      sessionStorage.setItem("lastTimeStamp", timeStamp);
      setAuthCookies("access_token", access_token);
      setAuthCookies("refresh_token", refresh_token);

      const user = { ...data.user, permission: data.user.userGroup.permission };
      const userRole = user.type;
      dispatch({ type: SET_AUTH_USER, payload: user });

      if (data.isPasswordExpired) navigateExpiredPassword();
      else navigateHome(userRole);
    } catch (e) {
      logger.error(e);
      setCounterError((prevCount) => prevCount + 1);

      const errorMessage = e.response.data.message;

      if (
        errorMessage ===
        "Authentication error, too many multi factor authentication attempt."
      ) {
        setCounterError(+process.env.REACT_APP_MAX_OTP_TRIES);
      }

      setOtp("");
      setIsError(true);
      setErrorMessage(errorMessage);

      if (counterError === +process.env.REACT_APP_MAX_OTP_TRIES) {
        sessionStorage.clear();

        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }

      setTimeout(() => {
        setIsError(false);
      }, 5000);

      setOtp("");
      setLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />

      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "50%",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <img
            src="/assets/itxcast-logo.png"
            alt="ITXCast logo"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />

          <Typography2XLarge sx={{ mt: 2, textAlign: "center" }} gutterBottom>
            {t("mfa.2faTitle")}
          </Typography2XLarge>

          {target !== "TOTP" ? (
            <TypographyNormal sx={{ fontWeight: 300, textAlign: "center" }}>
              {parser(parseVariables(t("mfa.2faHint"), { email: target }))}{" "}
              <Link
                underline="hover"
                onClick={removeOTPToken}
                href="/login"
                sx={{ textDecoration: "none", color: colorStyling.primary }}
              >
                {t("mfa.2faBackToLogin")}
              </Link>
            </TypographyNormal>
          ) : (
            <>
              <Alert severity="info">
                {t("mfa.2faTotpHint")}{" "}
                <Link
                  underline="hover"
                  onClick={removeOTPToken}
                  href="/login"
                  sx={{ textDecoration: "none", color: colorStyling.primary }}
                >
                  {t("mfa.2faBackToLogin")}
                </Link>
              </Alert>
            </>
          )}

          {counterError < 4 && target !== "TOTP" && <TimerCountdown />}

          {isError && (
            <Error
              handleOnCloseErrorPrompt={handleOnCloseErrorPrompt}
              errorMessage={errorMessage}
            />
          )}

          <CustomTextField
            autoFocus
            placeholder={t("mfa.2faCodeInputPlaceholder")}
            fullWidth
            margin="normal"
            disabled={counterError === 4}
            value={otp}
            required
            autoComplete="off"
            onChange={(e) => {
              setOtp(e.target.value);
              setEmptyMessage("");
            }}
            inputProps={{ maxLength: 6 }}
          />

          {emptyMessage.length > 0 && (
            <Box
              sx={{ pl: 1, pb: 1, display: "flex", justifyContent: "start" }}
            >
              <TypographySmall sx={{ color: colorStyling.red }}>
                {emptyMessage}
              </TypographySmall>
            </Box>
          )}

          <Box>
            {loading ? (
              <Button disabled fullWidth variant="contained">
                <CircularProgress color="inherit" size={20} thickness={2} />
              </Button>
            ) : (
              <Button
                disabled={counterError === 4}
                variant="contained"
                fullWidth
                type="submit"
              >
                {t("login.confirmBtn")}
              </Button>
            )}
          </Box>

          {counterError === 4 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              {t("mfa.2faMaxAttemptsError")}
            </Alert>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default TwoFactorAuthentication;
