import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography2XLarge, TypographyXLarge } from "./customComponent";
import { DisconnectedIcon } from "../icons";
import { colorStyling } from "../helpers/color";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const NotFound = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const theme = useTheme();
  const { t: tCommon } = useTranslation();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isEmpty(user)) navigate("/login");
    // eslint-disable-next-line
  }, [user]);

  return (
    <Box
      sx={{
        backgroundColor: colorStyling.white.hover,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Typography2XLarge
        sx={{
          fontSize: lg ? "4rem" : "2rem",
          mb: 4,
          fontWeight: 700,
          textAlign: "center",
          p: 1,
        }}
      >
        {tCommon("404.title")}
      </Typography2XLarge>
      <TypographyXLarge
        sx={{
          fontWeight: 600,
          mb: 4,
          textAlign: "center",
          fontSize: lg ? "1.5rem" : "1rem",
          p: 1,
        }}
      >
        {tCommon("404.titleExplain")}
      </TypographyXLarge>

      {!isEmpty(user) ? (
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{ mb: 8 }}>
            {tCommon("404.backToHomeBtn")}
          </Button>
        </Link>
      ) : (
        <Link to="/login" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{ mb: 8 }}>
            {tCommon("404.backToLoginBtn")}
          </Button>
        </Link>
      )}

      {lg && <DisconnectedIcon />}
    </Box>
  );
};

export default NotFound;
