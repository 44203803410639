import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import { useTranslation } from "react-i18next";
import DisableMFADialogFormOTP from "./mfa/DisableMFADialogFormOTP";
import { getUserAuth } from "../../store/actions/authAction";

const DeactivateMFA = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("user");

  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    dispatch(getUserAuth());
    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("account.deactivateMFASuccess"),
    });

    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="warning"
        size="small"
        sx={{ px: "5px", py: 0 }}
        onClick={handleOpenDialog}
      >
        {t("account.deactivateMFABtn")}
      </Button>

      <DisableMFADialogFormOTP
        open={open}
        onClose={handleCloseDialog}
        handleSuccess={handleSuccess}
        dialogTitle={t("account.deactivateMFADialogTitle")}
      />
    </>
  );
};

export default DeactivateMFA;
