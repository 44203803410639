export const SET_CONTENT_FIRST_LOAD = "SET_CONTENT_FIRST_LOAD";
export const SET_LOADING_MEDIA = "SET_LOADING_MEDIA";
export const SET_LOADING_CMS = "SET_LOADING_CMS";
export const SET_FORCE_LOAD = "SET_FORCE_LOAD";
export const SET_CMS_DATA = "SET_CMS_DATA";
export const SET_CMS_FILTER = "SET_CMS_FILTER";
export const SET_CMS_DETAILS = "SET_CMS_DETAILS";
export const SET_CMS_ROWS_PER_PAGE = "SET_CMS_ROWS_PER_PAGE";
export const SET_ASSET_DATA = "SET_ASSET_DATA";
export const SET_SUCCESS_PROMPT_CMS_PAGE = "SET_SUCCESS_PROMPT_CMS_PAGE";
export const SET_SUCCESS_MESSAGE_CMS_PAGE = "SET_SUCCESS_MESSAGE_CMS_PAGE";
export const SET_ERROR_PROMPT_CMS_PAGE = "SET_ERROR_PROMPT_CMS_PAGE";
export const SET_ERROR_MESSAGE_CMS_PAGE = "SET_ERROR_MESSAGE_CMS_PAGE";
export const SET_INFO_PROMPT_CMS_PAGE = "SET_INFO_PROMPT_CMS_PAGE";
export const SET_INFO_MESSAGE_CMS_PAGE = "SET_INFO_MESSAGE_CMS_PAGE";
export const SET_CMS_CONTENT_DATA = "SET_CMS_CONTENT_DATA";
export const SET_MEDIA_DATA = "SET_MEDIA_DATA";
export const SET_MEDIA_DATA_LENGTH = "SET_MEDIA_DATA_LENGTH";
export const SET_MEDIA_DATA_FILTER = "SET_MEDIA_DATA_FILTER";
export const SET_MEDIA_ERRORS = "SET_MEDIA_ERRORS";
export const SET_ADD_MEDIA_DATA = "SET_ADD_MEDIA_DATA";
export const SET_REMOVE_MEDIA_DATA = "SET_REMOVE_MEDIA_DATA";
export const SET_UPLOADING_MEDIA = "SET_UPLOADING_MEDIA";
export const SET_UPLOAD_MEDIA_DATA = "SET_UPLOAD_MEDIA_DATA";
export const SET_UPDATE_UPLOAD_STATE = "SET_UPDATE_UPLOAD_STATE";
export const SET_UPDATE_UPLOAD_RESPONSE = "SET_UPDATE_UPLOAD_RESPONSE";
export const SET_CONTENT_NAME = "SET_CONTENT_NAME";
export const SET_CONTENT_TAGS = "SET_CONTENT_TAGS";
export const SET_CONTENT_LAYERS = "SET_CONTENT_LAYERS";
export const SET_CONTENT_LAYER_ARRAY = "SET_CONTENT_LAYER_ARRAY";
export const SET_TEXT_LAYERS = "SET_TEXT_LAYERS";
export const SET_CONTENT_RESOLUTION = "SET_CONTENT_RESOLUTION";
export const SET_CONTENT_RESOLUTION_RATIO = "SET_CONTENT_RESOLUTION_RATIO";
export const SET_CANVAS_RES = "SET_CANVAS_RES";
export const SET_LAYER_MEDIAS = "SET_LAYER_MEDIAS";
export const SET_CMS_ERROR_HANDLER = "SET_CMS_ERROR_HANDLER";
export const SET_LOADING_SUBMIT_CONTENT = "SET_LOADING_SUBMIT_CONTENT";
export const SET_LOADING_CONTENT_DETAIL = "SET_LOADING_CONTENT_DETAIL";
export const SET_CONTENT_IS_EDITING = "SET_CONTENT_IS_EDITING";
export const SET_CONTENT_DETAIL = "SET_CONTENT_DETAIL";
export const SET_CONTENT_DISABLE_DIRECT = "SET_CONTENT_DISABLE_DIRECT";
export const SET_ADS_PLACEHOLDER_FLAG = "SET_ADS_PLACEHOLDER_FLAG";
export const SET_CONTENT_LIST_AFTER_ADD = "SET_CONTENT_LIST_AFTER_ADD";
export const SET_CONTENT_LIST_AFTER_REMOVE = "SET_CONTENT_LIST_AFTER_REMOVE";
export const SET_DEFAULT_ASSET_BEHAVIOR = "SET_DEFAULT_ASSET_BEHAVIOR";
