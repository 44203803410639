import { logger } from "./logger";

const SET_UPLOAD_4K_RES = 3840;
const expireTime = +process.env.REACT_APP_FILE_PROCESSING_EXPIRY || 480000;
const APP_ENV = process.env.REACT_APP_ENV;

export const getAllowedFilesKey = () => {
  let key = "allowed_files";
  switch (APP_ENV) {
    case "dev":
    case "staging":
      key += "_dev";
      break;

    default:
    case "prod":
      break;
  }

  return key;
};

export const defaultAllowedFiles = {
  mov: true,
  avi: false,
  wmv: false,
};

// Creating thumbnail from the first frame of the video
export const createVideoThumbnail = async (file, inputProps, allowedFiles) => {
  let thumbnail = null;
  var video = document.createElement("video");

  const fileType = inputProps.type;
  const canPlay = video.canPlayType(fileType);
  logger.log(`canPlayVideo? ${canPlay === "" ? "no" : "yes"}`);
  if (canPlay === "" && allowedFiles[inputProps.extension] === false) {
    logger.log(`Unsupported video format [${fileType}].`);
    return { thumbnail: null, duration: 0 };
  } else {
    video.src = file;
  }

  var timeupdate = function () {
    if (snapImage()) {
      video.removeEventListener("timeupdate", timeupdate);
      video.pause();
    }
  };

  let duration = 0;
  video.addEventListener("loadeddata", function () {
    if (snapImage()) {
      duration = Math.floor(video.duration);
      video.removeEventListener("timeupdate", timeupdate);
    }
  });

  var snapImage = function () {
    try {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      thumbnail = canvas.toDataURL();
      return true;
    } catch (error) {
      logger.error(`Error! createThumbnailFromVideo:`, error);
      return false;
    }
  };

  try {
    video.addEventListener("timeupdate", timeupdate);
    video.preload = "metadata";
    video.crossOrigin = "anonymous";

    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    await video.play();
    return { thumbnail, duration };
  } catch (error) {
    logger.error(`Error! createThumbnailFromVideo:`, error);
    return { thumbnail: null, duration: 0 };
  }
};

export const getImageSource = async (payload, allowedFiles) => {
  const { input, inputProps, resizedFlag } = payload;

  let fileURL;
  let videoURL;
  let file = input;
  if (typeof file === "string") {
    const getFile = await fetch(input);
    const getFileBlob = await getFile.blob();
    file = new File([getFileBlob], inputProps.name, { type: inputProps.type });
  }

  return new Promise(async (resolve, reject) => {
    const fileType = file.type.substring(0, file.type.indexOf("/"));
    if (!file || !fileType || fileType?.length === 0) {
      reject({
        file,
        message: new Error(`File empty or type unknown. [${file.type}]`),
      });
      return;
    }

    const unsupportedError = new Error(`Unsupported media file format [${file.type}].`);
    if (allowedFiles[inputProps.extension] === false) {
      reject({ file, message: unsupportedError });
      return;
    }

    let vidDuration = 0;
    switch (fileType) {
      case "image":
        fileURL = URL.createObjectURL(file);
        break;

      case "video":
        videoURL = URL.createObjectURL(file);
        const videoPostProc = await createVideoThumbnail(videoURL, inputProps, allowedFiles);
        fileURL = videoPostProc.thumbnail;
        vidDuration = videoPostProc.duration;

        if (fileURL === null) {
          reject({ file, message: unsupportedError });
          return;
        }
        break;

      default:
        break;
    }

    const img = new Image();
    img.src = fileURL;

    let breaker = 0;
    const loadImgInterval = setInterval(async () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      if ((imgWidth && imgHeight) && (fileType !== "video" || (vidDuration > 0))) {
        clearInterval(loadImgInterval);
        if (imgWidth > SET_UPLOAD_4K_RES || imgHeight > SET_UPLOAD_4K_RES) {
          switch (fileType) {
            case "image":
              const resizedImg = await resizeImage(input, file.type, SET_UPLOAD_4K_RES);
              const resizedFile = dataURLtoFile(resizedImg, file.name);
              const newResponse = await getImageSource({ input: resizedFile, inputProps, resizedFlag: true }, allowedFiles);
              resolve(newResponse);
              return;
      
            default:
            case "video":
              break;
          }
        }

        const response = {
          type: fileType,
          url: fileURL,
          dimension: {
            width: imgWidth,
            height: imgHeight,
          },
          videoURL,
          duration: vidDuration,
          localURL: videoURL ?? fileURL,
        };

        if (resizedFlag) {
          response["resized"] = input;
        }

        resolve(response);
      } else {
        breaker++;
        if (breaker === expireTime / 100) {
          logger.log(`getImageSource-BREAK!`);
          clearInterval(loadImgInterval);
        }
      }
    }, 100);
  });
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
};

export const resizeImage = (data, type, size) => {
  const RESIZED_RES = size ?? 480;
  return new Promise(async (resolve, reject) => {
    let thumbnail = null;
    // Load the image
    var reader = new FileReader();
    reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var canvas = document.createElement('canvas'),
            max_size = RESIZED_RES, // max res for width/height
            width = image.width,
            height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          thumbnail = canvas.toDataURL(type); // ex. "image/jpeg"
          resolve(thumbnail);
        }
        image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(data);
  });
};

export const splitFileName = (fileName) => {
  if (!fileName || fileName.length === 0) {
    return { name: "", extension: "" };
  }

  const parts = fileName.split(".");
  const extension = parts.length > 1 ? parts.pop() : "";
  const name = parts.join(".");
  return { name, extension };
};
