import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { initiateAXIOS } from "../../config/axios";
import { API_LIMIT, initiateOrganizationAPI } from "../../api";
import { createViewer } from "../../store/actions/viewerAction";
import Role from "../../helpers/roles";
import { logger } from "../../helpers/logger";
import { TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import { SET_ERROR_PROMPT_VIEWER_DIALOG } from "../../store/actionTypes/viewerActionTypes";
import { parseVariables } from "../../helpers/localize";
import { sanitizeInput } from "../../helpers/inputProcessing";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const CreateViewer = () => {
  const dispatch = useDispatch();
  const {
    isErrorPromptViewerDialog: isError,
    errorMessageViewerDialog: errorMessage,
  } = useSelector((state) => state.viewer);

  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const userRole = user?.type;
  const userOrg = user?.organization?.id;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [viewerName, setViewerName] = useState("");
  const [viewerKey, setViewerKey] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [keyValidation, setKeyValidation] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setViewerName("");
    setViewerKey("");
    setOrganizations([]);
    setSelectOrganization("");
    setNameValidation("");
    setKeyValidation("");
    dispatch({ type: SET_ERROR_PROMPT_VIEWER_DIALOG, payload: false });
    setOpen(false);
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setViewerName(input);
      if (nameValidation) setNameValidation("");
    }
  };

  const handleInputKey = (input) => {
    if (input.length > 6) setKeyValidation(t("validation.keyMaxLength"));
    else {
      setViewerKey(input);
      if (keyValidation) setKeyValidation("");
    }
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const submitForm = () => {
    if (viewerName.trim() === "") {
      setViewerName("");
      setNameValidation(t("validation.nameEmpty"));
      return;
    }

    if (viewerKey.trim() === "") {
      setViewerKey("");
      setKeyValidation(t("validation.keyEmpty"));
      return;
    }

    const payload = {
      name: viewerName.trim(),
      key: viewerKey.trim(),
      organizationId:
        selectOrganization === "" ? +userOrg : +selectOrganization,
    };

    setLoading(true);
    payload["name"] = sanitizeInput(payload.name);
    dispatch(createViewer(payload, handleFinishSubmit));
  };

  const getOrganizations = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const listOrganization = data.items;

      let organizationData = [];
      listOrganization.map((item) => organizationData.push(item));
      setOrganizations(organizationData);
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    if (open && userRole === Role.Eins) {
      getOrganizations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {userRole && (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<AddIcon fontSize="small" />}
          sx={{ boxShadow: 3, ml: 2 }}
        >
          {t("createBtn")}
        </Button>
      )}

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("form.newDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_VIEWER_DIALOG}
            />
            <StyledBox sx={{ mt: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                autoFocus
                margin="dense"
                fullWidth
                name="viewerName"
                placeholder={t("form.namePlaceholder")}
                value={viewerName}
                onChange={(e) => {
                  handleInputName(e.target.value);
                }}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </StyledBox>

            <StyledBox>
              <InputLabel>{t("form.keyLabel")}</InputLabel>

              <TextField
                autoFocus
                margin="dense"
                fullWidth
                name="viewerKey"
                placeholder={t("form.keyPlaceholder")}
                value={viewerKey}
                onChange={(e) => {
                  handleInputKey(e.target.value);
                }}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {keyValidation}
              </TypographySmall>
            </StyledBox>

            {userRole === Role.Eins && (
              <StyledBox>
                <InputLabel>{t("form.organizationLabel")}</InputLabel>

                <TextField
                  select
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  value={selectOrganization}
                  onChange={(e) => {
                    setSelectOrganization(e.target.value);
                  }}
                  disabled={loading}
                >
                  <option value="">{t("form.organizationSelect")}</option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </StyledBox>
            )}
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              {tCommon("cancelBtn")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={submitForm}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateViewer;
