import { initiateAXIOS } from "../../config/axios";
import {
  SET_AUTH_USER,
} from "../actionTypes/userActionType";
import {
  initiateAuthAPI,
} from "../../api";
import { logger } from "../../helpers/logger";
import Cookies from "js-cookie";

// If local, use secure = false & sameSite = "Lax" for cookies
const cookiesSecure = process.env.REACT_APP_COOKIES_SECURE || "false";
const cookiesSameSite = process.env.REACT_APP_COOKIES_SAMESITE || "Lax";

export const setAuthCookies = (key, value) => {
  Cookies.set(key, value, {
    expires: 7, // if not set, it'll be cleared like session
    secure: (cookiesSecure === "true"), // true = Only sent over HTTPS.
    sameSite: cookiesSameSite, // Prevents CSRF.
    httpOnly: true // Not accessible via JavaScript
  });
};

export const getAuthCookies = (key) => {
  return Cookies.get(key);
};

export const deleteAuthCookies = (key) => {
  Cookies.remove(key);
};

export const getUserAuth = (resolve) => {
  return async (dispatch) => {
    let access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(initiateAuthAPI + "user", {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const user = { ...data, permission: data.userGroup.permission };
      dispatch({ type: SET_AUTH_USER, payload: user });
      if (resolve) resolve(user);
    } catch (e) {
      logger.error(e);

      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data));
      }
    }
  };
};
