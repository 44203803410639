import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { formattedDate, formattedDateDash } from "../../helpers/dateFormat";
import { CstTypo14W300 } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import {
  API_LIMIT,
  instanceCMSViewerAPI,
  instanceCMSViewerGroupAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { CSVLink } from "react-csv";
import {
  getViewerStatus,
  getViewerStatusMinuteDiff,
} from "../../helpers/viewerHelper";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { filterObjOnly } from "../../helpers/filterHelper";

const initialHeaders = [
  { labelID: "Id", key: "id", order: 1 },
  { labelID: "Name", key: "name", order: 2 },
  { labelID: "Status", key: "status", order: 3 },
  { labelID: "LastActiveTime", key: "activeAt", order: 4 },
];

const ExportViewer = () => {
  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHeader, setErrorHeader] = useState("");

  const [status, setStatus] = useState("");
  const [group, setGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);

  const csvRef = useRef();
  const [reportData, setReportData] = useState([]);
  const [reportHeaders, setReportHeaders] = useState([{}]);
  const [csvHeaders, setCsvHeaders] = useState([{}]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  const handleCloseErr = () => {
    setIsError(false);
  };

  const setupReportHeaders = (headers) => {
    const updated = [];
    headers.forEach((header) => {
      updated.push({
        label: t(`export.header${header.labelID}`),
        labelID: header.labelID,
        key: header.key,
        order: header.order,
      });
    });
    setReportHeaders(updated);
  };

  useEffect(() => {
    if (open) {
      setupReportHeaders(initialHeaders);
      fetchGroup();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetData = () => {
    setStatus("");
    setSelectedGroup("");
    setGroup([]);
    setReportData([]);
    setupReportHeaders(initialHeaders);
  };

  const handleChangeStatus = (event) => {
    const value = event.target.value;
    setStatus(value);
  };

  const handleChangeGroup = (event) => {
    const value = event.target.value;
    setSelectedGroup(value);
  };

  useEffect(() => {
    if (reportData.length !== 0) {
      csvRef.current.link.click();
      handleClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const fetchReport = async () => {
    const access_token = sessionStorage.getItem("access_token");
    let allData = {};
    let query = "";
    let queryObj = {};

    if (!isEmpty(selectedGroup.trim())) {
      queryObj["screenGroupId"] = selectedGroup;
    }

    switch (status) {
      case "OFFLINE":
        queryObj["connectionStatus"] = status;
        queryObj["activeAtLte"] = dayjs()
          .subtract(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        delete query["activeAtGte"];
        break;

      case "ONLINE":
        queryObj["activeAtGte"] = dayjs()
          .subtract(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        queryObj["activeAtLte"] = dayjs()
          .add(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        delete queryObj["connectionStatus"];
        break;

      default:
        delete queryObj["connectionStatus"];
        delete queryObj["activeAtGte"];
        delete queryObj["activeAtLte"];
        break;
    }

    try {
      query = filterObjOnly(queryObj);
      const { data } = await initiateAXIOS.get(instanceCMSViewerAPI + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      allData = { totalItem: data.totalItem, items: data.items };
      let currentOffset = API_LIMIT; // Offset for pagination

      // Check if we need to fetch more data
      if (+data.totalItem > +API_LIMIT) {
        while (allData.items.length < +data.totalItem) {
          const { data: nextData } = await initiateAXIOS.get(
            instanceCMSViewerAPI + `&offset=${currentOffset}` + query,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          allData.items.push(...nextData.items);

          currentOffset += API_LIMIT;

          if (allData.items.length >= allData.totalItem) break;
        }
      }

      return allData;
    } catch (e) {
      setIsError(true);
      setErrorMessage(`[${e?.response?.status}] ${e?.response?.data?.message}`);
      setErrorHeader(t("export.errorHeaderFetchReport"));
      setTimeout(handleCloseErr, 5000);
      setLoading(false);
    }
  };

  const fetchGroup = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingGroup(true);
    try {
      const { data } = await initiateAXIOS.get(instanceCMSViewerGroupAPI, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setGroup(data.items);
    } catch (e) {
      setIsError(true);
      setErrorMessage(`[${e?.response?.status}] ${e?.response?.data?.message}`);
      setErrorHeader(t("export.errorGroupFetch"));
      setTimeout(handleCloseErr, 5000);
    } finally {
      setLoadingGroup(false);
    }
  };

  /**
   * generate csv function
   */
  const generateCsv = async () => {
    let csvData = [];

    setCsvHeaders(reportHeaders);
    setLoading(true);
    // initial fetch for total item
    const initialData = await fetchReport();
    csvData = csvData.concat(initialData.items);
    setLoading(false);

    if (csvData.length === 0) {
      setIsError(true);
      setErrorHeader(tCommon("noData"));
      setErrorMessage(t("export.noViewerHint"));
      setTimeout(handleCloseErr, 5000);
      return;
    }

    generateData(csvData);
  };

  const generateData = (payload) => {
    setReportData(
      payload.map(({ id, name, activeAt }) => ({
        id,
        name,
        status: getViewerStatus(activeAt) ? "ONLINE" : "OFFLINE",
        activeAt: formattedDate(activeAt),
      }))
    );
  };

  return (
    <>
      <Button
        sx={{ ml: 2, boxShadow: 3 }}
        variant="contained"
        onClick={handleOpen}
      >
        {t("export.exportBtn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} disableEscapeKeyDown={true}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("export.exportTitle")}
          <IconButton onClick={handleClose} disabled={loading}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isError && (
            <Alert
              sx={{ my: 2 }}
              severity="error"
              variant="filled"
              onClose={handleCloseErr}
            >
              <AlertTitle>{errorHeader}</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          {loading ? (
            <Box
              sx={{ height: "20vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={22}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <CstTypo14W300
                sx={{ color: colorStyling.primary, marginTop: "15px" }}
              >
                {tCommon("loadingDataList")}
              </CstTypo14W300>
            </Box>
          ) : (
            <>
              <Box>
                <InputLabel>{t("export.filter.statusLabel")}</InputLabel>

                <TextField
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                  sx={{ mr: 2, mt: 1 }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  value={status}
                  onChange={handleChangeStatus}
                >
                  <option value="">{t("export.filter.statusAny")}</option>
                  <option value="ONLINE">
                    {t("export.filter.statusOnline")}
                  </option>
                  <option value="OFFLINE">
                    {t("export.filter.statusOffline")}
                  </option>
                </TextField>
              </Box>

              <Box sx={{ mt: 2 }}>
                <InputLabel>{t("export.filter.groupLabel")}</InputLabel>

                {loadingGroup ? (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"center"}
                  >
                    <CircularProgress size={32} />
                  </Box>
                ) : (
                  <TextField
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    sx={{ mr: 2, mt: 1 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    value={selectedGroup}
                    onChange={handleChangeGroup}
                  >
                    <option value="">{t("export.filter.groupAny")}</option>
                    {group.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </TextField>
                )}
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            fullWidth
            disabled={loading}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={generateCsv}
            disabled={loading}
          >
            {tCommon("exportBtn")}
          </Button>
        </DialogActions>

        <CSVLink
          data={reportData}
          headers={csvHeaders}
          filename={`viewer-${formattedDateDash(new Date())}.csv`}
          ref={csvRef}
          separator={";"}
        ></CSVLink>
      </Dialog>
    </>
  );
};

export default ExportViewer;
