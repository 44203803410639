import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  activateSchedule,
  deactivateSchedule,
} from "../../../store/actions/scheduleAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";
import { showSanitizedInput } from "../../../helpers/inputProcessing";

const ActivateScheduleConfirmation = ({
  schedule,
  open,
  handleClickClose,
  callback,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [loading, setLoading] = useState(false);

  const successCallback = async () => {
    if (callback) await callback();
    setLoading(false);
    handleClickClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    dispatch(
      schedule.isActive
        ? deactivateSchedule(schedule.id, successCallback)
        : activateSchedule(schedule.id, successCallback)
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {
        if (!loading) handleClickClose();
      }}
    >
      <DialogContent>
        <DialogContentText
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          {schedule.isActive
            ? parser(
                parseVariables(t("item.deactivateConfirmationText"), {
                  name: showSanitizedInput(schedule.name),
                })
              )
            : parser(
                parseVariables(t("item.activateConfirmationText"), {
                  name: showSanitizedInput(schedule.name),
                })
              )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          fullWidth
          startIcon={<ClearIcon />}
          onClick={() => {
            if (!loading) handleClickClose();
          }}
          disabled={loading}
        >
          {tCommon("cancelBtn")}
        </Button>

        <Button
          variant="contained"
          fullWidth
          startIcon={!loading && <DoneIcon />}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : tCommon("continueBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateScheduleConfirmation;
