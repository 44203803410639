import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

const BackToContent = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const backNavigate = () => {
    navigate("/cms/content-template");
  };

  const handleCloseBackDialog = () => {
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseBackDialog} maxWidth="sm">
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {t("designer.backConfirmationTitle")}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{t("designer.backConfirmationHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleCloseBackDialog}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={backNavigate}
          >
            {tCommon("continueBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BackToContent;
