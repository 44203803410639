import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Tooltip } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import ActivateScheduleConfirmation from "./ActivateScheduleConfirmation";

const ActivateSchedule = ({ schedule }) => {
  const { t } = useTranslation("schedule");

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={schedule.isApproved ? "" : t("item.activateDisabledTip")}
        placement="top"
      >
        <Box>
          <Button
            variant="contained"
            color={schedule.isActive ? "error" : "primary"}
            startIcon={
              schedule.isActive ? (
                <UpdateDisabledIcon fontSize="small" />
              ) : (
                <UpdateIcon fontSize="small" />
              )
            }
            sx={{ mr: 2, boxShadow: 3 }}
            disabled={schedule.isApproved ? false : true}
            {...(schedule.isApproved && { onClick: () => setOpen(true) })}
          >
            {schedule.isActive
              ? t("item.deactivateBtn")
              : t("item.activateBtn")}
          </Button>
        </Box>
      </Tooltip>

      <ActivateScheduleConfirmation
        schedule={schedule}
        open={open}
        handleClickClose={handleClickClose}
      />
    </>
  );
};

export default ActivateSchedule;
