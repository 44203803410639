import {
  SET_LOADING_CMS,
  SET_CMS_DATA,
  SET_CMS_FILTER,
  SET_ASSET_DATA,
  SET_ADD_MEDIA_DATA,
  SET_REMOVE_MEDIA_DATA,
  SET_ERROR_MESSAGE_CMS_PAGE,
  SET_ERROR_PROMPT_CMS_PAGE,
  SET_LOADING_MEDIA,
  SET_MEDIA_DATA,
  SET_MEDIA_DATA_LENGTH,
  SET_SUCCESS_MESSAGE_CMS_PAGE,
  SET_SUCCESS_PROMPT_CMS_PAGE,
  SET_CONTENT_NAME,
  SET_CONTENT_TAGS,
  SET_UPLOADING_MEDIA,
  SET_UPLOAD_MEDIA_DATA,
  SET_UPDATE_UPLOAD_STATE,
  SET_UPDATE_UPLOAD_RESPONSE,
  SET_CONTENT_LAYERS,
  SET_TEXT_LAYERS,
  SET_CONTENT_RESOLUTION,
  SET_CONTENT_RESOLUTION_RATIO,
  SET_CANVAS_RES,
  SET_LAYER_MEDIAS,
  SET_CMS_ERROR_HANDLER,
  SET_LOADING_SUBMIT_CONTENT,
  SET_LOADING_CONTENT_DETAIL,
  SET_CONTENT_IS_EDITING,
  SET_CONTENT_DETAIL,
  SET_CONTENT_DISABLE_DIRECT,
  SET_ADS_PLACEHOLDER_FLAG,
  SET_FORCE_LOAD,
  SET_CONTENT_LIST_AFTER_ADD,
  SET_CONTENT_LIST_AFTER_REMOVE,
  SET_CONTENT_FIRST_LOAD,
  SET_CMS_DETAILS,
  SET_CMS_ROWS_PER_PAGE,
  SET_CONTENT_LAYER_ARRAY,
  SET_MEDIA_DATA_FILTER,
  SET_MEDIA_ERRORS,
  SET_DEFAULT_ASSET_BEHAVIOR,
  SET_INFO_PROMPT_CMS_PAGE,
  SET_INFO_MESSAGE_CMS_PAGE,
} from "../actionTypes/cmsActionType";
import update from 'immutability-helper';

const initialState = {
  firstLoad: false,
  forceLoad: false,
  loadingCMS: false,
  cmsData: {
    items: [],
    totalItem: 0,
  },
  cmsFilter: {
    nameLike: "",
    searchByUser: "",
    searchByUserIDs: [],
    searchByTags: [],
    sortBy: "idDesc",
  },
  cmsDetails: {},
  rowsPerPage: 10,
  isSuccessPromptCMSPage: false,
  isErrorPromptCMSPage: false,
  isInfoPromptCMSPage: false,
  successMessageCMSPage: "",
  errorMessageCMSPage: "",
  infoMessageCMSPage: "",
  media: [],
  mediaLength: 0,
  mediaFilter: {},
  mediaErrors: 0,
  disableDirect: false,
  uploadingMedia: {},
  loadingMedia: false,
  contentName: "",
  contentLayers: {},
  textLayers: {},
  layerArray: [],
  contentRes: {
    width: 0,
    height: 0,
    custom: false,
    orientation: "P",
    label: "",
  },
  canvasRes: { // preview resolution (NOT content)
    width: 360,
    height: 640,
    orientation: "P",
  },
  widthRatio: 0,
  heightRatio: 0,
  layerMedias: {},
  errorHandler: {
    contentName: false,
    contentLayers: false,
    resolution: false,
    selectedMedia: false,
    limitReached: false,
  },
  assetData: {},
  loadingSubmit: false,
  loadingDetail: false,
  isEditing: false,
  contentDetail: {},
  adsPlaceholderFlag: {},
  defaultAssetBehavior: "FIT",
  contentTags: {},
};

export const cmsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let data;
  let reset;
  let updated;
  let callback;
  let objIndex;
  let updatedMedia;
  switch (type) {
    case SET_CONTENT_FIRST_LOAD:
      return { ...state, firstLoad: payload };

    case SET_LOADING_CMS:
      return { ...state, loadingCMS: payload };

    case SET_FORCE_LOAD:
      return { ...state, forceLoad: payload };

    case SET_CMS_DATA:
      return { ...state, cmsData: payload };

    case SET_CMS_FILTER:
      return { ...state, cmsFilter: payload };

    case SET_CMS_DETAILS:
      return { ...state, cmsDetails: payload };

    case SET_CMS_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_MEDIA_DATA:
      return { ...state, media: payload };

    case SET_MEDIA_DATA_LENGTH:
      return { ...state, mediaLength: payload };

    case SET_MEDIA_DATA_FILTER:
      return { ...state, mediaFilter: payload };

    case SET_MEDIA_ERRORS:
      const { method } = payload;
      updated = state.mediaErrors;
      switch (method) {
        default:
        case "add":
          updated += 1;
          break;
        case "reset":
          updated = 0;
          break;
      }
      return { ...state, mediaErrors: updated };

    case SET_ADD_MEDIA_DATA: // currently not used
      const { index, ...newObj } = payload;
      updatedMedia = [newObj, ...state.media];
      return {
        ...state,
        media: updatedMedia,
      };

    case SET_REMOVE_MEDIA_DATA:
      updatedMedia = state.media.filter((el) => {
        return el.id !== payload.id;
      });

      return {
        ...state,
        media: [ ...updatedMedia ],
      };

    case SET_UPLOADING_MEDIA:
      return {
        ...state,
        uploadingMedia: { ...payload },
      };

    case SET_UPLOAD_MEDIA_DATA:
      updated = { ...state.uploadingMedia, ...payload };
      return {
        ...state,
        uploadingMedia: updated,
      };

    case SET_UPDATE_UPLOAD_STATE:
      objIndex = payload.id;
      updated = { ...state.uploadingMedia };
      if (updated[objIndex]) {
        if (payload.status) {
          updated[objIndex]["status"] = payload.status;
        }
        if (payload.progress) {
          updated[objIndex]["progress"] = payload.progress;
        }
      }

      return {
        ...state,
        uploadingMedia: updated,
      };

    case SET_UPDATE_UPLOAD_RESPONSE:
      objIndex = payload.id;
      const postResponse = payload.response;
      updated = { ...state.uploadingMedia };
      if (updated[objIndex]) {
        updated[objIndex]["response"] = postResponse;
      }

      return {
        ...state,
        uploadingMedia: updated,
      };

    case SET_LOADING_MEDIA:
      return { ...state, loadingMedia: payload };

    case SET_CONTENT_LIST_AFTER_ADD:
      data = [...state.cmsData.items];
      data.pop();

      updated = [payload].concat(data);
      return {
        ...state,
        cmsData: {
          items: updated,
          totalItem: state.cmsData.totalItem + 1,
        },
      };

    case SET_CONTENT_LIST_AFTER_REMOVE:
      const { batchIDs } = payload;

      data = state.cmsData.items;
      if (batchIDs?.length > 0) {
        updated = data.filter(
          (dt) => !batchIDs.includes(dt.id)
        );

        callback = payload.callback;
        callback();
      } else {
        updated = data.filter(
          (dt) => dt.id.toString() !== payload.id.toString()
        );
      }

      return {
        ...state,
        cmsData: {
          items: updated,
          totalItem: state.cmsData.totalItem - (batchIDs?.length ?? 1),
        },
      };

    case SET_SUCCESS_PROMPT_CMS_PAGE:
      return { ...state, isSuccessPromptCMSPage: payload };

    case SET_SUCCESS_MESSAGE_CMS_PAGE:
      return { ...state, successMessageCMSPage: payload };

    case SET_ERROR_PROMPT_CMS_PAGE:
      return { ...state, isErrorPromptCMSPage: payload };

    case SET_ERROR_MESSAGE_CMS_PAGE:
      return { ...state, errorMessageCMSPage: payload };

    case SET_INFO_PROMPT_CMS_PAGE:
      return { ...state, isInfoPromptCMSPage: payload };

    case SET_INFO_MESSAGE_CMS_PAGE:
      return { ...state, infoMessageCMSPage: payload };

    case SET_CONTENT_NAME:
      return { ...state, contentName: payload };

    case SET_CONTENT_TAGS:
      return { ...state, contentTags: payload };

    case SET_CONTENT_LAYERS:
      return { ...state, contentLayers: payload };

    case SET_CONTENT_LAYER_ARRAY:
      return { ...state, layerArray: payload };

    case SET_TEXT_LAYERS:
      if (payload.add) {
        return { ...state, textLayers: { ...state.textLayers, ...payload.add } };
      }
      if (payload.edit) {
        let updatedTextLayers = { ...state.textLayers };
        updatedTextLayers = update(updatedTextLayers, { [payload.edit.id]: { $merge: { edit: payload.edit.value } } });
        return { ...state, textLayers: updatedTextLayers };
      }
      return { ...state, textLayers: payload };

    case SET_CONTENT_RESOLUTION:
      return { ...state, contentRes: payload };

    case SET_CANVAS_RES:
      return { ...state, canvasRes: payload };

    case SET_LAYER_MEDIAS:
      const { layerID, layerMedias, forceUpdate } = payload;
      reset = payload["reset"];
      if (!layerID) {
        if (reset) {
          return { ...state, layerMedias: {} };
        }

        if (forceUpdate) {
          return { ...state, layerMedias: forceUpdate };
        }
      }

      updated = state;
      updated = update(updated, { layerMedias: { $merge: { [layerID]: [...layerMedias] } } });
      return { ...state, layerMedias: updated.layerMedias};

    case SET_CMS_ERROR_HANDLER:
      const { contentName, contentLayers, resolution, selectedMedia, limitReached } = payload;
      reset = payload["reset"];
      if (reset) {
        return { ...state, errorHandler: {
          contentName: false,
          contentLayers: false,
          resolution: false,
          selectedMedia: false,
          limitReached: false,
        }};
      }

      updated = state.errorHandler;
      if (contentName !== undefined) {
        updated.contentName = contentName;
      }
      if (contentLayers !== undefined) {
        updated.contentLayers = contentLayers;
      }
      if (resolution !== undefined) {
        updated.resolution = resolution;
      }
      if (selectedMedia !== undefined) {
        updated.selectedMedia = selectedMedia;
      }
      if (limitReached !== undefined) {
        updated.limitReached = limitReached;
      }
      return { ...state, errorHandler: { ...updated } };

    case SET_ASSET_DATA:
      const assetIDs = Object.keys(payload);
      const updatedAssetData = { ...state.assetData };
      assetIDs.forEach((assetID) => {
        updatedAssetData[assetID] = payload[assetID];
      });
      return { ...state, assetData: { ...updatedAssetData } };

    case SET_LOADING_SUBMIT_CONTENT:
      return { ...state, loadingSubmit: payload };
        
    case SET_LOADING_CONTENT_DETAIL:
      return { ...state, loadingDetail: payload };
        
    case SET_CONTENT_IS_EDITING:
      return { ...state, isEditing: payload };
        
    case SET_CONTENT_DETAIL:
      return { ...state, contentDetail: payload };
        
    case SET_CONTENT_RESOLUTION_RATIO:
      return { ...state, ...payload };
        
    case SET_CONTENT_DISABLE_DIRECT:
      return { ...state, disableDirect: payload };
        
    case SET_ADS_PLACEHOLDER_FLAG:
      reset = payload["reset"];
      if (reset) {
        return { ...state, adsPlaceholderFlag: {}};
      }

      return { ...state, adsPlaceholderFlag: {
        ...state.adsPlaceholderFlag,
        [payload.id]: payload.edit,
      }};
        
    case SET_DEFAULT_ASSET_BEHAVIOR:
      return { ...state, defaultAssetBehavior: payload };

    default:
      return state;
  }
};
