import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import appReducers from "./reducers";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { authReducer } from "./reducers/authReducer";
import externalFeatureReducers from "../externalFeatures/store/reducers";
import { logger } from "../helpers/logger";
import * as Sentry from "@sentry/react";

// Leave empty on purpose. Can insert Sentry options below in the future.
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const persistConfig = {
  storage,
  key: "loggedUser",
  whitelist: ["loggedUser"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const combinedReducers = combineReducers({
  auth: persistedReducer,
  ...appReducers,
  ...externalFeatureReducers,
});

const rootReducer = (state, action) => {
  if (action.type === "persist/REHYDRATE") {
    logger.log("Rehydrated payload:", action); // Check for the rehydrated data
  }

  if (action.type === "SET_AUTH_ENCRYPTED_DATA") {
    logger.log("Logged user payload:", action); // Check for the rehydrated data
  }

  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return combinedReducers(state, action);
};

window.addEventListener("unhandledrejection", (event) => {
  Sentry.captureException(event.reason);
});

window.onerror = function (message, source, lineno, colno, error) {
  Sentry.captureException(error);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWare = applyMiddleware(thunk);

const composeMiddleware = composeEnhancers(middleWare, sentryReduxEnhancer);

const store = createStore(rootReducer, composeMiddleware);

const persistor = persistStore(store);

export { persistor, store };
