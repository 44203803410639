import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import {
  AdsScheduleIcon,
  AnnouncementPurpleIcon,
  ClockIcon,
  CommandScheduleIcon,
  ContentScheduleIcon,
  GroupBlueIcon,
  ViewerBlueIcon,
} from "../../icons";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  TypographyNormal,
  TypographySmall,
  TypographyXSmall,
} from "../customComponent";
import AssignedComponentActionMenu from "./AssignedComponentActionMenu";
import { purple } from "@mui/material/colors";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../helpers/switchColor";
// import ViewerScreenshot from "../viewer/id/ViewerScreenshot";

const AssignedComponentItem = ({ type, item, sourceData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        ":last-child": {
          mb: 0,
        },
      }}
    >
      {type === "viewer" && <ViewerItem item={item} />}
      {type === "schedule" && <ScheduleItem item={item} />}
      {type === "group" && <GroupItem item={item} />}

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {/* TODO when implementing screenshot */}
        {/* {type === "viewer" && <ViewerScreenshot viewer={item} size="sm" />} */}

        {/* Hide menu for Instant Schedule */}
        {item?.type !== "INSTANT" && (
          <AssignedComponentActionMenu
            type={type}
            item={item}
            sourceData={sourceData}
          />
        )}
      </Box>
    </Box>
  );
};

export default AssignedComponentItem;

const ViewerItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ViewerBlueIcon />

      <Box sx={{ ml: 2 }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {item.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

const ScheduleItem = ({ item }) => {
  const { t } = useTranslation("schedule");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {item.type === "ANNOUNCEMENT" ? (
        <AnnouncementPurpleIcon />
      ) : item.type === "ADVERTISEMENT" ? (
        <AdsScheduleIcon />
      ) : item.type.includes("CONTENT") ? (
        <ContentScheduleIcon />
      ) : (
        <CommandScheduleIcon />
      )}

      <Box sx={{ ml: 2, width: "250px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              mr: 2,
            }}
            display={"flex"}
            alignItems={"center"}
          >
            {item.isApproved ? (
              <Tooltip title={t("item.approvedTip")} placement="top">
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.2,
                    color: switchBackgroundColorBasedOnStatus("APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t("item.notApprovedTip")} placement="top">
                <ErrorOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.2,
                    color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            )}
            <Box>
              <TypographyNormal
                sx={{
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {item.type !== "INSTANT" ? item.name : item.type}
              </TypographyNormal>
            </Box>
          </Box>

          {item.type === "INSTANT" && (
            <>
              <Box sx={{ mx: 0.5 }}> {" - "}</Box>
              <TypographyNormal
                sx={{
                  fontWeight: 600,
                  color: purple[600],
                }}
              >
                {t(`command.${item.command}`, { defaultValue: null }) ??
                  item.command}
              </TypographyNormal>
            </>
          )}
        </Box>

        {item.type.includes("SCHEDULE") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TypographySmall
              sx={{
                fontWeight: 600,
                color: purple[600],
                textTransform: "capitalize",
                mr: 1,
              }}
            >
              {item.command.replace(/_/g, " ").toLowerCase()}
            </TypographySmall>
            <ClockIcon />
            <TypographySmall
              display="flex"
              alignItems="center"
              sx={{ ml: 0.5, fontWeight: 600, textTransform: "uppercase" }}
            >
              {item.frequency === "OFF" ? (
                <>
                  {item.type.includes("CONTENT") ? (
                    <>
                      {t("frequency.LOOP")}
                      <Tooltip
                        arrow
                        disableInteractive
                        placement="top"
                        title={t("item.explainLOOP")}
                      >
                        <InfoOutlinedIcon
                          fontSize="1rem"
                          sx={{ marginLeft: "3px" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    t("frequency.ONCE")
                  )}
                </>
              ) : (
                t(`frequency.${item.frequency}`, {
                  defaultValue: null,
                }) ?? item.frequency
              )}
            </TypographySmall>
          </Box>
        )}
      </Box>

      <TypographyXSmall
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          p: 1,
          fontWeight: 600,
          minWidth: "65px",
          borderRadius: "15px",
          color: switchColorOnScheduleStatus(
            item.isActive ? "ACTIVE" : "PENDING"
          ),
          backgroundColor: switchBackgroundColorBasedOnStatus(
            item.isActive ? "ACTIVE" : "PENDING"
          ),
        }}
      >
        <FiberManualRecordIcon
          sx={{
            color: switchColorOnScheduleStatus(
              item.isActive ? "ACTIVE" : "PENDING"
            ),
            fontSize: 8,
            mr: "4px",
          }}
        />
        {item.isActive ? t("item.activeLabel") : t("item.inactiveLabel")}
      </TypographyXSmall>
    </Box>
  );
};

const GroupItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <GroupBlueIcon />

      <TypographyNormal sx={{ fontWeight: 600, ml: 2 }}>
        {item.name}
      </TypographyNormal>
    </Box>
  );
};
