import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../../store/actionTypes/globalActionType";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import { useTranslation } from "react-i18next";
import { logger } from "../../../helpers/logger";
import { initiateAuthAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { getUserAuth } from "../../../store/actions/authAction";

const ActivateEmailMFA = ({ callback }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("user");

  const [loading, setLoading] = useState(false);

  const handleSuccess = () => {
    dispatch(getUserAuth());
    callback();

    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("account.activateMFASuccess"),
    });

    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
  };

  const enableEmailMfa = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const data = await initiateAXIOS.post(
        initiateAuthAPI + "mfa",
        { mfaType: "EMAIL" },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      if (data.status === 200) {
        handleSuccess();
      }
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: errorMessage });

      handleTimeoutPrompt(dispatch, SET_GLOBAL_ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={enableEmailMfa} disabled={loading}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          t("account.activateEmailMFABtn")
        )}
      </Button>
    </>
  );
};

export default ActivateEmailMFA;
