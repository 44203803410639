import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUserRoleSchema } from "../../schemas/createUserSchema";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import Role from "../../helpers/roles";
import {
  API_LIMIT,
  initiateOrganizationAPI,
  initiateUserGroupAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { createUser } from "../../store/actions/userAction";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import { TypographySmall } from "../customComponent";
import parser from "html-react-parser";
import { sanitizeInput } from "../../helpers/inputProcessing";
import { colorStyling } from "../../helpers/color";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const CreateUser = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const userRole = user?.type;
  const userOrg = user?.organization?.id;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameValidation, setNameValidation] = useState("");
  const [form, setForm] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleVisibilityPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleVisibilityConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setNameValidation("");
    dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: false });
  };

  const dispatch = useDispatch();

  const access_token = sessionStorage.getItem("access_token");

  const resolver = createUserRoleSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolver),
  });

  const [organizations, setOrganizations] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState("");
  const [selectGroup, setSelectGroup] = useState("");

  const handleReset = () => {
    reset({
      name: "",
      password: "",
      confirmPassword: "",
      role: "",
      email: "",
    });
    setSelectOrganization("");
    setSelectGroup("");
  };

  const getOrganizations = async (query) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  const getUserGroups = async (query) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setUserGroups(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (selectOrganization !== "")
      getUserGroups(`&organizationId=${selectOrganization}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrganization]);

  useEffect(() => {
    if (open) {
      if (userRole === Role.Eins) {
        getOrganizations();
      } else {
        getUserGroups(`&organizationId=${userOrg}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleReset();
      handleClickClose();
    }
  };

  const submitUserForm = async (data) => {
    const { name, email, password, role } = data;
    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }

    let payload = {
      name: name.trim(),
      email: email.trim(),
      password,
      type: role,
      organizationId:
        selectOrganization === "" ? +userOrg : +selectOrganization,
      userGroupId: +selectGroup,
    };

    setLoading(true);
    payload["name"] = sanitizeInput(payload.name);
    dispatch(createUser(payload, handleFinishSubmit));
  };

  return (
    <>
      {userRole && (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
        >
          {t("createBtn")}
        </Button>
      )}

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit(submitUserForm)}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.createDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
            />
            <StyledBox sx={{ mt: 1 }}>
              <Tooltip
                title={
                  <TypographySmall sx={{ color: "white" }}>
                    {parser(t("common.userReqHints"))}
                  </TypographySmall>
                }
                placement="top-start"
              >
                <InputLabel>
                  {t("form.usernameLabel")}
                  <InfoOutlinedIcon fontSize="10" />
                </InputLabel>
              </Tooltip>
              <TextField
                margin="dense"
                {...register("name")}
                fullWidth
                required
                placeholder={t("form.usernamePlaceholder")}
                helperText={errors.name?.message}
                error={errors.name ? true : false}
                disabled={loading}
                onChange={(e) => setNameValidation("")}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </StyledBox>

            <StyledBox>
              <InputLabel>{t("form.emailLabel")}</InputLabel>
              <TextField
                margin="dense"
                {...register("email")}
                fullWidth
                required
                type="email"
                placeholder={t("form.emailPlaceholder")}
                helperText={errors.email?.message}
                error={errors.email ? true : false}
                disabled={loading}
              />
            </StyledBox>

            <Box sx={{ display: "flex" }}>
              <StyledBox sx={{ width: "100%" }}>
                <Tooltip
                  title={
                    <TypographySmall sx={{ color: "white" }}>
                      {parser(t("common.passwordReqHints"))}
                    </TypographySmall>
                  }
                  placement="top-start"
                >
                  <InputLabel>
                    {t("form.passwordLabel")}
                    <InfoOutlinedIcon fontSize="10" />
                  </InputLabel>
                </Tooltip>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ mt: 1 }}
                >
                  <OutlinedInput
                    margin="dense"
                    fullWidth
                    {...register("password")}
                    type={form.showPassword ? "text" : "password"}
                    required
                    placeholder={t("form.passwordPlaceholder")}
                    error={errors.password ? true : false}
                    disabled={loading}
                    endAdornment={
                      <InputAdornment position="end" sx={{ mr: 1 }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleVisibilityPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: "red" }}>
                      {errors.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>

              <StyledBox sx={{ width: "100%", ml: 2 }}>
                <InputLabel>{t("form.confirmPasswordLabel")}</InputLabel>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ mt: 1 }}
                >
                  <OutlinedInput
                    margin="dense"
                    fullWidth
                    {...register("confirmPassword")}
                    type={form.showConfirmPassword ? "text" : "password"}
                    required
                    placeholder={t("form.confirmPasswordPlaceholder")}
                    error={errors.confirmPassword ? true : false}
                    disabled={loading}
                    endAdornment={
                      <InputAdornment position="end" sx={{ mr: 1 }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleVisibilityConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.confirmPassword && (
                    <FormHelperText sx={{ color: "red" }}>
                      {t("form.passwordNotMatch")}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Box>

            <StyledBox>
              <InputLabel>{t("form.roleLabel")}</InputLabel>
              <TextField
                margin="dense"
                {...register("role")}
                select
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                helperText={errors.role?.message}
                error={errors.role}
                disabled={loading}
              >
                <option value="">{t("form.selectRole")}</option>
                {userRole === Role.Eins && (
                  <option value="ADMIN">{t("form.selectRoleAdmin")}</option>
                )}
                <option value="BASIC">{t("form.selectRoleBasic")}</option>
              </TextField>
            </StyledBox>

            {userRole === Role.Eins && (
              <StyledBox>
                <InputLabel>{t("form.organizationLabel")}</InputLabel>
                <TextField
                  select
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setSelectOrganization(e.target.value)}
                  value={selectOrganization}
                  disabled={loading}
                >
                  <option value="" disabled>
                    {t("form.selectOrganization")}
                  </option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </StyledBox>
            )}

            <StyledBox>
              <InputLabel>{t("form.userGroupLabel")}</InputLabel>
              <TextField
                select
                margin="dense"
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setSelectGroup(e.target.value)}
                value={selectGroup}
                disabled={loading}
              >
                <option value="">{t("form.selectUserGroup")}</option>
                {userGroups?.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </TextField>
            </StyledBox>
          </DialogContent>

          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setOpen(false);

                reset({
                  name: "",
                  password: "",
                  confirmPassword: "",
                  role: "",
                  organization: "",
                  email: "",
                });
              }}
              disabled={loading}
            >
              {tCommon("cancelBtn")}
            </Button>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateUser;
