import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { CstTypo14W300 } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import Schedule from "../../../models/ScheduleModel";
import RenderViewDetailSchedule from "./RenderViewDetailSchedule";
import {
  groupAPILimit,
  initiateScheduleAPI,
  viewerAPILimit,
} from "../../../api";
import { colorStyling } from "../../../helpers/color";
import { useDispatch } from "react-redux";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../../store/actionTypes/globalActionType";
import { useTranslation } from "react-i18next";

//TODO when working on Group Assign Schedule
const ViewDetailSchedule = ({ schedule, onClose, openDetail }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [scheduleDetail, setScheduleDetail] = useState({});

  const handleClickClose = () => {
    onClose(false);
    setScheduleDetail({});
  };

  const getSchedule = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + schedule.id,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const screenData = await initiateAXIOS.get(
        viewerAPILimit + "&scheduleId=" + schedule.id,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const groupData = await initiateAXIOS.get(
        groupAPILimit + "&scheduleId=" + schedule.id,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = new Schedule({
        ...data,
        screens: screenData.data.items,
        screenGroups: groupData.data.items,
      });
      setScheduleDetail(OOP);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({
        type: SET_GLOBAL_ERROR_MESSAGE,
        payload: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openDetail) {
      getSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetail]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={openDetail}
        fullWidth
        onClose={handleClickClose}
      >
        <DialogContent>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={20}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <CstTypo14W300
                sx={{ color: colorStyling.primary, marginTop: "15px" }}
              >
                {t("loadingDataList")}
              </CstTypo14W300>
            </Box>
          ) : (
            <RenderViewDetailSchedule
              schedule={scheduleDetail}
              onClose={onClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDetailSchedule;
