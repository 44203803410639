import * as yup from "yup";
import i18n from "i18next";
import { parseVariables } from "../helpers/localize";
import { passwordYupValidation } from "../helpers/passwordHelper";

export const resetPasswordSchema = yup.object({
  "Verification Code": yup
    .string()
    .min(6, ({ min }) =>
      parseVariables(i18n.t("validation.minLengthChars"), { num: min })
    )
    .required(),
  "New Password": passwordYupValidation().required(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});
