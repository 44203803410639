import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { TypographyNormal } from "../../customComponent";
import { getUserGroups } from "../../../store/actions/userAction";
import UserGroupItem from "./UserGroupItem";
import { colorStyling } from "../../../helpers/color";
import { filterObjtoString } from "../../../helpers/filterHelper";
import {
  SET_LOADING_USER,
  SET_USER_GROUP_PAGE,
  SET_USER_GROUP_ROWS_PER_PAGE,
} from "../../../store/actionTypes/userActionType";
import { parseVariables } from "../../../helpers/localize";
import Role from "../../../helpers/roles";

const UserGroupList = () => {
  const dispatch = useDispatch();
  const {
    userGroups,
    userGroupFilters,
    loadingUser: loading,
    userGroupPage: page,
    userGroupRowsPerPage: rowsPerPage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("usergroup");
  const { t: tCommon } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const userRole = user?.type;

  const scrollRef = useRef(null);

  const handleLoading = (value) => {
    dispatch({ type: SET_LOADING_USER, payload: value });
  };

  const handleChangePagination = (e, cPage, cRowPerPage) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    dispatch({ type: SET_USER_GROUP_PAGE, payload: cPage });
    fetchData(userGroupFilters, cPage, cRowPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowPerPage = event.target.value;
    dispatch({
      type: SET_USER_GROUP_ROWS_PER_PAGE,
      payload: parseInt(rowPerPage, 10),
    });
    handleChangePagination(null, 0, rowPerPage);
  };

  const fetchData = async (filterObj, cPage, cRowPerPage) => {
    const targetPage = cPage ?? page;
    const targetRowPerPage = cRowPerPage ?? rowsPerPage;

    let isPrefetch = false;
    let limit = targetRowPerPage;
    let offset = targetPage * targetRowPerPage;
    if (offset === 0) {
      limit = limit * 2; //fetch 2 page worth of data
      isPrefetch = false;
    } else {
      offset = (targetPage + 1) * targetRowPerPage;
      isPrefetch = true;
    }
    let filterString = filterObjtoString(filterObj, limit, offset);

    const userGroupsLength = userGroups?.items?.length;
    if (userGroupsLength === 0) handleLoading(true);
    if (
      userGroupsLength !== userGroups.totalItem ||
      userGroupsLength === 0 ||
      offset === 0
    )
      dispatch(getUserGroups(filterString, isPrefetch, handleLoading));
  };

  useEffect(() => {
    fetchData(userGroupFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (page === 0) {
      fetchData(userGroupFilters);
    } else handleChangePagination(null, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupFilters]);

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <Box sx={{ maxHeight: 500 }}>
        {loading || !userRole ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {!userGroups.items.length ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: 3,
                  borderRadius: "6px",
                  overflowY: "auto",
                  maxHeight: 500,
                }}
                ref={scrollRef}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ px: 4 }}>
                        {t("table.headerName")}
                      </TableCell>
                      {userRole === Role.Eins && (
                        <TableCell sx={{ px: 4 }} width={"30%"}>
                          {t("table.headerOrganization")}
                        </TableCell>
                      )}
                      <TableCell sx={{ px: 4 }} align="right">
                        {t("table.headerAction")}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userGroups?.items
                      ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      ?.map((group) => (
                        <UserGroupItem key={group.id} group={group} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>

      {userRole && (!loading || userGroups?.totalItem > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={userGroups.totalItem}
              page={page}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default UserGroupList;
