import axios from "axios";
import { initiateAuthAPI } from "../api";
import { setAuthCookies } from "../store/actions/authAction";

const baseURL = process.env.REACT_APP_ITXCAST_API_URL;
const disruptionURL = process.env.REACT_APP_TD_API_URL;

const instance = axios.create({ baseURL: baseURL });

export default instance;

export const initiateAXIOS = axios.create({
  baseURL: baseURL,
});

export const disruptionAXIOS = axios.create({
  baseURL: disruptionURL,
});

export const bplrtAXIOS = axios.create({
  baseURL: baseURL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const dispatchCustomEvent = (type, detail) => {
  const newEvent = new CustomEvent(type, { detail });
  window.dispatchEvent(newEvent);
};

initiateAXIOS.interceptors.request.use((config) => {
  dispatchCustomEvent("apiRequest", {
    method: config.method,
    url: config.url,
  });
  return config;
});

// Response interceptor for handling token expiration
initiateAXIOS.interceptors.response.use(
  (response) => {
    // api response success event
    dispatchCustomEvent("apiResponseSuccess", { response });
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest.baseURL.includes(baseURL)) {
      return Promise.reject(error);
    }

    const excludedEndpoint = [
      "login",
      "reset-password",
      "auth/email",
      "auth/mfa",
    ];
    const isMatch = excludedEndpoint.some((str) =>
      originalRequest.url.includes(str)
    );

    if (error.response?.status === 401 && !originalRequest._retry && !isMatch) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return initiateAXIOS(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const refresh_token = sessionStorage.getItem("refresh_token");
        const response = await initiateAXIOS.post(
          initiateAuthAPI + "refresh",
          {},
          {
            headers: { authorization: `Bearer ${refresh_token}` },
          }
        );

        const newAccessToken = response.data.token;
        const newRefreshToken = response.data.refreshToken;
        sessionStorage.setItem("access_token", newAccessToken);
        sessionStorage.setItem("refresh_token", newRefreshToken);
        setAuthCookies("access_token", newAccessToken);
        setAuthCookies("refresh_token", newRefreshToken);

        processQueue(null, newAccessToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return initiateAXIOS(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);

        // api response error event
        dispatchCustomEvent("apiResponseError", { refreshError });
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // api response error event
    dispatchCustomEvent("apiResponseError", { error });
    return Promise.reject(error);
  }
);
