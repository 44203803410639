import * as yup from "yup";
import { passwordYupValidation } from "../helpers/passwordHelper";

export const editPasswordUserSchema = yup.object().shape({
  "New Password": passwordYupValidation().nullable().notRequired(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});

export const editPasswordMyAccountSchema = yup.object().shape({
  "New Password": passwordYupValidation().required(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});
