import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import {
  SET_DELETE_GROUP,
  SET_DETAIL_GROUP,
  SET_ERROR_MESSAGE_GROUP_DIALOG,
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_DIALOG,
  SET_ERROR_PROMPT_GROUP_PAGE,
  SET_GROUP,
  SET_GROUP_SCHEDULE,
  SET_GROUP_VIEWER,
  SET_LOADING_GROUP,
  SET_NEW_GROUP,
  SET_PREFETCH_GROUP,
  SET_SUCCESS_MESSAGE_GROUP_PAGE,
  SET_SUCCESS_PROMPT_GROUP_PAGE,
} from "../actionTypes/groupActionType";
import {
  groupAPILimit,
  initiateGroupAPI,
  scheduleAPILimit,
  viewerAPILimit,
} from "../../api";
import Group from "../../models/GroupModel";
import Schedule from "../../models/ScheduleModel";
import Viewer from "../../models/ViewerModel";
import { logger } from "../../helpers/logger";
import { handleTimeoutPrompt } from "../handlePrompt";

import i18n from "i18next";
const t = i18n.getFixedT(null, "group");

export const fetchGroupById = async (id, access_token) => {
  const { data } = await initiateAXIOS.get(initiateGroupAPI + id, {
    headers: { authorization: `Bearer ${access_token}` },
  });

  const { schedules, screens } = await fetchScreenAndScheduleData(
    id,
    access_token
  );

  return { ...data, schedules, screens };
};

const fetchScreenAndScheduleData = async (id, access_token) => {
  const screenData = await initiateAXIOS.get(
    viewerAPILimit + "&screenGroupId=" + id,
    {
      headers: { authorization: `Bearer ${access_token}` },
    }
  );

  const scheduleData = await initiateAXIOS.get(
    scheduleAPILimit + "&screenGroupId=" + id,
    {
      headers: { authorization: `Bearer ${access_token}` },
    }
  );

  let screens = [];
  // Check if screens exist
  if (screenData.data.items)
    screenData.data.items.map((item) => screens.push(new Viewer(item)));

  let schedules = [];
  // Check if screen groups exist
  if (scheduleData.data.items) {
    const initSchedules = scheduleData.data.items;
    const filteredSchedules = initSchedules.filter((schedule) => {
      return (
        schedule.type !== "INSTANT" || schedule.command !== "REFRESH_DISPLAY"
      );
    });
    filteredSchedules.map((item) => schedules.push(new Schedule(item)));
  }
  return { schedules, screens };
};
const fetchScreenAndScheduleDataNoReturn = async (id, dispatch) => {
  const access_token = sessionStorage.getItem("access_token");
  const screenData = await initiateAXIOS.get(
    viewerAPILimit + "&screenGroupId=" + id,
    {
      headers: { authorization: `Bearer ${access_token}` },
    }
  );

  const scheduleData = await initiateAXIOS.get(
    scheduleAPILimit + "&screenGroupId=" + id,
    {
      headers: { authorization: `Bearer ${access_token}` },
    }
  );

  let screens = [];
  // Check if screens exist
  if (screenData.data.items)
    screenData.data.items.map((item) => screens.push(new Viewer(item)));

  let schedules = [];
  // Check if screen groups exist
  if (scheduleData.data.items) {
    const initSchedules = scheduleData.data.items;
    const filteredSchedules = initSchedules.filter((schedule) => {
      return (
        schedule.type !== "INSTANT" || schedule.command !== "REFRESH_DISPLAY"
      );
    });
    filteredSchedules.map((item) => schedules.push(new Schedule(item)));
  }

  dispatch({ type: SET_GROUP_VIEWER, payload: { id, screens } });
  dispatch({
    type: SET_GROUP_SCHEDULE,
    payload: { id, schedules },
  });
};

export const getGroups = (query, isPrefetch, loading, fromHome = false) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.get(initiateGroupAPI + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let groups = [];
      data.items.map((group) => groups.push(new Group(group)));

      dispatch({
        type: isPrefetch ? SET_PREFETCH_GROUP : SET_GROUP,
        payload: { totalItem: data.totalItem, items: groups },
      });

      if (!fromHome) {
        for (let i = 0; i < data.items.length; i++) {
          const groupId = data.items[i].id;
          fetchScreenAndScheduleDataNoReturn(groupId, dispatch);
        }
      }
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (loading) loading(false);
    }
  };
};

export const createGroup = (payload, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.post(initiateGroupAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const group = new Group({ ...data, screensCount: 0, schedulesCount: 0 });
      dispatch({ type: SET_NEW_GROUP, payload: group });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.createMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
      callback("success");
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_DIALOG, payload: message });
      callback("error");

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const searchGroup = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(groupAPILimit + `${query}`, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const listGroup = data.items;

      let groups = [];
      listGroup.map((item) => groups.push(item));
      dispatch({ type: SET_GROUP, payload: groups });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const deleteGroup = (payload, navigateToGroup) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.delete(initiateGroupAPI + payload.id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.deleteMsg"),
      });

      dispatch({ type: SET_DELETE_GROUP, payload: payload.id });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
      navigateToGroup();
    }
  };
};

export const editGroup = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateGroupAPI + payload.id,
        payload.data,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const group = new Group({ ...payload.group, ...data });

      dispatch({ type: SET_DETAIL_GROUP, payload: group });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.updateMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const getGroup = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateGroupAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const { schedules, screens } = await fetchScreenAndScheduleData(
        id,
        access_token
      );

      const group = new Group({ ...data, schedules, screens });

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: group,
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const createImmediateJobForGroup = ({ id, schedule }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.post(initiateGroupAPI + id + "/immediate", schedule, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.immediateJobCreateMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const assignGroupToSchedule = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/schedule",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.assignScheduleMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeScheduleFromGroup = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/schedule",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });

      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.removeScheduleMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const assignGroupToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/screen",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.assignViewerMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeViewerFromGroup = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateGroupAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_DETAIL_GROUP,
        payload: new Group(await fetchGroupById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.removeViewerMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};

export const removeSubgroupFromGroup = ({ id, deleteChildren }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateGroupAPI + id + "/children",
        { deleteChildren: deleteChildren },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = data;

      dispatch({ type: SET_DETAIL_GROUP, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
        payload: t("api.removeSubgroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_GROUP_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_GROUP_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_GROUP, payload: false });
    }
  };
};
