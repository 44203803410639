import dayjs from "dayjs";

export const dateFormatMMDDYYYYhmmss = (date) => {
  const format = dayjs(new Date(date)).format("DD MMMM YYYY, h:mm A");
  return format;
};

export const dateFormatISO = (date) => {
  const format = dayjs(new Date(date)).format("YYYY-MM-DDTHH:mm:ss");
  return format;
};

export const dateFormat = (date) => {
  const format = dayjs(new Date(date)).format("DD MMM h:mm A");
  return format;
};

export const dateFormatDDMMYYYY = (date) => {
  const format = dayjs(new Date(date)).format("DD MMM YYYY hh:mm A");
  return format;
};

export const dateFormatYYYYMMDD = (date) => {
  const format = dayjs(new Date(date)).format("YYYYMMDD");
  return format;
};

export const formattedDate = (date) => {
  const format = dayjs(new Date(date)).format("DD/MM/YYYY hh:mm A");
  return format;
};

export const formattedDateDash = (date) => {
  const format = dayjs(new Date(date)).format("DD-MM-YYYY_hhmmA");
  return format;
};

export const dateOnlyFormatDDMMYYYY = (date) => {
  const format = dayjs(new Date(date)).format("DD/MM/YYYY");
  return format;
};

export const compareDateLessThan = (date1, date2, compareTime) => {
  let dateFormat1 = new Date(date1);
  let dateFormat2 = new Date(date2);
  dateFormat1.setHours(0, 0, 0, 0);
  dateFormat2.setHours(0, 0, 0, 0);

  const msBetweenDates = Math.abs(
    dateFormat1.getTime() - dateFormat2.getTime()
  );

  const hoursBetweenDates = msBetweenDates / compareTime;

  if (hoursBetweenDates < 1) {
    return false;
  } else {
    return true;
  }
};
