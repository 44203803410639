import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { CustomTextField, TypographySmall } from "../../customComponent";
import {
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_PROMPT_USER_DIALOG,
} from "../../../store/actionTypes/userActionType";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import { initiateAXIOS } from "../../../config/axios";
import { initiateAuthAPI } from "../../../api";
import { logger } from "../../../helpers/logger";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../../store/actionTypes/globalActionType";
import { QRCodeSVG } from "qrcode.react";
import { colorStyling } from "../../../helpers/color";
import parser from "html-react-parser";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { getUserAuth } from "../../../store/actions/authAction";

const ActivateTOTPMFA = ({ callback }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [uri, setUri] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("");

  const handleOpenDialog = () => {
    setOpen(true);
    getTotpQrUri();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOtp("");
    setToken("");
    setUri("");
  };

  const getTotpQrUri = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingQr(true);
    try {
      const { data } = await initiateAXIOS.post(
        initiateAuthAPI + "otp/totp",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setToken(data.otpToken);
      setUri(data.uri);
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
    } finally {
      setLoadingQr(false);
    }
  };

  const handleSuccess = () => {
    dispatch(getUserAuth());
    handleCloseDialog();
    callback();

    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("account.activateMFASuccess"),
    });

    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
  };

  const handleSubmitOtp = async () => {
    setEmptyMessage("");

    const otpTrim = otp.trim();
    if (otpTrim.length === 0 || otp.length !== 6) {
      setEmptyMessage(t("common.otpInputEmpty"));
      setOtp(otpTrim);
      return;
    }

    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const payload = { otp: otp, otpToken: token, mfaType: "TOTP" };
      const data = await initiateAXIOS.post(initiateAuthAPI + "mfa", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      if (data.status === 200) {
        handleSuccess();
        handleCloseDialog();
      }
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: errorMessage });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpenDialog}>
        {t("account.activateTOTPMFABtn")}
      </Button>

      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("account.activateTOTPMFADialogTitle")}
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DispatchErrorPromptAlert
            isError={isError}
            errorMessage={errorMessage}
            closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
          />

          <Alert icon={false} severity="success" sx={{ mb: 2 }}>
            {parser(t("account.otpActivateTotpHint"))}
          </Alert>
          <Box
            sx={{ width: "100%", mt: 1, mb: 2 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box>
              {loadingQr ? (
                <CircularProgress size={100} />
              ) : (
                <Box display={"flex"} justifyContent={"center"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{
                      border: "1px solid black",
                      p: 1,
                      borderRadius: 2,
                      backgroundColor: colorStyling.primary,
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        p: 1,
                        backgroundColor: colorStyling.white.full,
                      }}
                    >
                      <QRCodeSVG value={uri} size="190" />
                    </Box>
                  </Box>
                </Box>
              )}

              <InputLabel sx={{ mt: 2 }}>
                {t("account.enterOtpLabel")}
              </InputLabel>
              <CustomTextField
                sx={{ my: 1 }}
                autoFocus
                placeholder={t("account.enterOtpPlaceholder")}
                fullWidth
                margin="normal"
                disabled={loading}
                value={otp}
                required
                autoComplete="off"
                onChange={(e) => {
                  setOtp(e.target.value);
                  setEmptyMessage("");
                }}
                inputProps={{ maxLength: 6 }}
              />

              {emptyMessage.length > 0 && (
                <Box
                  sx={{
                    pl: 1,
                    pb: 1,
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <TypographySmall sx={{ color: colorStyling.red }}>
                    {emptyMessage}
                  </TypographySmall>
                </Box>
              )}
            </Box>
          </Box>
          <Alert severity="info">
            {parser(t("account.otpDownloadTotpAuthAppHint"))}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            variant="contained"
            fullWidth
            type="submit"
            onClick={handleSubmitOtp}
          >
            {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivateTOTPMFA;
