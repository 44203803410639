import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { initiateAXIOS } from "../../../../config/axios";
import { instanceCMSContentAPI } from "../../../../api";
import {
  Typography2XLarge,
  TypographyLarge,
  TypographySmall,
} from "../../../customComponent";
import ContentViewer from "./ContentViewer";
import LayerItem from "./LayerItem";
import { colorStyling } from "../../../../helpers/color";
import { logger } from "../../../../helpers/logger";
import { ResolutionType } from "../../../../helpers/resolution";
import ContentPreview from "./ContentPreview";
import { updateDetailsArray } from "../../../../store/actions/cmsAction";
import { SET_ADS_PLACEHOLDER_FLAG } from "../../../../store/actionTypes/cmsActionType";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const ContentDetails = ({ open, handleClose, contentID }) => {
  const dispatch = useDispatch();
  const { cmsDetails } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const permission = user?.permission;

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const [layers, setLayers] = useState([]);
  const [canvasRes, setCanvasRes] = useState({ width: 0, height: 0 });
  const [ratiodRes, setRatiodRes] = useState({ width: 0, height: 0 });

  const [openContentViewer, setOpenContentViewer] = useState(false);

  const setupCanvas = (data) => {
    let resLabel = `${data.width} x ${data.height}`;
    let assignedRes =
      ResolutionType["P"][resLabel] ?? ResolutionType["L"][resLabel];
    if (!assignedRes) {
      resLabel = "custom0";
      assignedRes = ResolutionType["P"][resLabel];
    }

    let potraitFlag = false;
    const updatedCanvasRes = { width: 0, height: 0 };
    if (data.width < data.height) {
      potraitFlag = true;
    }

    let ratiodWidth;
    let ratiodHeight;
    if (!assignedRes?.free) {
      updatedCanvasRes["width"] = potraitFlag ? 360 : 640;
      updatedCanvasRes["height"] = potraitFlag ? 640 : 360;
      ratiodWidth = data.width / updatedCanvasRes.width;
      ratiodHeight = data.height / updatedCanvasRes.height;
    } else {
      updatedCanvasRes["width"] = data.width;
      updatedCanvasRes["height"] = data.height;
      ratiodWidth = 1;
      ratiodHeight = 1;
    }

    setRatiodRes({ width: ratiodWidth, height: ratiodHeight });
    setCanvasRes(updatedCanvasRes);
  };

  const getContent = async () => {
    if (cmsDetails[contentID]) {
      const data = cmsDetails[contentID];
      setContent(data);
      setupCanvas(data);
      if (data.layers?.length > 0) {
        setLayers(data.layers);
      }
      setLoading(false);
    }

    const access_token = sessionStorage.getItem("access_token");
    const getUrl = `${instanceCMSContentAPI}${contentID}`;
    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setContent(data);
      setupCanvas(data);
      setLayers(data.layers);
      dispatch(updateDetailsArray({ cache: cmsDetails, data }));
      setLoading(false);
    } catch (error) {
      logger.error(`getContent-ERR:`, error);
    }
  };

  const resetData = () => {
    const resetRes = { width: 0, height: 0 };
    setCanvasRes(resetRes);
    setRatiodRes(resetRes);
    setContent({});
    setLayers([]);
  };

  useEffect(() => {
    resetData();
    setLoading(true);
    if (contentID > 0 && open) {
      getContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseDetails = () => {
    handleClose();
    dispatch({ type: SET_ADS_PLACEHOLDER_FLAG, payload: { reset: true } });
  };

  const handleOpenContentViewer = () => {
    setOpenContentViewer(true);
  };

  const handleCloseContentViewer = () => {
    setOpenContentViewer(false);
  };

  const previewResLabel = useMemo(() => {
    if (content.width > 0 && content.height > 0) {
      return (
        <InputLabel>
          ({content.width} x {content.height})
        </InputLabel>
      );
    }

    return;
  }, [content]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleCloseDetails}>
      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"}>
            <Typography2XLarge>
              {loading ? t("detail.loadingTitle") : content.name}
            </Typography2XLarge>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {!loading && content.tags?.length > 0 && (
              <Box
                display={"flex"}
                gap={1}
                sx={{ mt: 1, ml: 2, flexWrap: "wrap" }}
              >
                {content.tags.map((item, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    bgcolor={"mediumseagreen"}
                    paddingY={"4px"}
                    justifyContent={"center"}
                    sx={{
                      top: 0,
                      left: 0,
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      display={"flex"}
                      sx={{ mr: 1, ml: 1, width: "max-content" }}
                    >
                      <TypographySmall
                        fontWeight={"medium"}
                        sx={{ color: "white" }}
                      >
                        {item}
                      </TypographySmall>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <IconButton onClick={handleCloseDetails}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          minHeight: "60vh",
          backgroundColor: colorStyling.cms.white,
          px: 5,
          py: 0,
        }}
      >
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ py: 2 }}
          >
            <Box alignSelf={"start"} flex={1}>
              <StyledBox>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {t("detail.layerSection")}
                </TypographyLarge>

                {!loading && layers?.length > 0 && (
                  <Box
                    sx={{
                      mt: 2,
                      width: "500px",
                    }}
                  >
                    {layers.map((layer, index) => {
                      return (
                        <LayerItem
                          key={layer.id}
                          index={index + 1}
                          layer={layer}
                        />
                      );
                    })}
                  </Box>
                )}
              </StyledBox>

              {!loading && (
                <Box sx={{ display: "flex", position: "relative" }}>
                  {permission?.screen?.read && (
                    <Box
                      textAlign={"center"}
                      sx={{
                        width: "100%",
                        pt: 2,
                        px: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<InfoIcon />}
                        sx={{ boxShadow: 3 }}
                        onClick={() => handleOpenContentViewer()}
                      >
                        {t("detail.showUsageBtn")}
                      </Button>
                    </Box>
                  )}

                  {/* TODO : Update order/duration directly (may needed) */}
                  {/* <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      pt: 2,
                      px: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<InfoIcon />}
                      sx={{ boxShadow: 3 }}
                      onClick={() => handleOpenContentViewer()}
                    >
                      Update
                    </Button>
                  </Box> */}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                top: "55px",
                width: "380px",
                height: "auto",
                position: "sticky",
                alignSelf: "flex-start",
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <InputLabel>{t("detail.previewLabel")}</InputLabel>
                {previewResLabel}
              </Box>

              <ContentPreview
                open={open}
                layers={layers}
                loading={loading}
                content={content}
                canvasRes={canvasRes}
                ratiodRes={ratiodRes}
              />
            </Box>
          </Box>

          {loading && (
            <Box
              sx={{ height: "40vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={20}
                thickness={4}
                sx={{ color: colorStyling.primary }}
              />
              <TypographyLarge
                sx={{ color: colorStyling.primary, mt: 2, fontWeight: 400 }}
              >
                {tCommon("loading")}
              </TypographyLarge>
            </Box>
          )}

          <ContentViewer
            open={openContentViewer}
            handleClose={handleCloseContentViewer}
            content={content}
          />
        </>
      </DialogContent>

      <DialogActions sx={{ mx: 2, pb: 1 }}>
        <Button variant="outlined" onClick={handleCloseDetails}>
          {tCommon("closeBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentDetails;
