const APP_ENV = process.env.REACT_APP_ENV;

export const getGlobalKey = () => {
  let key = "global_feature_flags";
  switch (APP_ENV) {
    case "dev":
    case "staging":
      key += "_dev";
      break;

    default:
    case "prod":
      break;
  }

  return key;
};

export const coreFeatureFlag = {
  ASSET: true,
  CONTENT: true,
  SCREEN: true,
  SCREEN_GROUP: true,
  USER: true,
  USER_GROUP: true,
  SCHEDULE: true,
  ADVERTISEMENT: true,
  "SCHEDULE.COMMAND": false,
  "SCHEDULE.CONTENT": true,
  "SCHEDULE.CALENDAR": true,
  ANNOUNCEMENT: true,
  "LOG.AUDIT": true,
  "LOG.CONTENT": true,
  "SMRT.HIVESTACK": true,
  "SMRT.VISTAR": true,
  "BPLRT.EQUIPMENT": true,
  "BPLRT.SMP": true,
};
