import React from "react";
import { Navigate } from "react-router-dom";
import Role from "../helpers/roles";

export const AuthorizationRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");

  return access_token ? children : <Navigate to="/login" />;
};

export const AuthorizationLandingRoute = ({ children, user }) => {
  const access_token = sessionStorage.getItem("access_token");

  if (access_token && user) {
    if (user.type === Role.Eins) return <Navigate to="/users" />;
    else return <Navigate to="/dashboard" />;
  } else return access_token ? children : <Navigate to="/login" />;
};

export const AuthorizationUsersRoute = ({ children, user }) => {
  const access_token = sessionStorage.getItem("access_token");

  if (access_token) {
    if (user.type === Role.User) return <Navigate to="/dashboard" />;
    else return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export const AuthorizationRouteRequestToken = ({ children }) => {
  const requestToken = sessionStorage.getItem("requestToken");

  return requestToken ? children : <Navigate to="/login" />;
};

export const AuthorizationLoginRoute = ({ children }) => {
  const requestToken = sessionStorage.getItem("requestToken");

  return requestToken ? <Navigate to="/authorization/request-otp" /> : children;
};

export const AuthorizationForgotPasswordRoute = ({ children }) => {
  const resetPasswordToken = sessionStorage.getItem("resetPasswordToken");

  return resetPasswordToken ? children : <Navigate to="/login" />;
};

export const AuthorizationSmrtTdRoute = ({ children, user }) => {
  const access_token = sessionStorage.getItem("access_token");
  const showTD = process.env.REACT_APP_SHOW_TD;

  if (access_token) {
    if (user.type === Role.Admin && showTD === "TRUE") return children;
    else return <Navigate to="/not-found" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export const AuthorizationBplrtSmpRoute = ({ children, user }) => {
  const access_token = sessionStorage.getItem("access_token");
  const showBPLRT = process.env.REACT_APP_SHOW_BPLRT;
  if (access_token) {
    // TODO : SMP Access Flag
    // const canAccessSMP = decryptUser(
    //   localStorage.getItem("permissionFlag")
    // ).canAccessSMP;
    const canAccessSMP = true; // to keep the condition by bypassing it

    if (
      (user.type === Role.Admin || canAccessSMP) &&
      showBPLRT === "TRUE"
    )
      return children;
    else return <Navigate to="/not-found" />;
  } else {
    return <Navigate to="/login" />;
  }
};
