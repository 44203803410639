import { logger } from "./logger";

export const cloneArray = (arr) => {
  try {
    return JSON.parse(JSON.stringify(arr));
  } catch (e) {
    logger.error(e);
    return arr;
  }
};

export const arrayRangeCustom = (start, stop, step) => {
  const result = [];
  for (let i = start; i <= stop; i += step) {
    result.push(i);
  }
  return result;
};
