import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { parseVariables } from "../../../../helpers/localize";
import dayjs from "dayjs";
import { handleTimeoutPrompt } from "../../../../store/handlePrompt";
import {
  SET_ERROR_MESSAGE_SCHEDULE_DIALOG,
  SET_ERROR_PROMPT_SCHEDULE_DIALOG,
} from "../../../../store/actionTypes/scheduleActionType";
import {
  convertDurationtoMs,
  convertMstoDuration,
  durationValidation,
  generateDurationOptions,
  slotTimeValidation,
} from "../../../../helpers/scheduleHelper";
import {
  getTimeDifferenceInMilliseconds,
  getTimeFromCron,
} from "../../../../helpers/cronHelper";
import { initiateAXIOS } from "../../../../config/axios";
import { contentCMSAPILimit } from "../../../../api";
import { logger } from "../../../../helpers/logger";
import DispatchErrorPromptAlert from "../../../../components/DispatchErrorPromptAlert";
import {
  CustomTextField,
  TypographySmall,
} from "../../../../components/customComponent";
import { editSchedule } from "../../../../store/actions/scheduleAction";
// TODO play percentage
// import { arrayRangeCustom } from "../../../../helpers/arrayHelper";

const EditAdvertisementSchedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptScheduleDialog: isError,
    errorMessageScheduleDialog: errorMessage,
  } = useSelector((state) => state.schedule);

  const { t } = useTranslation("advertschedule");
  const { t: tSchedule } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState("");

  const [loadingContentList, setLoadingContentList] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [selectContent, setSelectContent] = useState("");

  const [name, setName] = useState(schedule.name);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkboxEndDate, setCheckboxEndDate] = useState(false);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [duration, setDuration] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [selectPriority, setSelectPriority] = useState(2);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    handleResetForm();
    setOpen(false);
  };

  const handleResetForm = () => {
    setName("");
    setSelectContent("");
    setStartDate("");
    setEndDate("");
    setCheckboxEndDate(false);
    setStartTime("00:00");
    setEndTime("00:00");
    setDuration({
      hours: "00",
      minutes: "00",
      seconds: "00",
    });
    setSelectPriority(2);
    setValidation("");
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setName(input);
      if (validation) setValidation("");
    }
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setEndDate(dayjs(e.target.value).add(1, "d").format("YYYY-MM-DD"));
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleCheckboxEndDate = (checked) => {
    if (checked) {
      setEndDate(dayjs(startDate).add(1, "d").format("YYYY-MM-DD"));
    } else {
      setEndDate("");
    }
    setCheckboxEndDate(checked);
  };

  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTime = (e) => {
    setEndTime(e.target.value);
  };

  const handleChangeDuration = (e, part) => {
    let input = e.target.value;
    setDuration({ ...duration, [part]: input });
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      handleOpenErr(t("validation.nameEmpty"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      return;
    }
    if (name.trim().length > 40) {
      handleOpenErr(parseVariables(t("validation.nameMaxLength"), { max: 40 }));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      return;
    }

    if (startTime === endTime) {
      handleOpenErr(t("validation.timeCannotBeSame"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      return;
    }

    if (!durationValidation(duration)) {
      handleOpenErr(t("validation.durationEmpty"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      return;
    }

    let payload;
    payload = {
      id: schedule.id,
      data: {},
      schedule,
    };

    payload["data"]["_type"] = "ADVERTISEMENT";

    if (name.trim() !== schedule.name) {
      payload["data"]["name"] = name.trim();
    }

    const startAtData = dayjs(startDate)?.toISOString();
    if (startAtData !== schedule.startAt) {
      payload["data"]["startAt"] = startAtData;
    }

    if (!checkboxEndDate && schedule.endAt) {
      payload["data"]["endAt"] = null;
    } else if (endDate) {
      const endAtData = dayjs(endDate)
        .add(23, "hour")
        .add(59, "minute")
        .toISOString();
      if (checkboxEndDate && endAtData !== schedule.endAt) {
        payload["data"]["endAt"] = endAtData;
      }
    }

    const [startTimeHour, startTimeMinute] = startTime.split(":").map(Number);
    const cronData = `${+startTimeMinute} ${+startTimeHour} * * *`;
    if (cronData !== schedule.cron) {
      payload["data"]["cron"] = cronData;
      payload["data"]["cronTimeZone"] = dayjs.tz.guess();
    }

    const durationData = getTimeDifferenceInMilliseconds(startTime, endTime);
    if (durationData !== schedule.duration)
      payload["data"]["duration"] = durationData;

    const advertDurationData = convertDurationtoMs(duration);
    if (advertDurationData !== schedule.advertDuration)
      payload["data"]["advertDuration"] = advertDurationData;

    if (+selectContent !== +schedule.contentId)
      payload["data"]["contentId"] = +selectContent;

    if (+selectPriority !== +schedule.priority.value) {
      payload["data"]["priority"] = +selectPriority;
    }

    setLoading(true);
    dispatch(editSchedule(payload, handleFinishSubmit));
  };

  const handleOpenErr = (message) => {
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setContentList(data.items);
    } catch (error) {
      logger.error(error);

      handleOpenErr(t("validation.listContentsError"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
    } finally {
      setLoadingContentList(false);
    }
  };

  const setScheduleToForm = () => {
    setName(schedule.name);
    setSelectContent(schedule.contentId);
    setStartDate(dayjs(schedule.startAt).format("YYYY-MM-DD"));
    setEndDate(
      schedule.endAt ? dayjs(schedule.endAt).format("YYYY-MM-DD") : ""
    );
    setCheckboxEndDate(schedule.endAt ? true : false);
    setStartTime(getTimeFromCron(schedule.cron, schedule.cronTimeZone));
    setEndTime(
      getTimeFromCron(schedule.cron, schedule.cronTimeZone, schedule.duration)
    );
    setDuration(convertMstoDuration(schedule.advertDuration));
    setSelectPriority(schedule.priority.value);
    // TODO play percentage
    // setSelectPriority(
    //   schedule.priority.value < 25 ? 100 : schedule.priority.value
    // );
    setValidation("");
  };

  useEffect(() => {
    if (open) {
      setScheduleToForm();
      getCMSContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        title={
          schedule.isApproved ? (
            <TypographySmall sx={{ color: "white" }}>
              {tSchedule("item.editDisabledTip")}
            </TypographySmall>
          ) : (
            ""
          )
        }
        placement="top"
      >
        <Box>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={<EditIcon fontSize="small" />}
            sx={{ boxShadow: 3 }}
            disabled={schedule.isApproved ? true : false}
            {...(!schedule.isApproved && { onClick: () => setOpen(true) })}
          >
            {t("editBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.editDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_SCHEDULE_DIALOG}
            />

            {/* NAME */}
            <Box sx={{ my: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                size="small"
                margin="dense"
                inputProps={{
                  maxLength: 40,
                }}
                fullWidth
                variant="outlined"
                required
                placeholder={t("form.nameLabel")}
                value={name}
                onChange={(e) => handleInputName(e.target.value)}
                disabled={loading}
              />
            </Box>

            {/* DATE */}
            <Box sx={{ display: "flex", alignItems: "end", mb: 1 }}>
              <Box sx={{ width: "100%", mb: 0 }}>
                <InputLabel sx={{ mr: 0.5 }}>
                  {t("form.startDateLabel")}
                </InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  value={startDate}
                  onChange={handleStartDate}
                  required
                  margin="dense"
                  type="date"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: dayjs().format("YYYY-MM-DDT00:00"),
                    },
                  }}
                  disabled={loading}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  ml: 2,
                }}
              >
                <Box sx={{ pb: checkboxEndDate ? 0 : 0.5 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    value={checkboxEndDate}
                    checked={checkboxEndDate}
                    name={"showEndDate"}
                    onChange={(e) => {
                      handleCheckboxEndDate(e.target.checked);
                    }}
                    label={t("form.endDateCheckboxLabel")}
                    disabled={loading}
                  />
                </Box>

                {checkboxEndDate && (
                  <Box sx={{ width: "100%" }}>
                    <InputLabel>{t("form.endDateLabel")}</InputLabel>

                    <TextField
                      size="small"
                      fullWidth
                      value={endDate}
                      onChange={handleEndDate}
                      margin="dense"
                      type="date"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: startDate,
                        },
                      }}
                      disabled={loading}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box display={"flex"} sx={{ mb: 1 }}>
              {/* TIME */}
              <Box sx={{ width: "100%", mr: 0.5 }}>
                <InputLabel>{t("form.timeLabel")}</InputLabel>
                <Box display={"flex"} alignItems="center">
                  <TextField
                    size="small"
                    margin="dense"
                    type="time"
                    variant="outlined"
                    disabled={loading}
                    label={t("form.timeStartLabel")}
                    value={startTime}
                    onChange={handleStartTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mx={1}>{"-"}</Box>
                  <TextField
                    size="small"
                    margin="dense"
                    type="time"
                    variant="outlined"
                    disabled={loading}
                    label={t("form.timeEndLabel")}
                    value={endTime}
                    onChange={handleEndTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <TypographySmall>
                  {slotTimeValidation(startTime, endTime)}
                </TypographySmall>
              </Box>

              {/* SLOT DURATION */}
              <Box sx={{ width: "100%", ml: 0.5 }}>
                <InputLabel>{t("form.slotLabel")}</InputLabel>

                <Box display="flex" alignItems="center">
                  <FormControl variant="outlined" margin="dense">
                    <InputLabel id="hours-label">
                      {t("form.slotHourLabel")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="hours-label"
                      sx={{ width: 80 }}
                      value={duration.hours}
                      onChange={(e) => handleChangeDuration(e, "hours")}
                      label={t("form.slotHourLabel")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 100,
                            overflowY: "scroll",
                          },
                        },
                      }}
                      disabled={loading}
                    >
                      {generateDurationOptions(0, 23).map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box mx={1}>:</Box>

                  <FormControl variant="outlined" margin="dense">
                    <InputLabel id="minutes-label">
                      {t("form.slotMinuteLabel")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="minutes-label"
                      sx={{ width: 80 }}
                      value={duration.minutes}
                      onChange={(e) => handleChangeDuration(e, "minutes")}
                      label={t("form.slotMinuteLabel")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 100,
                            overflowY: "scroll",
                          },
                        },
                      }}
                      disabled={loading}
                    >
                      {generateDurationOptions(0, 59).map((minute) => (
                        <MenuItem key={minute} value={minute}>
                          {minute}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box mx={1}>:</Box>

                  <FormControl variant="outlined" margin="dense">
                    <InputLabel id="seconds-label">
                      {t("form.slotSecondLabel")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="seconds-label"
                      sx={{ width: 80 }}
                      value={duration.seconds}
                      onChange={(e) => handleChangeDuration(e, "seconds")}
                      label={t("form.slotSecondLabel")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 100,
                            overflowY: "scroll",
                          },
                        },
                      }}
                      disabled={loading}
                    >
                      {generateDurationOptions(0, 59).map((second) => (
                        <MenuItem key={second} value={second}>
                          {second}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TypographySmall>
                  {durationValidation(duration)
                    ? ""
                    : t("validation.slotRequired")}
                </TypographySmall>
              </Box>
            </Box>

            {/* CONTENT */}
            <Box sx={{ mb: 1 }}>
              <InputLabel>{t("form.contentLabel")}</InputLabel>

              {loadingContentList && contentList.length === 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ width: "100%", height: 20 }}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <CustomTextField
                  size="small"
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="contentSelect"
                  value={selectContent}
                  onChange={(e) => {
                    setSelectContent(e.target.value);
                  }}
                  disabled={loading}
                >
                  <option value="" disabled>
                    {t("form.contentDefaultSelect")}
                  </option>
                  {contentList.map((content) => (
                    <option value={content.id} key={content.id}>
                      {content.name}
                    </option>
                  ))}
                </CustomTextField>
              )}
            </Box>

            {/* PRIORITY */}
            <Box>
              <InputLabel>{t("form.priorityLabel")}</InputLabel>
              <CustomTextField
                size="small"
                select
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                name="contentSelect"
                value={selectPriority}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
                disabled={loading}
              >
                <option value="0">{t("priority.Highest")}</option>
                <option value="1">{t("priority.High")}</option>
                <option value="2">{t("priority.Normal")}</option>
                <option value="3">{t("priority.Low")}</option>
                <option value="4">{t("priority.Lowest")}</option>
              </CustomTextField>
            </Box>

            {/* TODO play percentage */}
            {/* PLAYING PERCENTAGE */}
            {/* <Box>
              <InputLabel>{t("form.playFrequencyLabel")}</InputLabel>
              <Select
                size="small"
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                value={selectPriority}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
                disabled={loading}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 150,
                      overflowY: "scroll",
                    },
                  },
                }}
              >
                {arrayRangeCustom(25, 200, 25).map((value, i) => (
                  <MenuItem key={i} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box> */}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              {tCommon("cancelBtn")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditAdvertisementSchedule;
