import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CssBaseline,
  Box,
  Toolbar,
  Container,
  Grid,
  Divider,
} from "@mui/material";
import {
  Typography2XLarge,
  TypographyLarge,
  TypographyNormal,
} from "../customComponent";
import {
  getActiveSchedules,
  getAllViewers,
  getHomeGroups,
  getLastActiveViewers,
  getPendingActiveSchedules,
} from "../../store/actions/homeAction";
import { GlobalErrorPrompt, GlobalSuccessPrompt, Sidebar } from "..";
import ViewerCard from "./ViewerCard";
import ActiveViewerCard from "./ActiveViewerCard";
import GroupCard from "./GroupCard";
import ScheduleCard from "./ScheduleCard";
import HomeScheduleList from "./HomeScheduleList";
import HomeLastActiveViewerList from "./HomeLastActiveViewerList";
import ActiveAnnouncementCard from "./ActiveAnnouncementCard";
import { SET_HOME_INITIAL_FETCH } from "../../store/actionTypes/homeActionType";
import { getGroups } from "../../store/actions/groupAction";
import { filterObjOnly, filterObjtoString } from "../../helpers/filterHelper";
import { getViewers } from "../../store/actions/viewerAction";
import {
  getAdvertisementSchedules,
  getSchedules,
} from "../../store/actions/scheduleAction";
import { getAnnouncements } from "../../store/actions/announcementAction";
import {
  getUsers,
  getUserGroups,
  getUserHistory,
} from "../../store/actions/userAction";
import { getContentReports } from "../../store/actions/reportingAction";
import { getAllContents } from "../../store/actions/cmsAction";
import { getContentAssets } from "../../store/actions/assetAction";

const Home = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("home");

  const { user, features } = useSelector((state) => state.auth);
  const username = user?.name;

  const {
    initialFetch,
    lastActiveViewersLimit: activeViewersLimit,
    pendingActiveSchedulesLimit: activeSchedulesLimit,
  } = useSelector((state) => state.home);

  const { groupFilters, rowsPerPage: groupLimit } = useSelector(
    (state) => state.group
  );
  const { viewerFilters, rowsPerPage: viewerLimit } = useSelector(
    (state) => state.viewer
  );
  const {
    scheduleFilters,
    advertisementScheduleFilters,
    rowsPerPage: scheduleLimit,
  } = useSelector((state) => state.schedule);
  const { announcementFilters, rowsPerPage: announcementLimit } = useSelector(
    (state) => state.announcement
  );
  const { contentReportFilters } = useSelector((state) => state.reporting);
  const { assetFilter, rowsPerPage: assetLimit } = useSelector(
    (state) => state.asset
  );
  const { cmsFilter, rowsPerPage: cmsLimit } = useSelector(
    (state) => state.cms
  );
  const {
    userFilters,
    userRowsPerPage: userLimit,
    userGroupFilters,
    userGroupRowsPerPage: userGroupLimit,
    userHistoryFilter,
  } = useSelector((state) => state.user);

  const getHomeData = () => {
    dispatch(getAllViewers());
    dispatch(getHomeGroups());
    dispatch(getActiveSchedules(features));
    dispatch(getLastActiveViewers(activeViewersLimit));
    dispatch(getPendingActiveSchedules(activeSchedulesLimit, features));
  };

  const preloadAllData = () => {
    // shortened get filter string func
    const offset = 0;
    const fs = (filter, limit) => {
      return filterObjtoString(filter, limit, offset);
    };
    dispatch(getGroups(fs(groupFilters, groupLimit), false, false, true));
    dispatch(getViewers(fs(viewerFilters, viewerLimit), false, false, true));
    dispatch(
      getSchedules(fs(scheduleFilters, scheduleLimit), false, false, true)
    );

    if (features["ANNOUNCEMENT"])
      dispatch(
        getAnnouncements(
          fs(announcementFilters, announcementLimit),
          false,
          false,
          true
        )
      );

    dispatch(getUsers(fs(userFilters, userLimit)));
    dispatch(getUserGroups(fs(userGroupFilters, userGroupLimit)));

    dispatch(
      getContentAssets({
        payload: { offset, limit: assetLimit, ...assetFilter },
        append: false,
      })
    );
    dispatch(
      getAllContents({
        payload: { offset, limit: cmsLimit, ...cmsFilter },
        append: false,
      })
    );

    if (features["LOG.CONTENT"])
      dispatch(
        getContentReports(
          fs(
            Object.fromEntries(
              Object.entries(contentReportFilters).filter(
                ([key, value]) => value
              )
            ),
            10
          )
        )
      );

    if (features["LOG.AUDIT"])
      dispatch(
        getUserHistory({
          payload: { offset, limit: 100, ...userHistoryFilter },
          append: false,
        })
      );

    if (features["ADVERTISEMENT"])
      dispatch(
        getAdvertisementSchedules(
          scheduleLimit,
          `${filterObjOnly(advertisementScheduleFilters)}`
        )
      );
  };

  useEffect(() => {
    getHomeData();
    if (initialFetch) {
      dispatch({ type: SET_HOME_INITIAL_FETCH });
      setTimeout(preloadAllData(), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <GlobalErrorPrompt />
        <GlobalSuccessPrompt />

        <Container maxWidth="xl">
          <Typography2XLarge>{username}</Typography2XLarge>
          <TypographyLarge sx={{ fontWeight: 300, mb: 1 }}>
            {t("titleExplain")}
          </TypographyLarge>

          <TypographyNormal sx={{ fontWeight: 300, mb: 1 }}>
            {t("overviewLabel")}
          </TypographyNormal>

          <Grid container spacing={2}>
            {features["SCREEN"] && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  <ViewerCard />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <ActiveViewerCard />
                </Grid>
              </>
            )}

            {features["SCREEN_GROUP"] && (
              <Grid item xs={12} sm={6} lg={3}>
                <GroupCard />
              </Grid>
            )}

            {((features["SCHEDULE"] &&
              (features["SCHEDULE.COMMAND"] || features["SCHEDULE.CONTENT"])) ||
              features["ADVERTISEMENT"]) && (
              <Grid item xs={12} sm={6} lg={3}>
                <ScheduleCard />
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {features["ANNOUNCEMENT"] && (
              <Grid item xs={12}>
                <ActiveAnnouncementCard />
              </Grid>
            )}

            {features["SCREEN"] && (
              <Grid item xs={12} lg={6}>
                <HomeLastActiveViewerList />
              </Grid>
            )}

            {((features["SCHEDULE"] &&
              (features["SCHEDULE.COMMAND"] || features["SCHEDULE.CONTENT"])) ||
              features["ADVERTISEMENT"]) && (
              <Grid item xs={12} lg={6}>
                <HomeScheduleList />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
