import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  TextField,
  InputLabel,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { editAnnouncement } from "../../../store/actions/announcementAction";
import { CustomTextField, TypographySmall } from "../../customComponent";
import { initiateAXIOS } from "../../../config/axios";
import { contentCMSAPILimit } from "../../../api";
import { colorStyling } from "../../../helpers/color";
import { isEmpty } from "lodash";
import {
  SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
  SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG,
} from "../../../store/actionTypes/announcementActionType";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { parseVariables } from "../../../helpers/localize";
import { sanitizeInput } from "../../../helpers/inputProcessing";

const EditAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptAnnouncementDialog: isError,
    errorMessageAnnouncementDialog: errorMessage,
  } = useSelector((state) => state.announcement);

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const contentPermission = user?.permission?.content;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickClose = () => {
    handleResetForm();
    setOpen(false);
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: false });
  };

  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [loadingContentList, setLoadingContentList] = useState(false);
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");
  const [selectPriority, setSelectPriority] = useState(2);

  const handleResetForm = () => {
    setName("");
    setNameValidation("");
    setSelectCustomContent("");
    setSelectPriority(2);
  };

  const handleOpenErr = (message) => {
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG, payload: message });
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setName(input);
      if (nameValidation) setNameValidation("");
    }
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }
    if (name.trim().length > 40) {
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
      return;
    }

    let payload = {
      id: announcement.id,
      data: { _type: "ANNOUNCEMENT" },
      announcement,
    };

    let tempAnn = {};

    if (name.trim() !== announcement.name) {
      tempAnn["name"] = sanitizeInput(name.trim());
    }

    if (announcement.contentId !== +selectCustomContent) {
      tempAnn["contentId"] = +selectCustomContent;
    }

    if (announcement.priority.value !== +selectPriority)
      tempAnn["priority"] = selectPriority;

    if (!isEmpty(tempAnn)) {
      payload.data = { ...payload.data, ...tempAnn };

      setLoading(true);
      dispatch(editAnnouncement(payload, handleFinishSubmit));
    } else handleClickClose();
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      setSelectCustomContent(announcement.contentId);

      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      handleOpenErr(t("validation.listContentsError"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG);
    } finally {
      setLoadingContentList(false);
    }
  };

  useEffect(() => {
    if (open) {
      setName(announcement.name);
      setSelectPriority(announcement.priority.value);
      if (contentPermission?.read) {
        getCMSContent();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        placement="top"
        title={
          announcement.isApproved ? (
            <TypographySmall sx={{ color: "white" }}>
              {t("item.editDisabledTip")}
            </TypographySmall>
          ) : (
            ""
          )
        }
      >
        <Box>
          <Button
            variant="contained"
            startIcon={<EditIcon fontSize="small" />}
            sx={{ boxShadow: 3, ml: 2 }}
            disabled={announcement.isApproved ? true : false}
            {...(!announcement.isApproved && { onClick: () => setOpen(true) })}
          >
            {t("item.editBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.editDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG}
            />
            <Box sx={{ mt: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                value={name}
                onChange={(e) => handleInputName(e.target.value)}
                required
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </Box>

            {contentPermission?.read && (
              <>
                {loadingContentList && customContentList.length === 0 ? (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ width: "100%", height: 150 }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <InputLabel>{t("form.contentLabel")}</InputLabel>
                    <CustomTextField
                      select
                      required
                      SelectProps={{ native: true }}
                      fullWidth
                      margin="dense"
                      name="contentSelect"
                      value={selectCustomContent}
                      onChange={(e) => {
                        setSelectCustomContent(e.target.value);
                      }}
                      disabled={loading}
                    >
                      <option value="" disabled>
                        {t("form.contentSelect")}
                      </option>
                      {customContentList.map((content) => (
                        <option value={content.id} key={content.id}>
                          {content.name}
                        </option>
                      ))}
                    </CustomTextField>
                  </Box>
                )}
              </>
            )}

            <Box sx={{ mt: 1 }}>
              <InputLabel>{t("form.priorityLabel")}</InputLabel>
              <CustomTextField
                select
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                name="contentSelect"
                value={selectPriority}
                disabled={loading}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
              >
                <option value="0">{t("priority.Highest")}</option>
                <option value="1">{t("priority.High")}</option>
                <option value="2">{t("priority.Normal")}</option>
                <option value="3">{t("priority.Low")}</option>
                <option value="4">{t("priority.Lowest")}</option>
              </CustomTextField>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClickClose}
              disabled={loading}
            >
              {tCommon("cancelBtn")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditAnnouncement;
