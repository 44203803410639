import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import ContentTemplateItem from "./ContentTemplateItem";
import ContentTemplateBatchDelete from "./ContentTemplateBatchDelete";
import ContentDetails from "./detail/ContentDetails";
import {
  TypographyNormal,
  TypographyLarge,
  TypographySmall,
} from "../../customComponent";
import {
  getAllContents,
  setContentLayers,
  setLayerMedias,
  setContentName,
  setCanvasRes,
  removeContentsInBatch,
  setContentTags,
} from "../../../store/actions/cmsAction";
import {
  SET_CMS_ROWS_PER_PAGE,
  SET_CONTENT_DETAIL,
  SET_CONTENT_FIRST_LOAD,
  SET_FORCE_LOAD,
} from "../../../store/actionTypes/cmsActionType";
import { colorStyling } from "../../../helpers/color";
import { useDebounce } from "../../hooks";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DATA_FETCH_LIMIT = 100;
const tableStyling = {
  padding: "12px 12px",
};

const ContentTemplateList = ({ permission }) => {
  const dispatch = useDispatch();
  const {
    cmsData,
    cmsFilter,
    loadingCMS,
    forceLoad,
    firstLoad,
    rowsPerPage,
    cmsDetails,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();
  const contentPermission = permission?.content;

  const [currentPage, setCurrentPage] = useState(0);
  const [dataFrom, setDataFrom] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const [dataPayload, setDataPayload] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [appendLoading, setAppendLoading] = useState(false);

  const [openContentDetails, setOpenContentDetails] = useState(false);
  const [detailsID, setDetailsID] = useState({});

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [openBatchDelete, setOpenBatchDelete] = useState(false);
  const [loadingBatchDelete, setLoadingBatchDelete] = useState(false);
  const [checkboxAll, setCheckboxAll] = useState(false);

  const tableDiv = useRef(null);

  // First load
  useEffect(() => {
    if (cmsData?.items?.length === 0) {
      dispatch({ type: SET_FORCE_LOAD, payload: true });
    }

    updateDataList(0);
    dispatch(setContentLayers({}));
    dispatch(setLayerMedias({ reset: true }));
    dispatch(setContentName(""));
    dispatch(setContentTags({}));
    dispatch(
      setCanvasRes({
        width: 360,
        height: 640,
        orientation: "P",
      })
    );

    return () => {
      dispatch({ type: SET_CONTENT_DETAIL, payload: {} });
      dispatch({ type: SET_CONTENT_FIRST_LOAD, payload: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenContentDetails = (contentID) => {
    setDetailsID(contentID);
    setOpenContentDetails(true);
  };

  const handleCloseContentDetails = () => {
    setOpenContentDetails(false);
  };

  const fetchContents = (payload) => {
    const { offset, append, filterUsed } = payload;

    const getPayload = {
      payload: {
        ...cmsFilter,
        limit: DATA_FETCH_LIMIT,
        offset: offset ?? dataOffset,
      },
      cache: cmsData,
      append: append,
      filter: filterUsed,
      cacheDetails: cmsDetails,
    };

    setDataOffset(offset);
    setDataPayload(getPayload.payload);
    dispatch(
      getAllContents(getPayload, () => {
        setAppendLoading(false);
      })
    );
  };

  const updateDataList = (page, filterUsed) => {
    const nextSlice = page * rowsPerPage;
    setDataFrom(nextSlice);

    let appendData = false;
    if (cmsData.items.length > 0 && nextSlice >= cmsData.items.length) {
      appendData = true;
    }

    const nextOffset =
      Math.floor(nextSlice / DATA_FETCH_LIMIT) * DATA_FETCH_LIMIT;
    const payload = { offset: nextOffset, append: appendData, filterUsed };
    fetchContents(payload);
  };

  const debouncedUpdate = useDebounce(currentPage, 1111);
  useEffect(() => {
    if (updateFlag === true) {
      updateDataList(currentPage);
      setUpdateFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate]);

  const updateDataSlice = ({ page, rows }) => {
    const nextPage = page >= 0 ? page : currentPage;
    const nextSlice = nextPage * (rows ?? rowsPerPage);
    if (nextSlice >= cmsData.items.length) {
      setAppendLoading(true);
    }

    setDataFrom(nextSlice);
    setCurrentPage(nextPage);
    setUpdateFlag(true);

    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSelectItem = (item) => {
    const updated = { ...selectedItems };
    const updatedOrder = [...selectedOrder];
    updated[item.id] = item;
    updatedOrder.push(item.id);
    setSelectedItems(updated);
    setSelectedOrder(updatedOrder);
  };

  const handleUnselectItem = (selectedID) => {
    const updated = { ...selectedItems };
    delete updated[selectedID];
    setSelectedItems(updated);

    const updatedOrder = selectedOrder.filter((val) => val !== selectedID);
    setSelectedOrder(updatedOrder);
    setCheckboxAll(false);
  };

  const clearSelection = () => {
    setSelectedItems({});
    setSelectedOrder([]);
    setCheckboxAll(false);
  };

  const openBatchDeleteDialog = () => {
    setOpenBatchDelete(true);
  };

  const confirmBatchDelete = (callback) => {
    setLoadingBatchDelete(true);

    const contents = [];
    const batchIDs = [];
    Object.keys(selectedItems).forEach((id) => {
      contents.push(selectedItems[id]);
      batchIDs.push(selectedItems[id].id);
    });

    const payload = dataPayload;
    dispatch(
      removeContentsInBatch(
        { contents, batchIDs },
        () => {
          setCheckboxAll(false);
          setOpenBatchDelete(false);
          setLoadingBatchDelete(false);
          setSelectedItems({});
          setSelectedOrder([]);
          callback();
        },
        () => {
          dispatch(getAllContents({ payload, cache: cmsData }));
        }
      )
    );
  };

  const closeBatchDeleteDialog = () => {
    setOpenBatchDelete(false);
  };

  const handleChangePagination = (e, page) => {
    updateDataSlice({ page });
    setCheckboxAll(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedRows = parseInt(event.target.value, 10);
    dispatch({
      type: SET_CMS_ROWS_PER_PAGE,
      payload: updatedRows,
    });
    updateDataSlice({ page: 0, rows: updatedRows });
    setCheckboxAll(false);
  };

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  useEffect(() => {
    if (firstLoad) {
      setCurrentPage(0);
      updateDataList(0, true);
    } else {
      dispatch({ type: SET_CONTENT_FIRST_LOAD, payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsFilter]);

  const dataSliced = useMemo(() => {
    const lastIndex = dataFrom + rowsPerPage;
    logger.log(`[${dataFrom}, ${lastIndex}]`);
    return cmsData.items.slice(dataFrom, lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsData, dataFrom, rowsPerPage]);

  const selectedCount = useMemo(() => {
    return Object.keys(selectedItems).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleCheckboxAll = () => {
    const updatedCheckbox = !checkboxAll;
    const updated = { ...selectedItems };

    let updatedOrder = [...selectedOrder];
    dataSliced.forEach((item) => {
      if (!item.canDelete) {
        return;
      }

      if (updatedCheckbox) {
        if (!updated[item.id]) {
          updatedOrder.push(item.id);
        }
        updated[item.id] = item;
      } else {
        delete updated[item.id];
        updatedOrder = updatedOrder.filter((val) => val !== item.id);
      }
    });

    setCheckboxAll(updatedCheckbox);
    setSelectedItems(updated);
    setSelectedOrder(updatedOrder);
  };

  if ((loadingCMS && !firstLoad) || forceLoad) {
    return (
      <Box
        height={"20vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 400 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );
  }

  return (
    <>
      <ContentTemplateBatchDelete
        contents={selectedItems}
        contentOrder={selectedOrder}
        open={openBatchDelete}
        closeDialog={closeBatchDeleteDialog}
        unselectItem={handleUnselectItem}
        handleConfirm={confirmBatchDelete}
        loading={loadingBatchDelete}
      />

      <ContentDetails
        open={openContentDetails}
        handleClose={handleCloseContentDetails}
        contentID={detailsID}
      />

      <Box sx={{ maxHeight: 567 }}>
        {selectedCount > 0 && (
          <Box
            height={40}
            display={"flex"}
            justifyContent={"end"}
            alignItems={"end"}
            sx={{ mb: 1 }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Box display={"flex"} flexDirection={"column"} sx={{ mr: 1 }}>
                <TypographySmall sx={{ fontSize: "13px" }}>
                  {parser(
                    t("table.selectedItemsHint", {
                      count: selectedCount,
                    })
                  )}
                </TypographySmall>
                <Box display={"flex"} justifyContent={"end"} sx={{ mr: "4px" }}>
                  <Link
                    href="#"
                    underline="none"
                    component={"div"}
                    onClick={clearSelection}
                    sx={{
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    <TypographySmall
                      sx={{
                        color: "blue",
                        fontWeight: 500,
                        ":hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      {t("table.clearSelectionLabel")}
                    </TypographySmall>
                  </Link>
                </Box>
              </Box>
              {/* TODO: If needed (Only applicable to Campaign Name) */}
              {/* <Button
                variant="contained"
                color="success"
                onClick={() => openBatchDeleteDialog()}
                sx={{ mr: 1 }}
              >
                {t("table.batchEditBtn")}
              </Button> */}
              <Button
                variant="contained"
                color="error"
                onClick={() => openBatchDeleteDialog()}
                sx={{ mx: 1 }}
              >
                {t("batchDelete.btn")}
              </Button>
            </Box>
          </Box>
        )}

        <TableContainer
          ref={tableDiv}
          component={Paper}
          elevation={2}
          sx={{
            overflowY: "auto",
            maxHeight: 500,
            borderRadius: "6px 6px 0 0",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableStyling, paddingLeft: 4 }}>
                  {t("table.headerContentName")}
                </TableCell>
                <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                  {parser(t("table.headerModifiedDate"))}
                </TableCell>
                <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                  {t("table.headerLastEditedBy")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ ...tableStyling, width: 222, paddingRight: 3 }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                  >
                    <Tooltip
                      arrow
                      disableInteractive
                      title={t("item.notAuthorized")}
                    >
                      <Box sx={{ width: "fit-content", ml: 3 }}>
                        <Checkbox
                          checked={checkboxAll}
                          size="small"
                          disabled={!contentPermission?.delete}
                          onClick={handleCheckboxAll}
                          sx={{ padding: "4px" }}
                        />
                      </Box>
                    </Tooltip>
                    <span style={{ marginRight: "4px" }}>
                      {t("table.headerAction")}
                    </span>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            {cmsData.totalItem !== 0 && (
              <TableBody>
                {dataSliced.length !== 0 ? (
                  dataSliced.map((data) => (
                    <ContentTemplateItem
                      key={data.id}
                      content={data}
                      openContentDetails={handleOpenContentDetails}
                      currentPayload={dataPayload}
                      permission={permission}
                      selectItem={handleSelectItem}
                      unselectItem={handleUnselectItem}
                      selectedFlag={selectedItems[data.id] ? true : false}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{ height: "20vh" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <CircularProgress
                          size={20}
                          thickness={3}
                          sx={{ color: colorStyling.primary }}
                        />
                        <TypographyNormal
                          sx={{
                            color: colorStyling.primary,
                            mt: 2,
                            fontWeight: 400,
                          }}
                        >
                          {tCommon("loadingDataList")}
                        </TypographyNormal>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>

          {cmsData.items.length === 0 && (
            <Box
              alignItems={"center"}
              bgcolor={colorStyling.cms.white}
              sx={{ p: 5 }}
            >
              <TypographyLarge textAlign={"center"}>
                {t("table.noData")}
              </TypographyLarge>
            </Box>
          )}
        </TableContainer>
      </Box>

      {!loadingCMS && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={cmsData.totalItem}
              page={currentPage}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
              disabled={appendLoading}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default ContentTemplateList;
