import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import Sidebar from "../../sidebar/Sidebar";
import UserErrorPrompt from "../UserErrorPrompt";
import UserSuccessPrompt from "../UserSuccessPrompt";
import UserHistoryList from "./UserHistoryList";
import UserHistoryFilter from "./UserHistoryFilter";
import ExportUserHistory from "./ExportUserHistory";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";

const UserHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("auditlog");

  const { user, features } = useSelector((state) => state.auth);
  const userRole = user?.type;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["LOG.AUDIT"] === false) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          {features["LOG.AUDIT.EXPORT"] && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportUserHistory />
            </Box>
          )}
          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <UserHistoryFilter />
            </Paper>

            <UserHistoryList role={userRole} />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default UserHistory;
