import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Paper,
  InputAdornment,
  TextField,
  InputLabel,
  Tooltip,
  Slider,
} from "@mui/material";
import LayerAsset from "./LayerAsset";
import { TypographyNormal, TypographyLarge } from "../../../customComponent";
import T from "../../../../transitions";

const LayerItem = ({ layer, index }) => {
  const { t } = useTranslation("cms");

  const { assetData } = useSelector((state) => state.cms);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (layer?.assets?.length > 0) {
      const layerAssets = [];
      layer.assets.forEach((asset, i) => {
        const obj =
          asset.interval >= 0
            ? asset
            : {
                id: asset.order + 1,
                assetId: asset.id,
                name: asset.name,
                type: asset.type,
                interval: asset.duration / 1000,
                isMuted: asset.isMuted,
                placeholder: asset.placeholder,
                assetFit: asset.fit ?? "FIT",
              };
        layerAssets.push(obj);
      });
      setContents(layerAssets);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer]);

  const textLayer = useMemo(() => {
    const text = assetData[layer.assets[0].id]?.text;
    return text;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, assetData]);

  return (
    <Paper sx={{ p: 2, mb: 2 }} elevation={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} sx={{ mb: "2px" }}>
          <InputLabel>{`${t("detail.layerLabel")} ${index}`}</InputLabel>
          {layer.name?.length > 0 && (
            <Tooltip
              arrow
              disableInteractive
              placement="top"
              title={layer.name?.length > 44 ? layer.name : ""}
            >
              <TypographyLarge>
                {": "}
                {layer.name?.length > 44
                  ? layer.name?.substring(0, 41).trim() + "..."
                  : layer.name}
              </TypographyLarge>
            </Tooltip>
          )}
        </Box>
        {textLayer && (
          <Tooltip
            arrow
            disableInteractive
            title={t("detail.contentBgColorTip")}
          >
            <Box
              sx={{
                marginTop: "-17px",
                width: "20px",
                height: "40px",
                borderRadius: "0 0 99px 99px",
                border: "2px solid rgba(28, 78, 216, 0.5)",
                backgroundColor: textLayer.backgroundColor,
                ":hover": {
                  backgroundColor: textLayer.backgroundColor,
                },
              }}
            />
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ mt: 1 }} />

      {!textLayer?.text ? (
        <>
          {contents.map((content, index) => (
            <LayerAsset
              key={content.id}
              layerID={layer.id}
              content={content}
              index={index}
              assetCount={contents.length}
            />
          ))}

          {contents.length >= 2 && (
            <>
              <Divider sx={{ my: 1 }} />

              <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
                <TypographyLarge sx={{ overflow: "visible" }}>
                  {t("detail.contentTransitionLabel")}
                </TypographyLarge>
                <TypographyLarge
                  sx={{
                    ml: 1,
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                  }}
                >
                  {t(`transition.${T[layer.transition].id}`)}
                </TypographyLarge>
              </Box>
            </>
          )}
        </>
      ) : (
        <Box sx={{ mt: 1, px: 1, width: "100%" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ width: "100%", paddingX: 1 }}
          >
            <Box>
              <TypographyLarge sx={{ width: "80px" }}>
                {t("detail.textLabel")}
              </TypographyLarge>
            </Box>

            <Box sx={{ width: "100%" }}>
              <TextField
                margin="dense"
                variant="outlined"
                size="small"
                value={textLayer.text}
                sx={{ width: "100%", pl: 1 }}
                inputProps={{ style: { cursor: "default" } }}
              />
            </Box>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ width: "100%", paddingX: 1 }}
          >
            <Box display={"flex"} alignItems={"center"} sx={{ width: "50%" }}>
              <InputLabel>
                <TypographyLarge sx={{ width: "80px" }}>
                  {t("detail.textSizeLabel")}
                </TypographyLarge>
              </InputLabel>

              <Tooltip arrow disableInteractive title={t("detail.textSizeTip")}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={textLayer.fontSize}
                  sx={{ width: "100px", pl: 1 }}
                  inputProps={{ style: { cursor: "default" } }}
                  InputProps={{
                    readOnly: true,
                    sx: { cursor: "default" },
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </Box>

            <Box display={"flex"} alignItems={"center"} sx={{ width: "50%" }}>
              <InputLabel>
                <TypographyLarge sx={{ width: "80px" }}>
                  {t("detail.textColorLabel")}
                </TypographyLarge>
              </InputLabel>

              <Tooltip
                arrow
                disableInteractive
                title={t("detail.textColorTip")}
              >
                <Box
                  variant="contained"
                  sx={{
                    padding: 0,
                    borderRadius: "99px",
                    border: `2px solid black`,
                    minWidth: "30px",
                    minHeight: "30px",
                    backgroundColor: textLayer.color,
                    ":hover": {
                      backgroundColor: textLayer.color,
                    },
                  }}
                />
              </Tooltip>
            </Box>
          </Box>

          <Divider component="div" sx={{ marginY: "6px" }} />

          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ width: "100%", paddingX: 1 }}
          >
            {textLayer.mode === "STATIC" ? (
              <TypographyNormal sx={{ width: "100%", fontStyle: "italic" }}>
                {t("detail.scrollDisabledHint")}
              </TypographyNormal>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ width: "50%" }}
                >
                  <Box>
                    <TypographyLarge sx={{ width: "80px" }}>
                      {t("detail.scrollDirectionLabel")}
                    </TypographyLarge>
                  </Box>

                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("detail.scrollDirectionTip")}
                  >
                    <TextField
                      margin="dense"
                      variant="outlined"
                      size="small"
                      value={textLayer.scrollingDirection}
                      sx={{ width: "100px", pl: 1 }}
                      inputProps={{ style: { cursor: "default" } }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  sx={{
                    width: "100%",
                    paddingLeft: 3,
                    paddingRight: 1,
                  }}
                >
                  <Box>
                    <TypographyLarge sx={{ width: "80px" }}>
                      {t("detail.scrollSpeedLabel")}
                    </TypographyLarge>
                  </Box>

                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("detail.scrollSpeedTip")}
                  >
                    <Slider
                      value={Math.round(textLayer.scrollingSpeed / 40)}
                      valueLabelDisplay="auto"
                      step={1}
                      marks={[
                        { value: 1, label: t("detail.scrollSpeedSlow") },
                        { value: 10, label: t("detail.scrollSpeedFast") },
                      ]}
                      min={1}
                      max={10}
                      sx={{ cursor: "default" }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default LayerItem;
