import * as yup from "yup";
import i18n from "i18next";
import { parseVariables } from "./localize";

export const passwordYupValidation = () => {
  return yup
    .string()
    .min(8, ({ min }) =>
      parseVariables(i18n.t("validation.minLengthChars"), { num: min })
    )
    .max(30, ({ max }) =>
      parseVariables(i18n.t("validation.maxLengthChars"), { num: max })
    )
    .matches(/[A-Z]/, i18n.t("validation.includeUppercase"))
    .matches(/[a-z]/, i18n.t("validation.includeLowercase"))
    .matches(/\d/, i18n.t("validation.includeNumber"))
    .matches(/[!@#$%^&*_]/, i18n.t("validation.includeSpecialCharacter"))
    .test("no-invalid-special", null, (value) => {
      const invalidSpecialChars =
        value && value.match(/[^A-Za-z0-9!@#$%^&*_=]/g);
      if (invalidSpecialChars) {
        // Returns a custom error message if any invalid special characters are found
        const errorMessages = invalidSpecialChars.map((char) =>
          parseVariables(i18n.t("validation.notAllowedSpecialCharacter"), {
            char,
          })
        );
        return new yup.ValidationError(
          errorMessages.join(", "),
          value,
          "password"
        );
      }
      return true; // If no invalid special characters, the test passes
    });
};

// Password validation function
export const validatePassword = (password) => {
  const minLength = 8;

  const hasUppercase = /[A-Z]/; // Matches uppercase letters
  const hasLowercase = /[a-z]/; // Matches lowercase letters
  const hasNumbers = /\d/; // Matches digits (0-9)
  const hasSpecial = /[!@#$%^&*_]/; // Matches special characters based on the provided regex

  const failedCategories = [];

  // Check each category
  if (password.length < minLength) {
    failedCategories.push(
      `Password must be at least ${minLength} characters long.`
    );
  }

  if (!hasUppercase.test(password)) {
    failedCategories.push("Must include at least one uppercase letter (A-Z)");
  }

  if (!hasLowercase.test(password)) {
    failedCategories.push("Must include at least one lowercase letter (a-z)");
  }

  if (!hasNumbers.test(password)) {
    failedCategories.push("Must include at least one number (0-9)");
  }

  const hasSpecialCharacter = hasSpecial.test(password);
  const invalidSpecialChars = password.match(/[^A-Za-z0-9]/g);

  if (!hasSpecialCharacter) {
    failedCategories.push(
      "Must include at least one special character (!@#$%^&*_)"
    );
  } else if (invalidSpecialChars) {
    invalidSpecialChars.forEach((char) => {
      failedCategories.push(
        `The character "${char}" is not allowed. Only special characters (!@#$%^&*_) are allowed.`
      );
    });
  }

  return {
    isValid: failedCategories.length === 0,
    failedCategories,
  };
};
