import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { colorStyling } from "../../../../helpers/color";
import {
  TypographyLarge,
  TypographyNormal,
} from "../../../../components/customComponent";
import ContentDetails from "../../../../components/cms/contentTemplate/detail/ContentDetails";
import { getAdvertisementSchedules } from "../../../../store/actions/scheduleAction";
import AdvertisementScheduleItem from "./AdvertisementScheduleItem";
import FilterAdvertisementSchedule from "./FilterAdvertisementSchedule";
import { filterObjOnly } from "../../../../helpers/filterHelper";
import { parseVariables } from "../../../../helpers/localize";
import {
  SET_SCHEDULE_PAGE,
  SET_SCHEDULE_ROWS_PER_PAGE,
} from "../../../../store/actionTypes/scheduleActionType";

const AdvertisementScheduleList = () => {
  const dispatch = useDispatch();
  const {
    advertisementSchedules,
    advertisementScheduleFilters: scheduleFilters,
    loadingAdvertisementSchedule: loading,
    page,
    rowsPerPage,
  } = useSelector((state) => state.schedule);

  const { t } = useTranslation("advertschedule");
  const { t: tCommon } = useTranslation();

  const tableDiv = useRef(null);

  const [updating, setUpdating] = useState(false);
  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [contentDetailId, setContentDetailId] = useState(null);

  const handleOpenContentDetail = (id) => {
    setContentDetailId(id);
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = () => {
    setOpenContentDetail(false);
  };

  const handleChangePagination = (e, page) => {
    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch({ type: SET_SCHEDULE_PAGE, payload: page });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: SET_SCHEDULE_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });

    handleChangePagination(null, 0);
  };

  const fetchData = async () => {
    if (updating) return;

    let isPrefetch = false;
    let limit = 100;
    let offset = page * rowsPerPage;
    let query = `${filterObjOnly(scheduleFilters)}`;
    if (offset === 0) {
      isPrefetch = false;
    } else {
      if (page + 1 * rowsPerPage >= advertisementSchedules.length) {
        query += `&offset=${advertisementSchedules.length}`;
        setUpdating(true);
        isPrefetch = true;
      } else {
        return;
      }
    }
    dispatch(
      getAdvertisementSchedules(limit, query, isPrefetch, () =>
        setUpdating(false)
      )
    );
  };

  const fetchFilterData = async () => {
    let isPrefetch = false;
    let limit = 100;
    let query = `&offset=0${filterObjOnly(scheduleFilters)}`;
    dispatch(
      getAdvertisementSchedules(limit, query, isPrefetch, () =>
        setUpdating(false)
      )
    );
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (page === 0) {
      fetchFilterData();
    } else handleChangePagination(null, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleFilters]);

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <ContentDetails
        open={openContentDetail}
        handleClose={handleCloseContentDetail}
        contentID={contentDetailId}
      />
      <Paper
        elevation={2}
        component="section"
        sx={{ borderRadius: "6px", mt: 2, mb: 2, p: 1.5 }}
      >
        <FilterAdvertisementSchedule updating={setUpdating} />
      </Paper>
      <TableContainer
        ref={tableDiv}
        component={Paper}
        sx={{ overflowY: "auto", maxHeight: 444, boxShadow: 3 }}
      >
        {loading ? (
          <Box
            sx={{ height: 300 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"5%"}></TableCell>
                <TableCell align="left" width={"20%"}>
                  {t("table.headerName")}
                </TableCell>
                <TableCell align="center">{t("table.headerStatus")}</TableCell>
                <TableCell align="left" width={"20%"}>
                  {t("table.headerContent")}
                </TableCell>
                <TableCell align="left" width={"20%"}>
                  {t("table.headerDate")}
                </TableCell>
                <TableCell align="left" width={"15%"}>
                  {t("table.headerTime")}
                </TableCell>
                <TableCell align="center" width={"10%"}>
                  {t("table.headerSlot")}
                </TableCell>
                {/* TODO Action if imported */}
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>

            {advertisementSchedules.items.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Alert
                      severity="info"
                      variant="filled"
                      icon={false}
                      sx={{ textAlign: "center", display: "block" }}
                    >
                      <TypographyLarge sx={{ color: colorStyling.white.full }}>
                        {tCommon("noData")}
                      </TypographyLarge>
                    </Alert>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {advertisementSchedules.items
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item, index) => (
                    <AdvertisementScheduleItem
                      key={item.name + item.id}
                      schedule={item}
                      index={index}
                      openContent={handleOpenContentDetail}
                    />
                  ))}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>

      <Box
        sx={{
          pt: 4,
          pb: 2,
          pr: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Paper component="div" elevation={1} sx={{ px: 1 }}>
          <TablePagination
            component="div"
            count={advertisementSchedules.totalItem}
            page={page}
            onPageChange={handleChangePagination}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={displayedRowsLabel}
            labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
          />
        </Paper>
      </Box>
    </>
  );
};

export default AdvertisementScheduleList;
