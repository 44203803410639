import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CssBaseline,
  Drawer,
  List,
  Box,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";

import { LogoIcon } from "../../icons";

import Navbar from "../Navbar";
import Versioning from "../Versioning";

import Role from "../../helpers/roles";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { colorStyling } from "../../helpers/color";
import { useRemoteConfig } from "../hooks";

import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";
import { SET_AUTH_FEATURES } from "../../store/actionTypes/userActionType";
import { getOrganizationFeatures } from "../../store/actions/userAction";
import {
  sidebarMenuConfigBottom,
  sidebarMenuConfigTop,
} from "./sidebarMenuConfig";
import { externalSidebarConfig } from "../../externalFeatures/externalSidebarMenu";
import * as Sentry from "@sentry/react";
import { coreFeatureFlag, getGlobalKey } from "../../helpers/featureFlag";
import { getUserAuth } from "../../store/actions/authAction";

const drawerWidth = 240;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: colorStyling.blue.deep,
    cursor: "pointer",
  },
  marginBottom: theme.spacing(1),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  marginLeft: theme.spacing(1.75),
  lineHeight: "1.625rem",
}));

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t: tCommon } = useTranslation();

  const { user, features } = useSelector((state) => state.auth);
  const userRole = user?.type;
  const userPermission = user?.permission;

  const globalFF = useRemoteConfig(getGlobalKey(), JSON.parse, coreFeatureFlag);

  const updateFeatureData = async (userID) => {
    if (!userID) {
      return;
    }

    let featureData;
    try {
      featureData = await getOrganizationFeatures(userID);
    } catch (error) {
      featureData = [];
    }

    const featureFlags = { ...globalFF };
    featureData.features.forEach((feature) => {
      featureFlags[feature] = false;
    });
    dispatch({ type: SET_AUTH_FEATURES, payload: featureFlags });
  };

  const refreshUserData = async () => {
    dispatch(
      getUserAuth((data) => {
        Sentry.setUser({
          id: data.id,
          name: data.name,
          username: data.name,
          email: data.email,
          mfaType: data.mfaType,
          loginAt: data.loginAt,
          roleType: data.type,
          organization: data.organization?.name || "null",
          userGroup: data.userGroup?.name || "null",
        });
      })
    );
  };

  useEffect(() => {
    if (user?.id) {
      refreshUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalFF?.enabled) {
      updateFeatureData(user?.organization?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFF]);

  const handlePage = (path) => {
    navigate(path);
  };

  const itxcastLogo = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoIcon />
      <Typography
        component="h2"
        sx={{
          color: colorStyling.white.full,
          marginLeft: "0.5rem",
          cursor: "pointer",
          fontSize: "2rem",
          fontWeight: 700,
          lineHeight: "2.375rem",
        }}
        onClick={() => navigate("/")}
      >
        {parser(
          parseVariables(tCommon("sidebar.title"), {
            color: colorStyling.blue.sky,
          })
        )}
      </Typography>
    </Box>
  );

  const menuItemUi = (item) => {
    const currentPath =
      pathname === item.path || pathname.includes(item.pathInclude);
    return (
      <StyledListItem
        key={item.path}
        button
        dense
        onClick={() => handlePage(item.path)}
        sx={{
          backgroundColor: currentPath ? colorStyling.blue.deep : null,
        }}
      >
        {React.cloneElement(item.icon, item.iconProps)}

        <StyledListItemText
          sx={{
            color: currentPath
              ? colorStyling.white.full
              : colorStyling.white.blue,
          }}
          primary={item.label}
        />
      </StyledListItem>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Navbar />

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: colorStyling.primary,
              paddingY: 3,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {itxcastLogo}

          <Box p={2}>
            <Typography
              component="h6"
              sx={{
                color: colorStyling.white.hover,
                opacity: 0.45,
                fontSize: 12,
              }}
              gutterBottom
            >
              {tCommon("sidebar.manageLabel")}
            </Typography>

            <List>
              {[...sidebarMenuConfigTop, ...externalSidebarConfig]
                .filter(
                  (item) =>
                    // Filter out items if their feature flag is in either the global or user-specific disabled features
                    (features[item.flag] || item.flag === undefined) &&
                    (!user.id ||
                      !item.condition ||
                      item.condition(userPermission, userRole, features))
                )
                .map((item) => menuItemUi(item))}

              {/* DIVIDER */}
              {userRole !== Role.Eins && (
                <Box
                  component="hr"
                  border={1}
                  borderColor={colorStyling.blue.light}
                  mb={2}
                />
              )}

              {sidebarMenuConfigBottom
                .filter(
                  (item) =>
                    // Filter out items if their feature flag is in either the global or user-specific disabled features
                    (features[item.flag] || item.flag === undefined) &&
                    (!user.id ||
                      !item.condition ||
                      item.condition(userPermission, userRole))
                )
                .map((item) => menuItemUi(item))}
            </List>
          </Box>

          <Versioning textColor={"white"} />
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
