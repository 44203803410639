import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSchedule } from "../../../store/actions/scheduleAction";

import Sidebar from "../../sidebar/Sidebar";
import ScheduleBreadCrumb from "./ScheduleBreadCrumb";
import ScheduleInfo from "./ScheduleInfo";
import DeleteSchedule from "./DeleteSchedule";
import EditSchedule from "./EditSchedule";
import ScheduleSuccessPrompt from "../ScheduleSuccessPrompt";
import ScheduleErrorPrompt from "../ScheduleErrorPrompt";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";
import ApproveSchedule from "./ApproveSchedule";
import ActivateSchedule from "./ActivateSchedule";
import { SET_SCHEDULE_DETAIL } from "../../../store/actionTypes/scheduleActionType";
import AdvertisementScheduleInfo from "../../../externalFeatures/components/stellar/advertisementSchedule/AdvertisementScheduleInfo";
import EditAdvertisementSchedule from "../../../externalFeatures/components/stellar/advertisementSchedule/EditAdvertisementSchedule";
import { stellarFlag } from "../../../externalFeatures/componentsFlag";
import CoreDetailPageLoader from "../../skeletonLoader/CoreDetailPageLoader";
import { isEmpty } from "lodash";

const ScheduleDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSchedule(id));
  }, [dispatch, id]);

  const {
    schedule: { [id]: schedule },
    loadingSchedule: loading,
  } = useSelector((state) => state.schedule);

  const { user, features } = useSelector((state) => state.auth);
  const permission = user?.permission;
  const schedulePermission = permission?.schedule;
  const groupPermission = permission?.screenGroup;
  const viewerPermission = permission?.screen;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["SCHEDULE"] === false) {
      navigate("/");
      return;
    }

    if (permission && !schedulePermission?.read) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  useEffect(() => {
    if (schedule?.type === "INSTANT") {
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: {},
      });
      navigate("/schedules");
    }

    if (!stellarFlag && schedule?.type === "ADVERTISEMENT") {
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: {},
      });
      navigate("/schedules");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  let isLoading = !loading || +schedule?.id === +id;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        {isEmpty(schedule) ? (
          <CoreDetailPageLoader infoLine={3} />
        ) : (
          <Container maxWidth="xl">
            <Box sx={{ mb: 2 }}>
              <ScheduleBreadCrumb schedule={schedule} />
            </Box>

            <ScheduleSuccessPrompt />
            <ScheduleErrorPrompt />

            {isLoading && (
              <Box
                sx={{ mb: 2 }}
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"nowrap"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"}>
                  {schedulePermission?.approve && (
                    <ApproveSchedule schedule={schedule} />
                  )}
                  {schedulePermission?.activate && (
                    <ActivateSchedule schedule={schedule} />
                  )}
                </Box>

                <Box display={"flex"}>
                  {schedulePermission?.update && (
                    <>
                      {schedule?.type === "ADVERTISEMENT" ? (
                        <EditAdvertisementSchedule schedule={schedule} />
                      ) : (
                        <EditSchedule schedule={schedule} />
                      )}
                    </>
                  )}
                  {schedulePermission?.delete && (
                    <DeleteSchedule schedule={schedule} />
                  )}
                </Box>
              </Box>
            )}

            <Grid container spacing={2} direction="column">
              <Grid item xs={4}>
                <Paper elevation={2} sx={{ p: 2, borderRadius: "6px", mb: 2 }}>
                  {schedule?.type === "ADVERTISEMENT" ? (
                    <AdvertisementScheduleInfo
                      schedule={schedule}
                      loading={!isLoading}
                    />
                  ) : (
                    <ScheduleInfo schedule={schedule} loading={!isLoading} />
                  )}
                </Paper>
              </Grid>

              {/* TODO change condition */}
              {isLoading && schedulePermission?.update ? (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                >
                  {/* Assign Group */}
                  {groupPermission?.read && (
                    <AssignComponent
                      getName={"group"}
                      typeName="schedule"
                      typeData={schedule}
                    />
                  )}

                  {/* Assign Viewer */}
                  {viewerPermission?.read && (
                    <AssignComponent
                      getName={"viewer"}
                      typeName="schedule"
                      typeData={schedule}
                    />
                  )}
                </Box>
              ) : null}

              {/* Group List */}
              {groupPermission?.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"group"}
                      type={"group"}
                      listData={schedule.screenGroups}
                      sourceData={{
                        id: schedule.id,
                        name: schedule.name,
                        type: "schedule",
                        isApproved: schedule.isApproved,
                        editPermission: schedulePermission?.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}

              {/* Viewer List */}
              {viewerPermission?.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"viewer"}
                      type={"viewer"}
                      listData={schedule.screens}
                      sourceData={{
                        id: schedule.id,
                        name: schedule.name,
                        type: "schedule",
                        isApproved: schedule.isApproved,
                        editPermission: schedulePermission?.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default ScheduleDetail;
