import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_LOADING_USER,
  SET_USER_FILTER,
} from "../../store/actionTypes/userActionType";
import Role from "../../helpers/roles";
import {
  API_LIMIT,
  initiateOrganizationAPI,
  initiateUserGroupAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { colorStyling } from "../../helpers/color";

const UserFilter = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { userFilters } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const userRole = user?.type;
  const userGroupPermission = user?.permission?.userGroup;

  const [name, setName] = useState(userFilters.nameLike);
  const [role, setRole] = useState(userFilters.type);

  const [userGroups, setUserGroups] = useState([]);
  const [userGroupId, setUserGroupId] = useState(userFilters.userGroupId);

  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(
    userFilters.organizationId
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_USER_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: SET_LOADING_USER,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...userFilters, sortBy: value });
  };

  const getUserGroups = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      setUserGroups(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: errorMessage });
    }
  };

  const getOrganizations = async () => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: errorMessage });
    }
  };

  const handleResetState = () => {
    setName("");
    setRole("");
    setUserGroupId("");
    setUserGroups([]);
    setOrganizationId("");
    setOrganizations([]);
  };

  const handleRefresh = () => {
    handleLoading(true);
    handleDispatchFilter({ sortBy: "idDesc" });
    handleResetState();
    if (userGroupPermission?.read) getUserGroups();
    if (userRole === Role.Eins) {
      getOrganizations();
    }
  };

  useEffect(() => {
    if (userRole === Role.Eins) {
      getOrganizations();
    }

    if (userGroupPermission?.read) getUserGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let query = { ...userFilters };

    if (name) query["nameLike"] = name;
    else delete query["nameLike"];

    if (role) query["type"] = role;
    else delete query["type"];

    if (userGroupId) query["userGroupId"] = userGroupId;
    else delete query["userGroupId"];

    if (organizationId) query["organizationId"] = organizationId;
    else delete query["organizationId"];

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        size="small"
        sx={{ mr: 2 }}
        placeholder={t("filter.searchPlaceholder")}
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      {userRole === Role.Eins && (
        <TextField
          size="small"
          SelectProps={{ native: true }}
          select
          fullWidth
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{ mr: 2 }}
        >
          <option defaultValue value="">
            {t("filter.searchRoleAny")}
          </option>
          <option value="ADMIN">{t("filter.searchRoleAdmin")}</option>
          <option value="BASIC">{t("filter.searchRoleUser")}</option>
        </TextField>
      )}

      {userGroupPermission?.read && (
        <TextField
          size="small"
          select
          fullWidth
          SelectProps={{ native: true }}
          value={userGroupId}
          onChange={(e) => setUserGroupId(e.target.value)}
          sx={{ mr: 2 }}
        >
          <option value="">{t("filter.searchUserGroupAny")}</option>
          {userGroups.map((userGroup) => (
            <option key={userGroup.id} value={userGroup.id}>
              {userGroup.name}
            </option>
          ))}
        </TextField>
      )}

      {userRole === Role.Eins && (
        <TextField
          size="small"
          select
          fullWidth
          SelectProps={{ native: true }}
          value={organizationId}
          onChange={(e) => setOrganizationId(e.target.value)}
          sx={{ mr: 2 }}
        >
          <option value="">{t("filter.searchOrganizationAny")}</option>
          {organizations.map((organization) => (
            <option key={organization.id} value={organization.id}>
              {organization.name}
            </option>
          ))}
        </TextField>
      )}

      <Button type="submit" variant="contained">
        {tCommon("searchBtn")}
      </Button>

      <IconButton
        sx={{ color: colorStyling.black, mx: 2 }}
        onClick={handleClick}
      >
        <Tooltip title={tCommon("sortTip")}>
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
          <FormControlLabel
            disableTypography
            value="idDesc"
            control={
              <Radio size="small" checked={userFilters.sortBy === "idDesc"} />
            }
            label={t("filter.sortByNewest")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
          <FormControlLabel
            disableTypography
            value="idAsc"
            control={
              <Radio size="small" checked={userFilters.sortBy === "idAsc"} />
            }
            label={t("filter.sortByOldest")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
          <FormControlLabel
            disableTypography
            value="nameAsc"
            control={
              <Radio size="small" checked={userFilters.sortBy === "nameAsc"} />
            }
            label={t("filter.sortByAtoZ")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
          <FormControlLabel
            disableTypography
            value="nameDesc"
            control={
              <Radio size="small" checked={userFilters.sortBy === "nameDesc"} />
            }
            label={t("filter.sortByZtoA")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: colorStyling.black }}>
        <Tooltip title={tCommon("resetFilterTip")}>
          <RestartAltIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default UserFilter;
